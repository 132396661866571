import { ComponentProps } from 'react'

import { Container as SidePanelContainer, StyledBackdrop } from './SidePanel'
import ModalTransition from './ModalTransition'

type Props = Omit<
  ComponentProps<typeof ModalTransition>,
  'backdropComponent' | 'modalComponent'
>

const SidePanelTransition = (props: Props) => (
  <ModalTransition
    {...props}
    backdropComponent={StyledBackdrop}
    modalComponent={SidePanelContainer}
  />
)

export default SidePanelTransition
