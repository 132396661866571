/* eslint-disable prefer-destructuring */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, useCallback, useEffect, useRef, useState } from 'react'

import { makeVar, useReactiveVar } from '../utils/reactiveVar'
import useIsAtTop from '../utils/useIsAtTop'
import { isSiteXplorer, isSiteYachting, isSiteYachtSupport } from '../themes'
import NavigationBar from './NavigationBar/NavigationBar'

const StyledNavigationBar = styled(NavigationBar, {
  shouldForwardProp: (prop) =>
    prop !== 'show' &&
    prop !== 'isAtTop' &&
    prop !== 'isNavigationOpen' &&
    prop !== 'hasTopBar',
})<{
  show?: boolean
  isAtTop?: boolean
  isNavigationOpen?: boolean
  hasTopBar?: boolean
}>(({ theme, show, isAtTop, isNavigationOpen, hasTopBar }) => [
  css`
    position: fixed;
    top: 0;
    z-index: 50;
    transform: translateY(-100%);
    transition: transform 200ms ease-out, background 600ms ease-in-out,
      color 600ms ease-in-out, top 200ms ease-out;
  `,
  show &&
    css`
      transform: translateY(0%);
      // Slow down when appearing
      transition-duration: 600ms;
    `,
  !isAtTop &&
    css`
      background: white;
      color: ${theme.colors.amels.deepBayAqua};

      ${isSiteXplorer(theme) &&
      css`
        color: ${theme.colors.xplorer.white};
        background-color: ${theme.colors.xplorer.primaryXPLavaBlack};
      `};

      ${isSiteYachting(theme) &&
      css`
        color: ${theme.colors.yachting.darkBlue};
      `}

      ${isSiteYachtSupport(theme) &&
      css`
        color: ${theme.colors.yachtSupport.primaryYSOceanBlue};
      `}
    `,
  hasTopBar &&
    isAtTop &&
    css`
      // On some zoom levels / display scaling settings there is a 2px gap
      // We counter this by always subtracting 2px from the top value
      // Check the SimpleHeader component for the same fix
      top: calc(${theme.spacing.x6}px - 2px);

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        top: calc(${theme.spacing.x9}px - 2px);
      }
    `,
  isNavigationOpen &&
    css`
      top: 0;
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        top: 0;
      }
    `,
])

export const showNavigationBarVar = makeVar(false)
export const blockNavigationBarVar = makeVar(0)

export const isNavigationOpen = () => {
  if (typeof window !== 'undefined') {
    return window.location.hash === '#menu'
  }
  return false
}
/**
 * Whether to ignore scrolling up, preventing the sticky nav bar from appearing.
 * This is especially useful for automated scrolls.
 */
export const ignoreScrollUpVar = makeVar(false)

export const useShowNavigationBar = (timerDisappearTimeout = 5000) => {
  const lastScrollY = useRef(0)
  const timer = useRef<ReturnType<typeof setTimeout> | undefined>()

  const showNavigationBar = useReactiveVar(showNavigationBarVar)
  const blockNavigationBar = useReactiveVar(blockNavigationBarVar)
  const ignoreScrollUp = useReactiveVar(ignoreScrollUpVar)

  // The nav bar must be shown again each time this component is mounted
  useEffect(() => {
    showNavigationBarVar(true)
  }, [])

  // The nav bar must appear at the top of the page
  // The nav bar must appear when the user scrolls up
  // The nav bar must disappear after 5 seconds when it appeared due to
  // scrolling up
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY
      const delta = scrollY - lastScrollY.current
      if (delta === 0) {
        return
      }
      lastScrollY.current = scrollY
      const direction = delta > 0 ? 'down' : 'up'

      if (ignoreScrollUp) {
        return
      }

      showNavigationBarVar(direction === 'up' || scrollY <= 0)
      clearTimeout(timer.current)
      if (direction === 'up') {
        timer.current = setTimeout(() => {
          // don't hide navigation when scrolling to the top of the page
          if (scrollY) showNavigationBarVar(false)
        }, timerDisappearTimeout)
      }
    }

    handleScroll()
    document.addEventListener('scroll', handleScroll, {
      passive: true,
    })

    // eslint-disable-next-line consistent-return
    return () => document.removeEventListener('scroll', handleScroll)
  }, [ignoreScrollUp, timerDisappearTimeout])
  const [navigationBar, setNavigationBar] = useState<HTMLDivElement | null>(
    null,
  )
  const isAtTop = useIsAtTop()
  // The nav bar must stay visible when the user is interacting with it
  useEffect(() => {
    if (!navigationBar) {
      return
    }

    const handleMouseEnter = () => {
      clearTimeout(timer.current)
    }
    const handleMouseLeave = () => {
      if (isAtTop) {
        return
      }
      timer.current = setTimeout(() => {
        showNavigationBarVar(false)
      }, timerDisappearTimeout)
    }

    navigationBar.addEventListener('mouseenter', handleMouseEnter)
    navigationBar.addEventListener('mouseleave', handleMouseLeave)
    // eslint-disable-next-line consistent-return
    return () => {
      navigationBar.removeEventListener('mouseenter', handleMouseEnter)
      navigationBar.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [navigationBar, isAtTop, timerDisappearTimeout])

  const setRef = useCallback((elem: HTMLDivElement | null) => {
    setNavigationBar(elem)
  }, [])

  return [setRef, showNavigationBar && blockNavigationBar === 0] as const
}

type Props = ComponentProps<typeof NavigationBar>

const StickyNavigationBar = (props: Props) => {
  const [ref, show] = useShowNavigationBar()
  const isAtTop = useIsAtTop()

  return (
    <StyledNavigationBar
      show={show}
      isAtTop={isAtTop}
      data-sticky
      fullLogo={isAtTop}
      ref={ref}
      isNavigationOpen={isNavigationOpen()}
      {...props}
    />
  )
}

export default StickyNavigationBar
