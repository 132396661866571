import { ReactElement, SVGProps } from 'react'

import Vimeo from '../icons/Vimeo.svg'
import Facebook from '../icons/Facebook.svg'
import Instagram from '../icons/Instagram.svg'
import LinkedIn from '../icons/LinkedIn.svg'
import YachtingFacebook from '../icons/yachting/social/Facebook.svg'
import YachtingInstagram from '../icons/yachting/social/Instagram.svg'
import YachtingLinkedIn from '../icons/yachting/social/Linkedin.svg'
import YachtingTwitter from '../icons/yachting/social/X.svg'
import YachtingYoutube from '../icons/yachting/social/Youtube.svg'
import XplorerWhatsApp from '../icons/xplorer/social/WhatsApp.svg'
import XplorerMail from '../icons/xplorer/social/Mail.svg'
import XplorerLinkedIn from '../icons/xplorer/social/Linkedin.svg'
import XplorerFacebook from '../icons/xplorer/social/Facebook.svg'
import { currentSite, Site } from '../sites'

interface Props extends SVGProps<SVGElement> {
  icon:
    | 'vimeo'
    | 'facebook'
    | 'instagram'
    | 'linkedin'
    | 'twitter'
    | 'youtube'
    | 'whatsApp'
    | 'mail'
}

// Let TS check the switch statement for completeness, not eslint
// eslint-disable-next-line consistent-return
const SocialIcon = ({ icon, ...others }: Props): ReactElement => {
  // eslint-disable-next-line default-case
  switch (icon) {
    case 'vimeo':
      return <Vimeo {...others} />

    case 'facebook':
      if (currentSite === Site.Yachting) {
        return <YachtingFacebook {...others} />
      }

      if (currentSite === Site.Xplorer || currentSite === Site.YachtSupport) {
        return <XplorerFacebook {...others} />
      }

      return <Facebook {...others} />

    case 'instagram':
      if (currentSite === Site.YachtSupport || currentSite === Site.Yachting) {
        return <YachtingInstagram {...others} />
      }

      return <Instagram {...others} />

    case 'linkedin':
      if (currentSite === Site.Yachting) {
        return <YachtingLinkedIn {...others} />
      }

      if (currentSite === Site.Xplorer || currentSite === Site.YachtSupport) {
        return <XplorerLinkedIn {...others} />
      }

      return <LinkedIn {...others} />

    case 'twitter':
      return <YachtingTwitter {...others} />

    case 'youtube':
      return <YachtingYoutube {...others} />

    case 'whatsApp':
      return <XplorerWhatsApp {...others} />

    case 'mail':
      return <XplorerMail {...others} />
  }
}

export default SocialIcon
