import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'
import { useInView } from 'react-intersection-observer'

import AnimateText, { hiddenClassName } from '../components/AnimateText'
import Section from '../components/Section'
import {
  isSiteYachting,
  isSiteAmels,
  isThemeYachtingWomenInYachtingLandingspage,
} from '../themes'

export const IntroductionContent = styled.div``
export const Overlines = styled.div(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: ${theme.spacing.x1}px;
    margin-bottom: ${theme.spacing.x3}px;
    font-size: 12px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex-direction: row;
      max-width: 580px;
      gap: ${theme.spacing.x3}px ${theme.spacing.x8}px;
      margin: 0 auto ${theme.spacing.x3}px;
      justify-content: center;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      max-width: 100%;
      gap: ${theme.spacing.x8}px;
    }

    ${isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      display: none;
    `}
  `,
)
const Hyphen = styled.span(({ theme }) => [
  css`
    display: none;
  `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      display: inline-block;
      font-family: ${theme.fonts.HelveticaNeue.style.fontFamily}, sans-serif;
      font-style: normal;
      font-size: 30px;
      margin-left: ${theme.spacing.x1}px;
    `,
])
const Overline = styled.article(({ theme }) => [
  css`
    text-align: left;
    color: ${theme.colors.amels.beach};
    text-transform: uppercase;
  `,
  isSiteYachting(theme) &&
    css`
      ${theme.text.overlineSmall(theme)};
    `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      ${theme.text.subHeading(theme)};
      text-transform: none;
      letter-spacing: 0;
    `,
])
export const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)};
    max-width: 738px;
    margin-top: 0;
    margin-bottom: ${theme.spacing.x1}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: 0 auto ${theme.spacing.x2}px;
    }
  `,
)
export const SubTitle = styled.div(
  ({ theme }) => css`
    ${theme.text.subHeading(theme)};
    max-width: 738px;
    margin-bottom: ${theme.spacing.x6}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: 0 auto ${theme.spacing.x6}px;
    }
  `,
)
export const Text = styled.div(({ theme }) => [
  css`
    max-width: 868px;
    ${theme.text.body(theme)}
    margin-bottom: ${theme.spacing.x1}px;

    & p:last-child {
      margin-bottom: 0;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: 0 auto;
    }
  `,
  isSiteYachting(theme) &&
    css`
      font-size: 18px;
      font-weight: 300;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        font-size: 22px;
      }
    `,
])
export const WomenInYachtingOverlines = styled.div(({ theme }) => [
  css`
    display: none;
  `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: ${theme.spacing.x1}px;
      width: 100%;
      margin-top: ${theme.spacing.x3}px;
      font-size: 12px;
      align-items: center;

      ${Hyphen} {
        display: none;
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        flex-direction: row;
        gap: ${theme.spacing.x1}px;
        max-width: 580px;
        margin: ${theme.spacing.x4}px auto 0;
        justify-content: center;

        ${Hyphen} {
          display: inline-block;
        }
      }
      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        max-width: 100%;
      }

      ${Overline} {
        &:last-of-type {
          ${Hyphen} {
            display: none;
          }
        }
      }
    `,
])
export const IntroductionContainer = styled(Section)<{
  background?: Background
}>(({ theme, background }) => [
  css`
    padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      text-align: center;
      padding: ${theme.spacing.x20}px ${theme.spacing.x5}px
        ${theme.spacing.x10}px;
    }
  `,
  isSiteAmels(theme) &&
    css`
      color: ${theme.colors.amels.deepBayAqua};
      background: ${theme.colors.amels.marble};
    `,
  isSiteYachting(theme) &&
    css`
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        text-align: center;
        padding: ${theme.spacing.x10}px ${theme.spacing.x5}px
          ${theme.spacing.x10}px;
      }

      ${background === 'white' && `background: ${theme.colors.yachting.white};`}
      ${background === 'silver10' &&
      `background: ${theme.colors.yachting.silver10};`}
    `,
  isSiteYachting(theme) &&
    background === 'white' &&
    css`
      ${Text} {
        color: ${theme.colors.yachting.silver};
      }
    `,
])

type Background = 'white' | 'silver10'
interface Props
  extends Omit<
    ComponentProps<typeof IntroductionContainer>,
    'children' | 'title' | 'sectionIndicatorVariant'
  > {
  title?: ReactNode
  subTitle?: ReactNode
  overlines?: Array<{ key: string; text: ReactNode }>
  text: ReactNode
  background?: Background
}

const Introduction = ({
  title,
  subTitle,
  overlines,
  text,
  background,
  ...others
}: Props) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
    delay: 400,
  })

  const numOverlines = overlines ? overlines.length : 0

  return (
    <IntroductionContainer
      data-testid="introduction"
      sectionIndicatorVariant="dark"
      background={background}
      ref={ref}
      className={!inView ? hiddenClassName : undefined}
      {...others}
    >
      <IntroductionContent>
        <Overlines>
          {overlines?.map(({ key, text }, index) => (
            <Overline key={key}>
              <AnimateText delay={index * 100}>{text}</AnimateText>
            </Overline>
          ))}
        </Overlines>
        {title && (
          <Title>
            <AnimateText delay={numOverlines * 100}>{title}</AnimateText>
          </Title>
        )}
        {subTitle && (
          <SubTitle>
            <AnimateText delay={numOverlines * 100 + (title ? 200 : 0)}>
              {subTitle}
            </AnimateText>
          </SubTitle>
        )}

        <Text>
          <AnimateText
            delay={
              numOverlines * 100 + (title ? 200 : 0) + (subTitle ? 200 : 0)
            }
          >
            {text}
          </AnimateText>
        </Text>

        <WomenInYachtingOverlines>
          {overlines?.map(({ key, text }, index) => (
            <Overline key={key}>
              <AnimateText delay={index * 100}>
                {text}
                <Hyphen>-</Hyphen>
              </AnimateText>
            </Overline>
          ))}
        </WomenInYachtingOverlines>
      </IntroductionContent>
    </IntroductionContainer>
  )
}

export default Introduction
