import { ComponentProps } from 'react'

import PortfolioNavigation from '../components/PortfolioNavigation'
import storyblokLinkResolver from '../utils/storyblokLinkResolver'
import { SiteStoryblok } from './storyblok.generated'
import { mapImage } from '../utils/mapImage'

interface Props
  extends Omit<ComponentProps<typeof PortfolioNavigation>, 'title' | 'items'> {
  siteStory: SiteStoryblok
}

const PortfolioNavigationBlok = ({
  siteStory: { mainNavigationBlocks },
  ...others
}: Props) => {
  const portfolioNavigationItem = mainNavigationBlocks.find(
    (item) => item.codeId === 'portfolio',
  )
  if (!portfolioNavigationItem?.subNavigationBlocks) {
    return null
  }

  return (
    <PortfolioNavigation
      title={portfolioNavigationItem.title}
      items={portfolioNavigationItem.subNavigationBlocks.map(
        ({ _uid, title, link, image }) => ({
          key: _uid,
          title,
          href: storyblokLinkResolver(link),
          image: mapImage(image)?.src,
        }),
      )}
      {...others}
    />
  )
}

export default PortfolioNavigationBlok
