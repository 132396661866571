import { ComponentProps } from 'react'

import NavigationBar from '../components/NavigationBar/NavigationBar'
import StickyNavigationBar from '../components/StickyNavigationBar'
import storyblokLinkResolver from '../utils/storyblokLinkResolver'
import { SiteStoryblok } from './storyblok.generated'
import { hasTopBar } from './patterns/TopBarBlok'

export const getMenuItems = ({ mainNavigationBlocks }: SiteStoryblok) =>
  mainNavigationBlocks
    .filter(({ codeId }) => codeId !== 'site')
    .map(({ _uid, codeId, title, link }) => ({
      key: _uid,
      id: codeId,
      title,
      href: storyblokLinkResolver(link),
    }))

interface Props
  extends Omit<ComponentProps<typeof NavigationBar>, 'menuItems'> {
  siteStory: SiteStoryblok
}

const NavigationBarBlok = ({ siteStory, ...others }: Props) => (
  <StickyNavigationBar
    menuItems={getMenuItems(siteStory)}
    menuTitle={siteStory.menuTitle}
    hasTopBar={hasTopBar(siteStory)}
    {...others}
  />
)

export default NavigationBarBlok
