import { MutableRefObject, useEffect, useState } from 'react'

const useVideoIsPlaying = (
  videoRef: MutableRefObject<HTMLVideoElement | null>,
) => {
  const [isPlaying, setIsPlaying] = useState(false)
  useEffect(() => {
    const elem = videoRef.current
    if (!elem) {
      return
    }

    const updateIsPlaying = () => {
      setIsPlaying(!elem.paused)
    }
    updateIsPlaying()

    elem.addEventListener('playing', updateIsPlaying)
    elem.addEventListener('pause', updateIsPlaying)
    // eslint-disable-next-line consistent-return
    return () => {
      elem.removeEventListener('playing', updateIsPlaying)
      elem.removeEventListener('pause', updateIsPlaying)
    }
  }, [videoRef])

  return isPlaying
}

export default useVideoIsPlaying
