import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'
import { FieldError } from 'react-hook-form'
import {
  isSiteXplorer,
  isThemeYachtingWomenInYachtingLandingspage,
} from '../themes'

const Label = styled.label<{ variant?: 'topbar' }>(({ theme, variant }) => [
  css`
    display: block;
    margin: ${theme.spacing.x2}px 0;
  `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      margin: ${theme.spacing.x3}px 0;
    `,
  variant === 'topbar' &&
    css`
      color: ${theme.colors.xplorer.white};
      border: 2px solid ${theme.colors.xplorer.white};
      border-radius: 9999px;
      padding: 0;
      width: 100%;
      max-width: 180px;
      margin: 0;
    `,
])
const Error = styled.div(({ theme }) => [
  css`
    margin-top: ${theme.spacing.x1}px;
    color: ${theme.colors.amels.hazySunsetOrange};
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 3.43px;
  `,
  isSiteXplorer(theme) &&
    css`
      color: ${theme.colors.xplorer.error};
    `,
])

interface Props extends ComponentProps<typeof Label> {
  children: ReactNode
  error?: FieldError
  variant?: 'topbar'
  'data-testid'?: string
}

const FormField = ({
  children,
  error,
  variant,
  'data-testid': testid = 'formField',
  ...others
}: Props) => (
  <Label data-testid={testid} variant={variant} {...others}>
    {children}

    {error && (
      <Error data-testid={`${testid}.error`}>
        {error.type === 'required' && !error.message
          ? 'This field is required.'
          : error.message || 'Please enter a valid value.'}
      </Error>
    )}
  </Label>
)

export default FormField
