import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode, useState } from 'react'
import { NODE_PARAGRAPH } from 'storyblok-rich-text-react-renderer'

import Button, {
  mediumButtonCssYachting,
  whiteOutlineButtonCss,
  primaryOutlineButtonCssXplorer,
} from '../components/Button'
import FormFieldConditionsCheckbox from '../components/FormFieldConditionsCheckbox'
import FormFieldText from '../components/FormFieldText'
import RecaptchaContainer from '../components/RecaptchaContainer'
import RecaptchaDisclaimer from '../components/RecaptchaDisclaimer'
import RecaptchaLoader from '../components/RecaptchaLoader'
import RichText, { dummyResolver } from '../components/RichText'
import stripUndefined from '../utils/stripUndefined'
import { useRecaptchaForm } from '../utils/useRecaptchaForm'
import { FooterStoryblok } from './storyblok.generated'
import { operations } from '../api/form2lead.generated'
import {
  isSiteYachting,
  isSiteAmels,
  isThemeYachtingWomenInYachtingLandingspage,
  isSiteXplorer,
  isSiteYachtSupport,
} from '../themes'
import { heading6, body } from '../themes/xplorer/text'
import {
  heading6 as heading6YachtSupport,
  body as bodyYachtSupport,
} from '../themes/yachtsupport/text'
import { Site } from '../sites'
import Icon from '../icons/yachtsupport/Explore.svg'

const Submit = styled(Button)(
  ({ theme }) => css`
    margin-top: ${theme.spacing.x3}px;

    ${isSiteAmels(theme) &&
    css`
      ${whiteOutlineButtonCss(theme)}
    `}

    ${isSiteYachting(theme) &&
    css`
      ${mediumButtonCssYachting(theme)}
    `}

    ${isSiteXplorer(theme) &&
    css`
      ${primaryOutlineButtonCssXplorer(theme)}
      width: fit-content;
    `}
  `,
)
const Retry = styled(Button)(
  ({ theme }) => css`
    margin-top: ${theme.spacing.x3}px;

    ${isSiteAmels(theme) &&
    css`
      ${whiteOutlineButtonCss(theme)}
    `}

    ${isSiteYachting(theme) &&
    css`
      ${mediumButtonCssYachting(theme)}
    `}
  `,
)

const Title = styled.div(
  ({ theme }) => css`
    font-weight: 600;

    ${isSiteYachting(theme) &&
    css`
      ${theme.text.heading4(theme)}
      margin-bottom: ${theme.spacing.x3}px;
      color: ${theme.colors.yachting.white};
    `}

    ${isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      font-family: ${theme.fonts.Georgia.style.fontFamily}, sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.2;
      color: ${theme.colors.yachting.silver30};

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        font-size: 20px;
      }
    `}

    ${isSiteXplorer(theme) &&
    css`
      ${heading6(theme)}
      color: ${theme.colors.xplorer.white};
      margin-bottom: ${theme.spacing.x2}px;
    `}

    ${isSiteYachtSupport(theme) &&
    css`
      ${heading6YachtSupport(theme)}
      color: ${theme.colors.xplorer.white};
      margin-bottom: ${theme.spacing.x2}px;
    `}
  `,
)
const StyledForm = styled.form(
  ({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints
        .desktop}px) and (max-width: ${theme.breakpoints.desktopLarge}px) {
      width: 50%;
      ${isSiteYachtSupport(theme) ||
      (isSiteXplorer(theme) &&
        css`
          width: 100%;
        `)}
    }
  `,
)
const NewsletterText = styled.div(
  ({ theme }) => css`
    ${isSiteAmels(theme) &&
    css`
      margin-bottom: ${theme.spacing.x8}px;
    `}

    ${isSiteYachting(theme) &&
    css`
      margin-bottom: ${theme.spacing.x1}px;
    `}

    ${isSiteXplorer(theme) &&
    css`
      ${body(theme)}
      margin-bottom: ${theme.spacing.x4}px;
    `}

    ${isSiteYachtSupport(theme) &&
    css`
      ${bodyYachtSupport(theme)}
      color: ${theme.colors.yachtSupport.white};
      margin-bottom: ${theme.spacing.x4}px;
    `}
  `,
)

const Success = styled.div(
  ({ theme }) => css`
    ${isSiteYachtSupport(theme) &&
    css`
      width: 100%;
      max-width: 375px;
      text-align: center;
    `}
  `,
)

const StyledIcon = styled(Icon)(
  ({ theme }) => css`
    width: ${theme.spacing.x7}px;
    height: ${theme.spacing.x7}px;
    margin-bottom: ${theme.spacing.x4}px;
  `,
)

interface Props {
  newsletterTitle: ReactNode
  newsletterText: ReactNode
  emailBlock: FooterStoryblok['newsletterEmailInputBlock']
  privacyPolicyBlock: FooterStoryblok['newsletterPrivacyPolicyBlock']
  submitBlock: FooterStoryblok['newsletterSubmitBlock']
  thankYouBlock: FooterStoryblok['newsletterThankYouBlock']
  errorBlock: FooterStoryblok['newsletterErrorBlock']
  pardotConfiguration: FooterStoryblok['newsletterPardotConfiguration']
}

const NewsletterFormBlok = ({
  emailBlock,
  privacyPolicyBlock,
  submitBlock,
  thankYouBlock,
  errorBlock,
  pardotConfiguration,
  newsletterTitle,
  newsletterText,
  ...others
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [greatSuccess, setGreatSuccess] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formError, setFormError] = useState(false)
  interface FieldValues {
    email: string
    acceptPrivacyPolicy?: boolean
  }
  const {
    recaptchaElementId,
    isLoading,
    getRecaptchaToken,
    register,
    handleSubmit,
    formState: { errors },
  } = useRecaptchaForm<FieldValues>()
  const onSubmit = async (data: FieldValues) => {
    if (!data.acceptPrivacyPolicy) {
      // eslint-disable-next-line no-useless-return
      return
    }

    try {
      const recaptchaResponse = await getRecaptchaToken()
      // We can't set isSubmitting prior to the getRecaptchaToken call, as it
      // may get permanently stuck when the user closes the modal. There are no
      // events to inform us of the user closing the prompt.
      setIsSubmitting(true)

      // We could also map this on API, but it would be nice to keep fieldnames in sync with backend API
      const request: NonNullable<
        operations['CreateProspect']['requestBody']
      >['content']['application/json'] = {
        email: data.email,
        pardotEndpoint: pardotConfiguration,
        // for news letter subscriptions we can hardcode this as this is an implicit checkbox
        receiveNewsletter: true,
        consentStatement: data.acceptPrivacyPolicy ? 'true' : 'false',
        ...recaptchaResponse,
      }
      const response = await fetch('/api/prospect', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(stripUndefined(request)),
      })
      const result = await response.json()

      if (!result.success) {
        setFormError(true)
        return
      }
      setGreatSuccess(true)
    } catch (err) {
      setFormError(true)
      throw err
    } finally {
      setIsSubmitting(false)
    }
  }

  if (greatSuccess) {
    return (
      <Success data-testid="newsletterForm.success">
        {Site.YachtSupport && <StyledIcon />}
        <Title>{thankYouBlock[0].title}</Title>
        <div>{thankYouBlock[0].text}</div>
      </Success>
    )
  }

  if (formError) {
    return (
      <div data-testid="newsletterForm.success">
        <Title>{errorBlock[0].title}</Title>
        <div>{errorBlock[0].text}</div>
        <Retry
          type="button"
          data-testid="newsletterForm.submit"
          disabled={isSubmitting}
          onClick={() => {
            setFormError(false)
          }}
        >
          {errorBlock[0].linkText}
        </Retry>
      </div>
    )
  }

  return (
    <div {...others}>
      <Title>{newsletterTitle}</Title>
      <NewsletterText>{newsletterText}</NewsletterText>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <FormFieldText
          label={emailBlock[0].label}
          error={errors.email}
          {...register('email', {
            required: emailBlock[0].errorText,
          })}
          type="email"
          autoComplete="email"
          required
          data-testid="newsletterForm.email"
        />
        <div>
          <RecaptchaLoader />
          <RecaptchaDisclaimer color="deepBayAqua30" />
          <RecaptchaContainer id={recaptchaElementId} />
        </div>
        <FormFieldConditionsCheckbox
          label={
            <RichText nodeResolvers={{ [NODE_PARAGRAPH]: dummyResolver }}>
              {privacyPolicyBlock[0].label}
            </RichText>
          }
          error={errors.acceptPrivacyPolicy}
          {...register('acceptPrivacyPolicy', {
            required: privacyPolicyBlock[0].errorText,
          })}
          required
          data-testid="newsletterForm.acceptPrivacyPolicy"
        />

        <Submit
          type="submit"
          data-testid="newsletterForm.submit"
          disabled={isSubmitting || isLoading}
          variant="primary"
        >
          {submitBlock[0].title}
        </Submit>
      </StyledForm>
    </div>
  )
}

export default NewsletterFormBlok
