// Here we configure the loader used in our Image component,
// we want to use the types from next/image
// eslint-disable-next-line no-restricted-imports
import { ImageLoader, ImageProps } from 'next/image'
import getPublicIdFromCloudinaryUrl from './getPublicIdFromCloudinaryUrl'
import isBynderUrl from './isBynderUrl'
import isCloudinaryUrl from './isCloudinaryUrl'
import makeBynderImageUrl from './makeBynderImageUrl'
import makeCloudinaryImageUrl from './makeCloudinaryImageUrl'

interface ImageOptions {
  aspectRatio?: string
  transformations?: string[]
}

const cloudinaryLoader =
  ({ aspectRatio, transformations }: ImageOptions): ImageLoader =>
  ({ src, width }) => {
    const mergedTransformations = [
      'q_auto',
      'f_auto',
      aspectRatio ? `w_${width},c_fill,ar_${aspectRatio}` : `w_${width}`,
      ...(transformations || []),
    ]
    return makeCloudinaryImageUrl({
      publicId: getPublicIdFromCloudinaryUrl(src),
      transformations: mergedTransformations,
    })
  }

const bynderLoader =
  ({ aspectRatio, transformations }: ImageOptions): ImageLoader =>
  ({ src, width }) => {
    const calculateHeight = () => {
      if (!aspectRatio) return null
      const splitRatio = aspectRatio.split(':')
      const rat1 = Number(splitRatio[0])
      const rat2 = Number(splitRatio[1])
      const ratio = width / rat1

      return Math.round(ratio * rat2)
    }
    const mergedTransformations = [
      aspectRatio
        ? `transform:fill,width:${width},height:${calculateHeight()}`
        : `transform:fill,width:${width}`,
      ...(transformations || []),
    ]

    return makeBynderImageUrl({
      src,
      transformations: mergedTransformations,
    })
  }

export const getNextImageLoader = (
  src: ImageProps['src'],
  options: ImageOptions,
): { src: ImageProps['src']; loader: ImageLoader | undefined } => {
  if (typeof src === 'string' && isCloudinaryUrl(src)) {
    return {
      src,
      loader: cloudinaryLoader(options),
    }
  }

  if (typeof src === 'string' && isBynderUrl(src)) {
    return {
      src,
      loader: bynderLoader(options),
    }
  }

  // undefined means use the default next/image loader (e.g static image data or NOT cloudinary/bynder)
  return { src, loader: undefined }
}
