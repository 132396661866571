const stripEmpty = <T extends Record<string, unknown>>(obj: T): T => {
  Object.keys(obj).forEach((key) => {
    // eslint-disable-next-line security/detect-object-injection
    if (obj[key] === undefined || obj[key] === '' || obj[key] === null) {
      // eslint-disable-next-line security/detect-object-injection,no-param-reassign
      delete obj[key]
    }
  })
  return obj
}

export default stripEmpty
