import Script from 'next/script'

import { StoryblokStory } from '../utils/storyblok'

// https://github.com/storyblok/storyblok-js/blob/main/lib/types.ts
declare global {
  interface Window {
    StoryblokBridge: typeof StoryblokBridge
  }
}

interface StoryblokBridgeConfig {
  resolveRelations?: [string]
  customParent?: string
  preventClicks?: boolean
}

type StoryblokEventPayload = {
  action: StoryblokBridgeEvents
  story: StoryblokStory
}

type StoryblokBridgeEvents =
  | 'customEvent'
  | 'published'
  | 'input'
  | 'change'
  | 'unpublished'
  | 'enterEditmode'

export class StoryblokBridge {
  // We can't the .config property, but we converted the interface to a class so it is compatible with the 'new' operator
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private config: StoryblokBridgeConfig

  constructor(config: StoryblokBridgeConfig) {
    this.config = config
  }

  pingEditor!: (event: unknown) => void

  isInEditor!: () => boolean

  enterEditmode!: () => void

  on!: (
    event: StoryblokBridgeEvents | StoryblokBridgeEvents[],
    callback: (payload?: StoryblokEventPayload) => void,
  ) => void
}

let resolveStoryblokBridgeLoader: (value: StoryblokBridge) => void
export const storyblokBridgeLoaderPromise = new Promise<StoryblokBridge>(
  (resolve) => {
    resolveStoryblokBridgeLoader = resolve
  },
)

const StoryblokBridgeLoader = () => {
  const onLoaded = () => {
    if (!window.StoryblokBridge) {
      return
    }
    const storyblokBridgeInstance = new window.StoryblokBridge({
      preventClicks: true,
    })
    storyblokBridgeInstance.on(['published', 'change'], () => {
      window.location.reload()
    })
    resolveStoryblokBridgeLoader(storyblokBridgeInstance)
  }

  return (
    <Script
      src="https://app.storyblok.com/f/storyblok-v2-latest.js"
      strategy="afterInteractive"
      onLoad={onLoaded}
    />
  )
}

export default StoryblokBridgeLoader
