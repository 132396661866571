import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'

import Link from './Link'
import IconInteractable, {
  activeCss,
  hoverCss,
  useInteractWithIconInteractable,
} from './IconInteractable'

const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ theme, active }) => [
  css`
    color: ${theme.colors.amels.hazySunsetOrange};
    text-decoration: none;

    :hover {
      text-decoration: none;
    }
  `,
  hoverCss(theme),
  active && activeCss(theme),
])

interface Props extends ComponentProps<typeof Link> {
  icon: ReactNode
  weight?: 'bold' | 'default'
  children: ReactNode
}

const IconLink = ({ icon, children, href, onClick, ...others }: Props) => {
  const [active, handleClick] = useInteractWithIconInteractable()

  return (
    <StyledLink
      href={href}
      active={active}
      onClick={handleClick(onClick)}
      {...others}
    >
      <IconInteractable icon={icon} weight="bold">
        {children}
      </IconInteractable>
    </StyledLink>
  )
}

export default IconLink
