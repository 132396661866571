import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ElementType, forwardRef, InputHTMLAttributes, ReactNode } from 'react'
import { FieldError } from 'react-hook-form'
import Checkbox from './Checkbox'

import FormField from './FormField'
import {
  isSiteAmels,
  isSiteXplorer,
  isSiteYachting,
  isSiteYachtSupport,
} from '../themes'

const CheckboxContainer = styled.div(({ theme }) => [
  css`
    display: flex;
    gap: ${theme.spacing.x2}px;
  `,
  isSiteAmels(theme) &&
    css`
      line-height: 20px;
    `,

  isSiteXplorer(theme) &&
    css`
      align-items: center;
    `,
])
const StyledCheckbox = styled(Checkbox)(({ theme }) => [
  css`
    display: block;
    flex: 0 0 auto;
  `,
  isSiteYachting(theme) &&
    css`
      color: ${theme.colors.yachting.orange};
    `,
  isSiteXplorer(theme) &&
    css`
      color: ${theme.colors.xplorer.secondaryDamenYachtingOrange};
    `,
  isSiteYachtSupport(theme) &&
    css`
      color: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
    `,
])
const Label = styled('div', {
  shouldForwardProp: (prop) => prop !== 'colorScheme',
})<{
  colorScheme?: 'light' | 'dark'
}>(({ theme, colorScheme }) => [
  `
      align-self: center;
    `,
  isSiteXplorer(theme) &&
    css`
      color: ${colorScheme === 'dark'
        ? theme.colors.xplorer.white
        : theme.colors.xplorer.primaryXPLavaBlack};
    `,
  isSiteYachtSupport(theme) &&
    css`
      color: ${colorScheme === 'dark'
        ? theme.colors.yachtSupport.white
        : theme.colors.yachtSupport.secondaryYSWarmGrey};
    `,
])

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: ReactNode
  colorScheme?: 'light' | 'dark'
  error?: FieldError
  required?: boolean
  'data-testid'?: string
  as?: ElementType
}

// eslint-disable-next-line react/display-name
const FormFieldCheckbox = forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      colorScheme = 'dark',
      error,
      required,
      'data-testid': testid,
      className,
      as,
      ...others
    }: Props,
    ref,
  ) => (
    <FormField error={error} data-testid={testid} className={className} as={as}>
      <CheckboxContainer>
        <StyledCheckbox
          as="div"
          aria-required={required}
          aria-invalid={Boolean(error)}
          ref={ref}
          {...others}
        />
        <Label colorScheme={colorScheme}>{label}</Label>
      </CheckboxContainer>
    </FormField>
  ),
)

export default FormFieldCheckbox
