import { Theme, css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps } from 'react'

import { hiddenClassName } from './AnimateText'
import Button, {
  invisibleButtonCss,
  invisibleButtonXplorerCss,
  invisibleButtonYachtSupportCss,
} from './Button'
import AmelsIcon from '../icons/amels/CloseButton.svg'
import YachtingIcon from '../icons/yachting/CloseButton.svg'
import YachtsupportIcon from '../icons/yachtsupport/Close.svg'
import { isSiteXplorer, isSiteYachtSupport, isSiteYachting } from '../themes'
import { Site, currentSite } from '../sites'

const getCurrentInvisibleButton = (currentSite: string) => {
  switch (currentSite) {
    case Site.Xplorer:
      return invisibleButtonXplorerCss

    case Site.YachtSupport:
      return invisibleButtonYachtSupportCss

    default:
      return invisibleButtonCss
  }
}

const InvisibleButton = styled(Button)(({ theme }) => [
  getCurrentInvisibleButton(currentSite),
  css`
    padding: ${theme.spacing.x1}px;
  `,
  isSiteXplorer(theme) &&
    css`
      :hover {
        ::before {
          background-color: var(--background);
        }
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      :hover {
        ::before {
          background-color: var(--background);
        }
      }
    `,
])
const ringLength = 522
const closeIconCss = (
  theme: Theme,
  variant: 'default' | 'light' = 'default',
) => [
  css`
    width: 100%;
    height: 100%;

    .circle {
      opacity: 0;
      fill: none;
    }

    @media (prefers-reduced-motion: no-preference) {
      .circle {
        transition: opacity 400ms ease-in-out;
      }
      .icon {
        transition: color 400ms ease-in-out;
      }
      .ring {
        stroke-dasharray: ${ringLength};
        stroke-dashoffset: -${ringLength};
        transform: rotate(-90deg);
        transform-origin: center;
        :not(.${hiddenClassName} &) {
          animation: ${keyframes`
            0% {
              stroke-dashoffset: ${ringLength};
            }
            30% {
              transform: rotate(-90deg);
            }
            to {
              stroke-dashoffset: 0;
              transform: rotate(90deg);
            }
          `} 1000ms ease-in-out forwards;
          animation-delay: var(--animation-delay, 0ms);
        }
      }
      .icon {
        opacity: 0;
        will-change: opacity;
        :not(.${hiddenClassName} &) {
          animation: ${keyframes`
            to {
              opacity: 1;
            }
          `} 600ms linear forwards;
          animation-delay: calc(var(--animation-delay, 0ms) + 600ms);
        }
      }
    }

    ${InvisibleButton}:hover & {
      .circle {
        fill: currentColor;
        opacity: 1;
      }
      .icon {
        color: ${theme.colors.amels.silk};
      }
    }
  `,
  variant === 'light' &&
    css`
      color: ${theme.colors.amels.silk};

      .circle {
        fill: ${theme.colors.amels.silk};
        /* opacity: 1; */
      }
      .icon {
        fill: ${theme.colors.amels.deepBayAqua};
        stroke: ${theme.colors.amels.deepBayAqua};
      }

      @media (prefers-reduced-motion: no-preference) {
        .circle {
          opacity: 0;
          will-change: opacity;
          animation: ${keyframes`
          to {
            opacity: 1;
          }
        `} 600ms ease-in-out forwards;
          animation-delay: calc(var(--animation-delay, 0ms) + 600ms);
          transition: r 400ms ease-out;
        }
      }

      ${InvisibleButton}:hover & {
        .circle {
          r: 87;
        }
      }
    `,
  isSiteYachting(theme) &&
    css`
      color: ${theme.colors.yachting.orange};
    `,
  isSiteXplorer(theme) &&
    css`
      svg {
        circle {
          display: none;
        }
      }

      ${InvisibleButton} & {
        .circle {
          r: 0;
        }

        .ring {
          stroke: ${theme.colors.xplorer.secondaryDamenYachtingOrange};
        }

        .icon {
          fill: ${theme.colors.xplorer.secondaryDamenYachtingOrange};
          stroke: ${theme.colors.xplorer.secondaryDamenYachtingOrange};
        }

        :hover {
          .circle {
            r: 0;
          }

          .icon {
            fill: ${theme.colors.xplorer.white};
            stroke: ${theme.colors.xplorer.white};
          }
        }
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      padding: ${theme.spacing.x1}px;
      border: 1px solid
        ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
      background-color: ${theme.colors.yachtSupport.white};
      color: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x1}px;
        border: 1px solid ${theme.colors.yachtSupport.grey03};
        background-color: ${theme.colors.yachtSupport.white};
        color: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
      }
    `,
]
const GetCurrentIcon = (currentSite: string) => {
  switch (currentSite) {
    case Site.Amels:
      return AmelsIcon

    case Site.YachtSupport:
      return YachtsupportIcon

    default:
      return YachtingIcon
  }
}

const CloseIcon = styled(GetCurrentIcon(currentSite), {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant?: 'default' | 'light' }>(({ theme, variant }) =>
  closeIconCss(theme, variant),
)

interface Props extends ComponentProps<typeof CloseIcon> {
  'data-testid'?: string
  variant?: 'default' | 'light'
}

const CloseButton = ({
  className,
  onClick,
  'data-testid': testid,
  variant,
  ...props
}: Props) => (
  <InvisibleButton className={className} onClick={onClick} data-testid={testid}>
    <CloseIcon variant={variant} aria-label="Close" {...props} />
  </InvisibleButton>
)

export default CloseButton
