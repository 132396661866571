import isCloudinaryUrl from './isCloudinaryUrl'
import getPublicIdFromCloudinaryUrl from './getPublicIdFromCloudinaryUrl'
import makeCloudinaryVideoUrl from './makeCloudinaryVideoUrl'
import isBynderUrl from './isBynderUrl'

const makeVideoSources = (video: string) => {
  if (isCloudinaryUrl(video)) {
    const videoPublicId = video && getPublicIdFromCloudinaryUrl(video)
    return (
      <>
        <source
          src={makeCloudinaryVideoUrl({
            publicId: videoPublicId,
            format: 'webm',
          })}
          type="video/webm"
        />
        <source
          src={makeCloudinaryVideoUrl({
            publicId: videoPublicId,
            format: 'mp4',
          })}
          type="video/mp4"
        />
        <source
          src={makeCloudinaryVideoUrl({
            publicId: videoPublicId,
            format: 'ogv',
          })}
          type="video/ogg"
        />
      </>
    )
  }

  if (isBynderUrl(video)) {
    const videoWithoutFileExtension = video.substring(0, video.lastIndexOf('.'))

    return (
      <>
        <source src={`${videoWithoutFileExtension}.webm`} type="video/webm" />
        <source src={`${videoWithoutFileExtension}.mp4`} type="video/mp4" />
        <source src={`${videoWithoutFileExtension}.ogv`} type="video/ogg" />
      </>
    )
  }

  return <source src={video} />
}

export default makeVideoSources
