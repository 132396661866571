import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode, useEffect, useState } from 'react'
import { Transition, TransitionGroup } from 'react-transition-group'
import { ENTERED, ENTERING } from 'react-transition-group/Transition'

import Image from './Image'
import Link from './Link'
import CloseButton from './CloseButton'
import AnimateText from './AnimateText'
import repaintOnMount from '../utils/repaintOnMount'
import { showNavigationBarVar } from './StickyNavigationBar'
import useDisableBodyScroll from '../utils/useDisableBodyScroll'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`
export const LeftPane = styled.div(
  ({ theme }) => css`
    flex: 0 0 auto;
    position: relative;
    width: 100%;
    padding: ${theme.spacing.x7}px ${theme.spacing.x4}px;
    background: ${theme.colors.amels.silk};
    display: flex;
    flex-flow: column;
    overflow: auto;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      width: 75%;
      padding: ${theme.spacing.x7}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      width: 40%;
      min-width: 600px;
    }
  `,
)
export const RightPane = styled.div(
  ({ theme }) => css`
    flex: 1 1 auto;
    background: ${theme.colors.amels.body};
    display: none;
    position: relative;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: block;
    }
  `,
)
const MenuWrapper = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing.x9}px 0 ${theme.spacing.x9}px ${theme.spacing.x2}px;
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    flex-flow: column;
  `,
)
const ImageWrapper = styled.div(
  () => css`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  `,
)
const SideMenuLink = styled(Link)(
  () => css`
    display: block;
    width: 100%;
    text-decoration: none;
    color: inherit;

    :hover {
      text-decoration: none;
    }
  `,
)
const SideMenuItem = styled('li', {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ theme, active }) => [
  css`
    font-family: ${theme.fonts.Zermatt.style.fontFamily};
    font-size: 40px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${theme.colors.amels.deepBayAqua};
    transition: color 300ms ease-in-out;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 64px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-size: 72px;
    }
  `,
  active &&
    css`
      &&& {
        color: ${theme.colors.amels.deepBayAqua};
      }
    `,
])
const SideMenu = styled('ul', {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ active }) => [
  css`
    list-style: none;

    ${SideMenuItem} {
      will-change: opacity;
      transition: opacity 300ms;
    }
  `,
  active &&
    css`
      ${SideMenuItem} {
        :not(:hover) {
          opacity: 0.5;
        }
      }
    `,
])
const Label = styled.div(
  ({ theme }) => css`
    position: relative;
    margin-bottom: ${theme.spacing.x2}px;
    font-family: ${theme.fonts.Poppins.style.fontFamily};
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 3px;
    color: ${theme.colors.amels.deepBayAqua};
    text-transform: uppercase;
  `,
)
const StyledCloseButton = styled(CloseButton)(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.spacing.x5}px;
    right: ${theme.spacing.x2}px;
    width: ${theme.spacing.x6}px;
    height: ${theme.spacing.x6}px;
    color: ${theme.colors.amels.hazySunsetOrange};
    --animation-delay: 200ms;
  `,
)

interface NavigationItem {
  key: string
  title: ReactNode
  href: string
  image?: string
}

interface Props
  extends Omit<ComponentProps<typeof Container>, 'children' | 'title'> {
  title: string
  items: NavigationItem[]
  close: () => void
}

const PortfolioNavigation = ({ title, items, close, ...others }: Props) => {
  const [activeItem, setActiveItem] = useState<NavigationItem | undefined>(
    undefined,
  )

  useEffect(() => {
    showNavigationBarVar(false)
  }, [])

  const ref = useDisableBodyScroll()

  return (
    <Container data-testid="portfolioNavigation" {...others}>
      <LeftPane ref={ref}>
        <Label>
          <AnimateText direction="static" delay={200}>
            {title}
          </AnimateText>
        </Label>
        <StyledCloseButton
          onClick={close}
          data-testid="portfolioNavigation.close"
        />

        {items && items.length > 0 && (
          <MenuWrapper>
            <SideMenu
              active={activeItem !== undefined}
              onMouseLeave={() => setActiveItem(undefined)}
            >
              {items.map((item, index) => (
                <SideMenuItem
                  key={item.key}
                  onMouseEnter={() => setActiveItem(item)}
                  active={item === activeItem}
                >
                  <AnimateText delay={600 + index * 100}>
                    <SideMenuLink href={item.href}>{item.title}</SideMenuLink>
                  </AnimateText>
                </SideMenuItem>
              ))}
            </SideMenu>
          </MenuWrapper>
        )}
      </LeftPane>

      <RightPane>
        <TransitionGroup component={null}>
          {activeItem?.image && (
            <Transition key={activeItem.key} in timeout={300}>
              {(transitionState) => (
                <ImageWrapper
                  style={{
                    transition: 'opacity 300ms ease-in-out',
                    opacity: [ENTERING, ENTERED].includes(transitionState)
                      ? 1
                      : 0,
                    zIndex: [ENTERING, ENTERED].includes(transitionState)
                      ? 1
                      : 0,
                  }}
                  ref={repaintOnMount(transitionState)}
                >
                  <Image
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    src={activeItem.image!}
                    alt=""
                    style={{ objectFit: 'cover' }}
                    fill
                    sizes="60vw"
                  />
                </ImageWrapper>
              )}
            </Transition>
          )}
        </TransitionGroup>
      </RightPane>
    </Container>
  )
}

export default PortfolioNavigation
