import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'
import { isSiteAmels, isSiteYachting } from '../themes'

const StyledTable = styled.table(
  ({ theme }) => css`
    width: 100%;

    th,
    td {
      padding: ${theme.spacing.x2}px;
    }
  `,
)
const TableHead = styled.thead(({ theme }) => [
  css`
    color: ${theme.text.heading4(theme)};
    text-align: left;
    border-bottom: 1px solid ${theme.colors.yachting.darkBlue};
  `,
  isSiteYachting(theme) &&
    css`
      th,
      td {
        font-family: ${theme.fonts.Georgia.style.fontFamily};
        font-weight: 300;
      }
    `,
  isSiteAmels(theme) &&
    css`
      border-bottom-color: ${theme.colors.amels.bayGold};
    `,
])

interface Cell {
  key: string
  content: ReactNode
}
interface Row {
  key: string
  cells: Cell[]
}

interface Props extends ComponentProps<typeof StyledTable> {
  head: Cell[]
  rows: Row[]
}

const Table = ({ head, rows, ...others }: Props) => (
  <StyledTable {...others}>
    <TableHead>
      <tr>
        {head.map((cell) => (
          <th key={cell.key}>{cell.content}</th>
        ))}
      </tr>
    </TableHead>
    <tbody>
      {rows.map((row) => (
        <tr key={row.key}>
          {row.cells.map((cell) => (
            <td key={cell.key}>{cell.content}</td>
          ))}
        </tr>
      ))}
    </tbody>
  </StyledTable>
)

export default Table
