import { RichTextTableStoryblok } from '../storyblok.generated'
import ResponsiveTable from '../../components/ResponsiveTable'

interface Props {
  content: RichTextTableStoryblok
}

const RichTextTableBlok = ({ content: { table }, ...others }: Props) =>
  table ? (
    <ResponsiveTable
      head={table.thead.map((cell) => ({
        key: cell._uid,
        content: cell.value,
      }))}
      rows={table.tbody.map((row) => ({
        key: row._uid,
        cells: row.body.map((cell, index) => ({
          key: cell._uid || `${row._uid}-cell${index}`,
          content: cell.value,
        })),
      }))}
      {...others}
    />
  ) : null

export default RichTextTableBlok
