import { css, keyframes, Theme } from '@emotion/react'
import styled from '@emotion/styled'
import { HTMLAttributes, ReactNode } from 'react'

import Image from '../components/Image'
import Section from '../components/Section'
import {
  isSiteAmels,
  isSiteXplorer,
  isSiteYachting,
  isSiteYachtSupport,
  isThemeYachtingWomenInYachtingLandingspage,
} from '../themes'
import LinkButton from '../components/LinkButton'
import SidePanelButton from '../components/SidePanelButton'
import { whiteOutlineButtonCss as amelsWhiteOutlineButtonCss } from '../components/Button/Button.amels'
import { secondaryButtonCssYachting as yachtingWhiteOutlineButtonCss } from '../components/Button/Button.yachting'
import {
  IntroductionContainer,
  IntroductionContent,
  Overlines as IntroductionOverlines,
  SubTitle as IntroductionSubTitle,
  Text as IntroductionText,
  Title as IntroductionTitle,
} from './Introduction'
import { currentSite, Site } from '../sites'
import ScrollYachting from '../icons/yachting/Scroll.svg'
import ScrollXplorer from '../icons/xplorer/Scroll.svg'
import ScrollYachtSupport from '../icons/yachtsupport/Scroll.svg'
import { getNextImageProps } from '../utils/getNextImageProps'

function getXplorerTitleColor(theme: Theme, colorOption: string) {
  switch (colorOption) {
    case 'green':
      return theme.colors.xplorer.primaryXPDarkOceanGreen
    case 'bronze':
      return theme.colors.xplorer.xpBronze10Solid
    case 'white':
    default:
      return theme.colors.xplorer.white
  }
}

const ImageContainer = styled.div(({ theme }) => [
  css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `,
  isSiteAmels(theme) &&
    css`
      position: relative;
      height: 240px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        height: 320px;
      }
    `,
])
const ImageOverlay = styled.div(() => [
  css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `,
])
export const ScrollIndicator = styled.div(({ theme }) => [
  css`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 134px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    gap: ${theme.spacing.x1}px;
  `,
  isSiteYachting(theme) &&
    css`
      svg {
        width: 20px;

        @media (prefers-reduced-motion: no-preference) {
          transform-origin: top;
          transform: scaleY(0);
          animation: ${keyframes`
          0% {
            transform: scaleY(0);
          }
          16%, 100% {
            transform: scaleY(1);
          }
        `} 5000ms ease-in-out infinite;
          animation-delay: var(--animation-delay, 0ms);
        }
      }
    `,
  isSiteXplorer(theme) &&
    css`
      bottom: 40px;

      svg {
        width: 34px;

        @media screen and (min-width: ${theme.breakpoints.desktop}px) {
          width: 40px;
        }

        path {
          @media (prefers-reduced-motion: no-preference) {
            transform-origin: top;

            animation: ${keyframes`
            0%, 100% {
              transform: translateY(-1px);
            }
            16%, 65% {
              transform: translateY(2px);
            }
          `} 5000ms ease-in infinite;
            animation-delay: var(--animation-delay, 0ms);
          }
        }
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      bottom: 0px;

      svg {
        width: 24px;
        z-index: 1;

        @media screen and (min-width: ${theme.breakpoints.desktop}px) {
          bottom: 4px;
        }

        path {
          @media (prefers-reduced-motion: no-preference) {
            transform-origin: top;

            animation: ${keyframes`
            0%, 100% {
              transform: translateY(-1px);
            }
            16%, 65% {
              transform: translateY(4px);
            }
          `} 5000ms ease-in infinite;
            animation-delay: var(--animation-delay, 0ms);
          }
        }
      }

      .triangle {
        position: absolute;
        bottom: ${theme.spacing.x2}px;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 0 28px 28px 28px;

        border-color: transparent transparent ${theme.colors.yachtSupport.white}
          transparent;

        @media screen and (min-width: ${theme.breakpoints.desktop}px) {
          border-width: 0 40px 40px 40px;
        }
      }
    `,
])
const ContentContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasImage',
})<{
  hasImage?: boolean
  align?: ContentAlignment
  variants?: Variants
}>(({ hasImage, align, variants, theme }) => [
  css`
    position: relative;
    width: 100%;
    padding: ${theme.spacing.x1 * 24}px ${theme.spacing.x5}px
      ${theme.spacing.x7}px;
    background: ${theme.colors.amels.oyster};
    text-align: ${align || 'center'};

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding-top: ${theme.spacing.x1 * 33}px;
      padding-bottom: ${theme.spacing.x10}px;
    }
  `,
  hasImage &&
    css`
      padding: ${theme.spacing.x8}px ${theme.spacing.x4}px;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding: ${theme.spacing.x10}px ${theme.spacing.x5}px;
      }
    `,
  isSiteYachting(theme) &&
    css`
      background: transparent;
      padding: ${theme.spacing.x1 * 21}px ${theme.spacing.x4}px
        ${theme.spacing.x1 * 21}px;
      min-height: 400px;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        text-align: center;
        padding-top: 291px;
        padding-bottom: 291px;
      }
    `,
  isSiteYachting(theme) &&
    hasImage &&
    css`
      min-height: 588px;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 110px;
        background: linear-gradient(
          to bottom,
          rgba(7, 49, 75, 0.6) 0%,
          rgba(7, 49, 75, 0) 100%
        );
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        &::after {
          height: 160px;
        }
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding-top: ${theme.spacing.x1 * 23}px;
        padding-bottom: ${theme.spacing.x1 * 23}px;
        min-height: 845px;
      }
    `,
  isSiteYachting(theme) &&
    hasImage &&
    css`
      padding: ${theme.spacing.x1 * 21}px ${theme.spacing.x4}px
        ${theme.spacing.x1 * 26}px;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding-bottom: ${theme.spacing.x1 * 26}px;
      }
    `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      &::after {
        background: linear-gradient(
          to bottom,
          rgba(215, 151, 120, 0.6) 0%,
          rgba(215, 151, 120, 0) 100%
        );
      }
    `,
  isSiteXplorer(theme) &&
    css`
      background: transparent;
      padding: ${theme.spacing.x5}px ${theme.spacing.x3}px;
      min-height: 400px;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding: ${theme.spacing.x5}px ${theme.spacing.x10}px;
      }
    `,
  isSiteXplorer(theme) &&
    hasImage &&
    css`
      display: flex;
      flex-flow: column;
      flex-grow: 1;
      align-items: center;
      justify-content: ${variants === 'logo' ? 'flex-start' : 'center'};
      padding: ${theme.spacing.x12}px ${theme.spacing.x3}px;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.395;
        background: linear-gradient(270deg, #fff 100%, #000 0.28%);
        mix-blend-mode: multiply;
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding-top: ${theme.spacing.x14}px;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding: ${theme.spacing.x5}px ${theme.spacing.x10}px;
        align-items: ${align === 'left' ? 'flex-start' : 'flex-end'};
        justify-content: center;

        &:before {
          display: none;
        }
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      background: transparent;
      padding: ${theme.spacing.x7}px ${theme.spacing.x3}px;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding: ${theme.spacing.x5}px ${theme.spacing.x10}px;
      }
    `,
  isSiteYachtSupport(theme) &&
    hasImage &&
    css`
      display: flex;
      flex-flow: column;
      flex-grow: 1;
      justify-content: center;
      text-align: center;
      padding: ${theme.spacing.x7}px ${theme.spacing.x3}px;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding: ${theme.spacing.x10}px ${theme.spacing.x10}px;
      }

      ${align === 'left' &&
      css`
        align-items: flex-start;
      `}
      ${align === 'right' &&
      css`
        align-items: flex-end;
      `}
        ${align === 'center' &&
      css`
        align-items: center;
      `}
        ${align === 'bottom' &&
      css`
        align-items: center;
        position: absolute;
        bottom: 0;

        h1 {
          position: absolute;
          bottom: ${theme.spacing.x7}px;

          @media screen and (min-width: ${theme.breakpoints.desktop}px) {
            bottom: ${theme.spacing.x15}px;
          }
        }
      `}
    `,
])

const Logo = styled.div(
  ({ theme }) => css`
    max-width: 288px;

    ${isSiteXplorer(theme) &&
    css`
      max-width: 220px;
    `}

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      max-width: 410px;
    }
  `,
)

export const Container = styled(Section, {
  shouldForwardProp: (prop) => prop !== 'colorOption',
})<{
  colorOption?: ColorOption
  size?: Size
}>(({ theme, colorOption, size }) => [
  isSiteYachting(theme) &&
    css`
      position: relative;
      z-index: 1;
      background: ${theme.colors.yachting.darkBlue};
      color: ${theme.colors.yachting.white};

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: calc(50% - 68px);
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 1) 50%,
          rgba(255, 255, 255, 0) 100%
        );
        clip-path: polygon(297px 0%, 300px 0, 3px 100%, 0% 100%);
        width: 400px;
        height: 780px;
        transform: translateY(-50%);
      }

      + ${IntroductionContainer} {
        z-index: 2;
        margin-top: -180px;
        background: transparent;
        padding-bottom: 0;

        ${IntroductionContent} {
          max-width: 1086px;
          margin: 0 auto;
          background: white;
          padding: ${theme.spacing.x4}px ${theme.spacing.x3}px;

          @media screen and (min-width: ${theme.breakpoints.tablet}px) {
            padding: ${theme.spacing.x10}px;
          }
        }

        ${IntroductionText} {
          color: ${theme.colors.yachting.silver};
        }

        ${IntroductionText},
        ${IntroductionOverlines},
      ${IntroductionTitle},
      ${IntroductionSubTitle} {
          max-width: none;
        }
      }
    `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      background: ${theme.colors.womenInYachtingPage.shell};

      + ${IntroductionContainer} {
        ${IntroductionText} {
          color: ${theme.colors.yachting.darkBlue};
        }
      }

      &::after {
        content: none;
      }
    `,
  isSiteXplorer(theme) &&
    css`
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: ${getXplorerTitleColor(theme, colorOption || '')};
      aspect-ratio: 375/480;

      ${size === 'medium' &&
      css`
        width: 100%;
        // On some zoom levels / display scaling settings there is a 2px gap between the top and navigationbar
        // We counter this by always subtracting 1px from the top value, so here we need to do the same for the height
        // Check the StickyNavigationBar component for the same fix
        height: calc(100vh - (${theme.spacing.x8}px - 2px));
      `}

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        aspect-ratio: ${size === 'small' ? '4/1' : '16/9'};

        & ${Logo} {
          max-width: ${size === 'small' ? '220' : '410'}px;
        }
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      aspect-ratio: ${size === 'small' ? '375/344' : '375/520'};

      ${size === 'medium' &&
      css`
        width: 100%;
        // On some zoom levels / display scaling settings there is a 2px gap between the top and navigationbar
        // We counter this by always subtracting 2px from the top value, so here we need to do the same for the height
        // Check the StickyNavigationBar component for the same fix
        height: calc(100vh - (${theme.spacing.x8}px - 2px));
      `}

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        aspect-ratio: ${size === 'small' ? '4/1' : '16/9'};
      }

      & ${Logo} {
        max-width: 225px;

        @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
          max-width: 420px;
        }
      }

      img {
        padding-bottom: ${theme.spacing.x2}px;
      }
    `,
])

const PageTitle = styled('h1', {
  shouldForwardProp: (prop) => prop !== 'hideTitle',
})<{
  hideTitle?: boolean
}>(({ theme, hideTitle }) => [
  css`
    ${theme.text.heading1(theme)}
    margin: 0 0 ${theme.spacing.x3}px 0;
  `,
  isSiteYachting(theme) &&
    css`
      color: inherit;
    `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      color: ${theme.colors.womenInYachtingPage.darkBlue};
    `,
  hideTitle &&
    css`
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
    `,
  isSiteXplorer(theme) &&
    css`
      color: currentColor;
      margin-bottom: 0;

      svg {
        min-width: 150px;
        width: 50%;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        text-align: start;

        svg {
          width: auto;
        }
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      color: ${theme.colors.yachtSupport.white};
      margin-bottom: 0;
    `,
])
const TagLine = styled.div(({ theme }) => [
  css`
    max-width: 867px;
    margin: 0 auto;
    text-align: left;
    ${theme.text.subHeading(theme)};
    color: ${theme.colors.yachting.white};
    font-size: 36px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 42px;
      text-align: center;
    }
  `,
  isSiteYachting(theme) &&
    css`
      text-align: center;
    `,
])
const Description = styled.div(({ theme }) => [
  css`
    max-width: 867px;
    margin: 0 auto;
    text-align: left;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      text-align: center;
    }
  `,
  isSiteYachting(theme) &&
    css`
      ${theme.text.bodyLargeIntroduction(theme)}
      text-align: center;
    `,
])
const buttonCss = (theme: Theme) => [
  isSiteAmels(theme) && amelsWhiteOutlineButtonCss(theme),
  isSiteYachting(theme) &&
    css`
      ${yachtingWhiteOutlineButtonCss(theme)}
      --hover-color: ${theme.colors.yachting.darkBlue};
      white-space: normal;
      text-align: left;
      padding: ${theme.spacing.x1}px ${theme.spacing.x3}px;
    `,
  css`
    margin-top: ${theme.spacing.x3}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-top: ${theme.spacing.x4}px;
    }
  `,
]
const StyledSidePanelButton = styled(SidePanelButton)(({ theme }) =>
  buttonCss(theme),
)
const StyledLinkButton = styled(LinkButton)(({ theme }) => buttonCss(theme))

type Size = 'medium' | 'small'
type ColorOption = 'white' | 'green' | 'bronze'
type Variants = 'text' | 'logo'
type ContentAlignment = 'left' | 'right' | 'center' | 'bottom'

interface Props
  extends Omit<HTMLAttributes<HTMLDivElement>, 'title' | 'children'> {
  title: ReactNode
  tagLine?: ReactNode
  text?: ReactNode
  image?: {
    src: string
    alt?: string
  }
  overlayImage?: {
    src: string
    alt?: string
  }
  children?: ReactNode
  callToAction?:
    | {
        title: ReactNode
        href?: string
        target?: string
      }
    | {
        label: ReactNode
        form: ReactNode
      }
  scroll?: string
  hideTitle?: boolean
  colorOption?: ColorOption
  variants?: Variants
  contentAlignment?: ContentAlignment
  size?: Size
  logo?: {
    src: string
    alt?: string
  }
}

const DESKTOP_SIZE_MEDIUM_WIDTH = 1292
const DESKTOP_SIZE_MEDIUM_HEIGHT = 845
const DESKTOP_SIZE_SMALL_WIDTH = 1407
const DESKTOP_SIZE_SMALL_HEIGHT = 352
const TABLET_SIZE_MEDIUM_WIDTH = 805
const TABLET_SIZE_MEDIUM_HEIGHT = 637
const TABLET_SIZE_SMALL_WIDTH = 974
const TABLET_SIZE_SMALL_HEIGHT = 244
const MOBILE_SIZE_MEDIUM_WIDTH = 450
const MOBILE_SIZE_MEDIUM_HEIGHT = 637
const MOBILE_SIZE_SMALL_WIDTH = 485
const MOBILE_SIZE_SMALL_HEIGHT = 621

const SimpleHeader = ({
  title,
  tagLine,
  text,
  image,
  overlayImage,
  children,
  callToAction,
  scroll,
  hideTitle,
  colorOption,
  logo,
  size,
  variants,
  contentAlignment,
  ...others
}: Props) => {
  const hasLogo = variants === 'logo' && logo?.src

  const sharedImageProps = {
    sizes: `100vw`,
  }

  const { srcSet: desktopSizeMedium } = getNextImageProps({
    image,
    aspectRatio: '1292:845',
    imageProps: {
      ...sharedImageProps,
      width: DESKTOP_SIZE_MEDIUM_WIDTH,
      height: DESKTOP_SIZE_MEDIUM_HEIGHT,
    },
  })

  const { srcSet: desktopSizeSmall } = getNextImageProps({
    image,
    aspectRatio: '4:1',
    imageProps: {
      ...sharedImageProps,
      width: DESKTOP_SIZE_SMALL_WIDTH,
      height: DESKTOP_SIZE_SMALL_HEIGHT,
    },
  })

  const { srcSet: tabletSizeMedium } = getNextImageProps({
    image,
    aspectRatio: '805:637',
    imageProps: {
      ...sharedImageProps,
      width: TABLET_SIZE_MEDIUM_WIDTH,
      height: TABLET_SIZE_MEDIUM_HEIGHT,
    },
  })

  const { srcSet: tabletSizeSmall } = getNextImageProps({
    image,
    aspectRatio: '4:1',
    imageProps: {
      ...sharedImageProps,
      width: TABLET_SIZE_SMALL_WIDTH,
      height: TABLET_SIZE_SMALL_HEIGHT,
    },
  })

  const { srcSet: mobileSizeMedium, ...mobileMediumRest } = getNextImageProps({
    image,
    aspectRatio: '450:637',
    imageProps: {
      ...sharedImageProps,
      width: MOBILE_SIZE_MEDIUM_WIDTH,
      height: MOBILE_SIZE_MEDIUM_HEIGHT,
    },
  })

  const { srcSet: mobileSizeSmall, ...mobileSmallRest } = getNextImageProps({
    image,
    aspectRatio: '375:480',
    imageProps: {
      ...sharedImageProps,
      width: MOBILE_SIZE_SMALL_WIDTH,
      height: MOBILE_SIZE_SMALL_HEIGHT,
    },
  })

  const srcSetMobile = size === 'small' ? mobileSizeSmall : mobileSizeMedium
  const srcSetTablet = size === 'small' ? tabletSizeSmall : tabletSizeMedium
  const srcSetDesktop = size === 'small' ? desktopSizeSmall : desktopSizeMedium

  const desktopWidth =
    size === 'small' ? DESKTOP_SIZE_SMALL_WIDTH : DESKTOP_SIZE_MEDIUM_WIDTH
  const desktopHeight =
    size === 'small' ? DESKTOP_SIZE_SMALL_HEIGHT : DESKTOP_SIZE_MEDIUM_HEIGHT
  const tabletWidth =
    size === 'small' ? TABLET_SIZE_SMALL_WIDTH : TABLET_SIZE_MEDIUM_WIDTH
  const tabletHeight =
    size === 'small' ? TABLET_SIZE_SMALL_HEIGHT : TABLET_SIZE_MEDIUM_HEIGHT
  const mobileWidth =
    size === 'small' ? MOBILE_SIZE_SMALL_WIDTH : MOBILE_SIZE_MEDIUM_WIDTH
  const mobileHeight =
    size === 'small' ? MOBILE_SIZE_SMALL_HEIGHT : MOBILE_SIZE_MEDIUM_HEIGHT

  return (
    <Container
      sectionIndicatorVariant="light"
      as="header"
      data-testid="simpleHeader"
      colorOption={colorOption}
      size={size}
      {...others}
    >
      {image && (
        <ImageContainer>
          <picture>
            <source
              width={desktopWidth}
              height={desktopHeight}
              media="(min-width: 1080px)"
              srcSet={srcSetDesktop}
            />
            <source
              width={tabletWidth}
              height={tabletHeight}
              // The small variant has a different breakpoint for tablet
              media={
                size === 'small' ? '(min-width: 768px)' : '(min-width: 540px)'
              }
              srcSet={srcSetTablet}
            />
            <source
              width={mobileWidth}
              height={mobileHeight}
              srcSet={srcSetMobile}
            />
            {
              // Alt prop is passed down via the rest props
              /* eslint-disable jsx-a11y/alt-text */
            }
            <img
              {...(size === 'small' ? mobileSmallRest : mobileMediumRest)}
              width={mobileWidth}
              height={mobileHeight}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                aspectRatio: size === 'small' ? '375/480' : '450/637',
              }}
              fetchPriority="high"
            />
          </picture>
        </ImageContainer>
      )}
      {overlayImage && (
        <ImageOverlay>
          <Image
            src={overlayImage.src}
            alt={overlayImage.alt ?? ''}
            fill
            style={{ objectFit: 'cover' }}
          />
        </ImageOverlay>
      )}
      <ContentContainer
        hasImage={Boolean(image?.src)}
        variants={variants}
        align={contentAlignment}
      >
        {hasLogo && (
          <Logo>
            <Image
              src={logo.src}
              alt={logo.alt ?? ''}
              height={320}
              width={410}
              style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
          </Logo>
        )}
        <>
          <PageTitle hideTitle={hideTitle}>{title}</PageTitle>
          {!hasLogo && (
            <>
              {tagLine && <TagLine>{tagLine}</TagLine>}
              {text && <Description>{text}</Description>}
            </>
          )}
        </>

        {callToAction && 'href' in callToAction && (
          <StyledLinkButton
            variant="secondary"
            href={callToAction.href ?? ''}
            target={callToAction.target}
          >
            {callToAction.title}
          </StyledLinkButton>
        )}
        {callToAction && 'form' in callToAction && (
          <StyledSidePanelButton
            sidePanel={callToAction.form}
            variant="secondary"
          >
            {callToAction.label}
          </StyledSidePanelButton>
        )}
        {
          // && image?.src
          scroll && (
            <ScrollIndicator>
              {scroll}
              {currentSite === Site.Yachting && <ScrollYachting />}
              {currentSite === Site.Xplorer && <ScrollXplorer />}
              {currentSite === Site.YachtSupport && <ScrollYachtSupport />}
              <div className="triangle" />
            </ScrollIndicator>
          )
        }

        {children}
      </ContentContainer>
    </Container>
  )
}

export default SimpleHeader
