import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, useEffect } from 'react'

import {
  isSiteYachting,
  isSiteAmels,
  isSiteXplorer,
  isSiteYachtSupport,
} from '../themes'
import useDisableBodyScroll from '../utils/useDisableBodyScroll'
import AnimateText from './AnimateText'
import CloseButton from './CloseButton'
import Link from './Link'
import Logo from './Logo'
import Slant from '../icons/yachting/Slant.svg'
import Cross from '../icons/yachting/Cross.svg'
import CloseLightXplorer from '../icons/xplorer/CloseLight.svg'
import CloseYachtSupport from '../icons/yachtsupport/Close.svg'
import SiteNavigationMainMenuItem, {
  MainMenuItemLinkWrapper,
} from './SiteNavigationMainMenuItem'
import SocialIcon from './SocialIcon'
import { showNavigationBarVar } from './StickyNavigationBar'
import { currentSite, Site } from '../sites'
import Button, { invisibleButtonCss, invisibleButtonXplorerCss } from './Button'

// TODO fix Tablet styling

const Container = styled.div(({ theme }) => [
  css`
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
  `,
  isSiteXplorer(theme)
    ? css`
        flex-direction: column;
      `
    : css`
        flex-direction: row;
      `,
  isSiteYachtSupport(theme)
    ? css`
        flex-direction: column;
      `
    : css`
        flex-direction: row;
      `,
])
const SlantedYachtingGradient = styled.div(
  () => css`
    height: 100%;
    z-index: 2;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to bottom,
        rgba(7, 49, 75, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      clip-path: polygon(0 0, 0% 100%, 450px 0);
    }
  `,
)
export const StyledAnimateText = styled(AnimateText)(
  () => css`
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
  `,
)

export const StyledSubMenuItemAnimateText = styled(AnimateText)(
  () => css`
    line-height: 1;
  `,
)
export const LeftPane = styled.div(({ theme }) => [
  !isSiteXplorer(theme) &&
    !isSiteYachtSupport(theme) &&
    css`
      display: none;
      color: ${theme.colors.amels.deepBayAqua};

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex: 0 0 278px;
        background: ${theme.colors.amels.silk};
        padding: ${theme.spacing.x9}px ${theme.spacing.x5}px 0
          ${theme.spacing.x5}px;
      }
      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        flex: 0 0 360px;
        padding: ${theme.spacing.x9}px ${theme.spacing.x10}px 0
          ${theme.spacing.x10}px;
      }
    `,
  isSiteYachting(theme) &&
    css`
      background: ${theme.colors.yachting.darkBlue};
      overflow: visible;
      z-index: 1;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x3}px ${theme.spacing.x5}px 0
          ${theme.spacing.x5}px;
        background: ${theme.colors.yachting.darkBlue};
      }
    `,
  isSiteXplorer(theme) &&
    css`
      display: none;
    `,
  isSiteYachtSupport(theme) &&
    css`
      display: none;
    `,
])
export const RightPane = styled.div(({ theme }) => [
  css`
    flex: 1 1 auto;
    overflow-y: auto;
    padding: ${theme.spacing.x6}px ${theme.spacing.x4}px ${theme.spacing.x4}px;
    background: ${theme.colors.amels.body};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x8}px ${theme.spacing.x10}px;
    }
  `,
  isSiteYachting(theme) &&
    css`
      background: ${theme.colors.yachting.darkBlue};
      padding: ${theme.spacing.x3}px ${theme.spacing.x4}px ${theme.spacing.x4}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x20}px ${theme.spacing.x8}px
          ${theme.spacing.x10}px;
      }
    `,
  isSiteXplorer(theme) &&
    css`
      background: ${theme.colors.xplorer.secondaryXPBronze};
      padding: ${theme.spacing.x3}px;

      :after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 40%;
        height: calc(
          100% - 150px
        ); // 146px is height of the topbar + small margin from bottom
        background-image: radial-gradient(
          ${theme.colors.xplorer.primaryXPLavaBlack} 1.5px,
          transparent 0
        );
        background-size: 40px 40px;
        background-repeat: repeat;
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x4}px;

        :after {
          width: 50%;
        }
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      background: var(
        --Lineair-Green,
        linear-gradient(
          45deg,
          ${theme.colors.yachtSupport.secondaryYSCoralGreen} 0%,
          ${theme.colors.yachtSupport.primaryYSOceanBlue} 100%
        )
      );
      padding: ${theme.spacing.x2}px ${theme.spacing.x3}px;

      :after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 40%;
        height: calc(
          100% - 150px
        ); // 146px is height of the topbar + small margin from bottom
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x4}px;

        :after {
          width: 50%;
        }
      }

      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        padding: ${theme.spacing.x5}px ${theme.spacing.x15}px;

        :after {
          width: 50%;
        }
      }
    `,
])
const Menu = styled.div(
  ({ theme }) => css`
    position: relative;
    display: flex;
    z-index: 2;
    flex-flow: column;
    justify-content: center;
    width: 100%;
    min-height: 100%;

    ${isSiteYachting(theme) &&
    css`
      justify-content: flex-start;
    `};
    ${isSiteXplorer(theme) &&
    css`
      align-items: flex-start;
      justify-content: flex-start;
      gap: ${theme.spacing.x7}px;
      padding-left: ${theme.spacing.x3}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding-left: ${theme.spacing.x11}px;
      }
    `};
    ${isSiteYachtSupport(theme) &&
    css`
      align-items: flex-start;
      justify-content: flex-start;
      gap: ${theme.spacing.x7}px;
    `};
  `,
)
const DesktopLogo = styled(Logo.Full)(
  ({ theme }) => css`
    width: 100%;

    ${isSiteYachting(theme) &&
    css`
      color: ${theme.colors.yachting.white};
      max-width: 135px;
      z-index: 1;
    `};

    ${isSiteXplorer(theme) &&
    css`
      color: ${theme.colors.xplorer.white};
    `};

    ${isSiteYachtSupport(theme) &&
    css`
      color: ${theme.colors.yachtSupport.white};
      width: 458px;
    `};
  `,
)
const Footer = styled.div``

const Socials = styled.ul(({ theme }) => [
  css`
    display: flex;
    list-style: none;
  `,
  isSiteYachting(theme) &&
    css`
      display: none;
    `,
])
const Social = styled.li(
  ({ theme }) => css`
    &:not(:last-child) {
      margin-right: ${theme.spacing.x1}px;
    }
  `,
)
const SubMenu = styled.ul(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.x10}px;
    list-style: none;

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      display: grid;
      grid-auto-columns: minmax(0, 1fr);

      & > li:nth-of-type(3):nth-last-of-type(odd) {
        grid-row: 3;
      }

      /* all odd items counting from the end at the second row*/
      & > li:nth-last-of-type(odd) {
        grid-row: 2;
      }

      li:first-of-type {
        grid-row: 1;
      }
      li:nth-of-type(2) {
        grid-row: 2;
      }
    }

    ${isSiteXplorer(theme) &&
    css`
      margin-bottom: ${theme.spacing.x7}px;

      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        display: flex;
        flex-direction: column;
      }
    `};

    ${isSiteYachtSupport(theme) &&
    css`
      margin-bottom: ${theme.spacing.x7}px;

      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        display: flex;
        flex-direction: column;
      }
    `};
  `,
)
const LegalMenu = styled.ul(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing.x7}px;
    list-style: none;
  `,
)

const StyledLegalMenuLink = styled(Link)(({ theme }) => [
  isSiteXplorer(theme) &&
    css`
      color: ${theme.colors.xplorer.primaryXPLavaBlack};
      font-size: 14px;
      font-weight: 300;
      line-height: 1.2;
      text-transform: uppercase;
      white-space: nowrap;
    `,
  isSiteYachtSupport(theme) &&
    css`
      color: ${theme.colors.yachtSupport.white};
      font-size: 14px;
      font-weight: 300;
      line-height: 1.2;
      text-transform: uppercase;
      white-space: nowrap;
    `,
])

const SubMenuItem = styled.li(({ theme }) => [
  css`
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      display: inline-block;
      width: 50%;
    }
  `,
  isSiteYachting(theme) &&
    css`
      margin-bottom: ${theme.spacing.x1}px;
    `,
  isSiteXplorer(theme) &&
    css`
      & + & {
        margin-top: ${theme.spacing.x3}px;
      }

      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        width: auto;
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      & + & {
        margin-top: ${theme.spacing.x3}px;
      }

      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        width: auto;
      }
    `,
])

const StyledSubMenuLink = styled(Link)(({ theme }) => [
  isSiteAmels(theme) &&
    css`
      font-family: ${theme.fonts.Zermatt.style.fontFamily};
      font-size: 24px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: normal;
      color: ${theme.colors.amels.beach};
      text-decoration: none;
    `,
  isSiteYachting(theme) &&
    css`
      ${theme.text.overlineMedium(theme)}
      color: ${theme.colors.yachting.white};
      letter-spacing: 5px;
      font-size: 18px;
      transition: color ease-in-out 0.2s;

      &:hover {
        color: ${theme.colors.yachting.orange};
      }
    `,
  isSiteXplorer(theme) &&
    css`
      color: ${theme.colors.xplorer.primaryXPLavaBlack};
      font-size: 20px;
      font-weight: 300;
      line-height: 1.2;
      white-space: nowrap;
      width: auto;
    `,
  isSiteYachtSupport(theme) &&
    css`
      color: ${theme.colors.yachtSupport.white};
      font-size: 20px;
      font-weight: 300;
      line-height: 1.2;
      white-space: nowrap;
      width: auto;
    `,
])
const SubMenuLinkWrapper = styled(AnimateText)(() => [
  css`
    line-height: 1;
    font-size: initial;
  `,
])
const MainNavigationWrapper = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.x7}px;
    margin-top: ${theme.spacing.x5}px;

    ${isSiteYachting(theme) &&
    css`
      margin-top: ${theme.spacing.x9}px;
    `};

    ${isSiteXplorer(theme) &&
    css`
      margin-top: ${theme.spacing.x2}px;
      margin-bottom: 0;
    `};

    ${isSiteYachtSupport(theme) &&
    css`
      margin-top: ${theme.spacing.x2}px;
      margin-bottom: 0;
      width: 100%;
    `};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-top: 0;
      margin-bottom: ${theme.spacing.x10}px;

      ${isSiteXplorer(theme) &&
      css`
        margin-top: ${theme.spacing.x5}px;
        margin-bottom: 0;
      `};

      ${isSiteYachtSupport(theme) &&
      css`
        margin-top: ${theme.spacing.x5}px;
        margin-bottom: 0;
        width: auto;
        min-width: 286px;
      `};
    }
  `,
)
const StyledSiteNavigationMainMenuItem = styled(SiteNavigationMainMenuItem)``
const MainNavigation = styled.ul(({ theme }) => [
  css`
    list-style: none;
  `,
  isSiteAmels(theme) &&
    css`
      ${StyledSiteNavigationMainMenuItem} {
        transition: opacity 300ms;
      }

      &:hover {
        ${StyledSiteNavigationMainMenuItem} {
          &:not(:hover) {
            opacity: 0.5;
          }
        }
      }
    `,
])
const MainNavigationItem = styled.li(({ theme }) => [
  isSiteYachting(theme) &&
    css`
      :not(:last-of-type) {
        margin-bottom: ${theme.spacing.x3}px;
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        :not(:last-of-type) {
          margin-bottom: ${theme.spacing.x4}px;
        }
      }
    `,
  isSiteXplorer(theme) &&
    css`
      margin-bottom: ${theme.spacing.x3}px;

      &:hover {
        ${MainMenuItemLinkWrapper} {
          color: ${theme.colors.xplorer.white};

          * {
            color: ${theme.colors.xplorer.white};
          }
        }
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      border-top: 1px solid ${theme.colors.yachtSupport.white};
      &:last-of-type {
        border-bottom: 1px solid ${theme.colors.yachtSupport.white};
      }
      padding-top: ${theme.spacing.x2}px;
      padding-bottom: ${theme.spacing.x2}px;
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        ${StyledSiteNavigationMainMenuItem} {
          > div {
            max-height: 48px;
          }
        }
      }

      &:hover {
        ${MainMenuItemLinkWrapper} {
          color: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};

          * {
            color: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
          }
        }
      }
    `,
])
const DesktopLogoWrapper = styled.div(
  ({ theme }) => css`
    max-width: 215px;
    color: ${theme.colors.xplorer.white};
    display: none;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: block;
    }

    ${isSiteYachtSupport(theme) &&
    css`
      margin: ${theme.spacing.x2}px auto ${theme.spacing.x2}px 0;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        margin: ${theme.spacing.x2}px auto ${theme.spacing.x2}px auto;
        display: flex;
        justify-content: center;
      }
    `};
  `,
)
const MobileLogoWrapper = styled.div(
  ({ theme }) => css`
    flex: 1 1 auto;
    display: block;
    max-width: 43px;
    color: ${theme.colors.amels.deepBayAqua};

    ${isSiteYachting(theme) &&
    css`
      flex: 0 1 auto;
      max-width: 120px;
      color: ${theme.colors.yachting.white};
    `};

    ${isSiteXplorer(theme) &&
    css`
      flex: 0 1 auto;
      color: ${theme.colors.xplorer.white};

      > svg {
        width: 161px;
      }
    `};

    ${isSiteYachtSupport(theme) &&
    css`
      flex: 0 1 auto;
      color: ${theme.colors.yachtSupport.white};
      margin: ${theme.spacing.x1}px 0;

      > svg {
        width: 243px;
      }
    `};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: none;
    }
  `,
)
const SocialLink = styled(Link)(
  ({ theme }) => css`
    transition: color 300ms;
    color: ${theme.colors.amels.shell};

    &:hover {
      color: ${theme.colors.amels.beach};
    }

    ${isSiteYachting(theme) &&
    css`
      color: ${theme.colors.yachting.silver};

      &:hover {
        color: ${theme.colors.yachting.black};
      }
    `};
  `,
)
const StyledSocialIcon = styled(SocialIcon)(
  ({ theme }) => css`
    width: ${theme.spacing.x4}px;
  `,
)
const StyledCloseButton = styled(CloseButton)(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.spacing.x5}px;
    right: ${theme.spacing.x3}px;
    color: ${theme.colors.amels.hazySunsetOrange};
    width: ${theme.spacing.x6}px;
    height: ${theme.spacing.x6}px;
    cursor: pointer;
    z-index: 3;

    ${isSiteYachting(theme) &&
    css`
      color: ${theme.colors.yachting.darkBlue};
    `};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      top: ${theme.spacing.x8}px;
      right: ${theme.spacing.x10}px;
    }
  `,
)
const HamburgerTitle = styled.div(
  ({ theme }) => css`
    ${isSiteAmels(theme) &&
    css`
      display: none;
    `}

    ${isSiteYachting(theme) &&
    css`
      display: inline-flex;
      align-items: center;
      gap: ${theme.spacing.x1}px;
      text-transform: uppercase;
    `}
  `,
)
const StyledSlantIcon = styled(Slant)(
  ({ theme }) => css`
    ${isSiteYachting(theme) &&
    css`
      height: 35px;
    `}
  `,
)
const StyledCrossIcon = styled(Cross)(
  ({ theme }) => css`
    ${isSiteYachting(theme) &&
    css`
      height: 16px;
    `}
  `,
)
const StyledCloseLightXplorerIcon = styled(CloseLightXplorer)()
const StyledCloseYachtSupportIcon = styled(CloseYachtSupport)()
const HamburgerTitleContent = styled.span(
  ({ theme }) => css`
    ${isSiteYachting(theme) &&
    css`
      display: none;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        display: block;
      }
    `}
  `,
)
const YachtingCloseButton = styled(Button)(({ theme }) => [
  invisibleButtonCss,
  css`
    position: absolute;
    top: ${theme.spacing.x5}px;
    right: ${theme.spacing.x4}px;
    color: ${theme.colors.yachting.white};
    cursor: pointer;
    z-index: 3;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      top: ${theme.spacing.x7}px;
      right: ${theme.spacing.x10}px;
    }
  `,
])
const XplorerCloseButton = styled(Button)(({ theme }) => [
  invisibleButtonXplorerCss,
  css`
    position: absolute;
    top: ${theme.spacing.x2}px;
    right: ${theme.spacing.x3}px;
    color: ${theme.colors.xplorer.white};
    cursor: pointer;
    z-index: 3;
    width: 40px;
    height: 40px;

    > svg {
      width: 40px;
      height: 40px;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      top: ${theme.spacing.x4}px;
      right: ${theme.spacing.x4}px;
    }
  `,
])

const YachtSupportCloseButton = styled(Button)(({ theme }) => [
  invisibleButtonCss,
  css`
    position: absolute;
    top: ${theme.spacing.x2}px;
    right: ${theme.spacing.x3}px;
    cursor: pointer;
    z-index: 3;
    width: 40px;
    height: 40px;
    border: 1px solid ${theme.colors.yachtSupport.grey03};
    background-color: transparent;

    :hover {
      color: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
    }

    > svg {
      width: 24px;
      height: 24px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        width: 24px;
        height: 24px;
      }
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      width: 56px;
      height: 56px;
      top: ${theme.spacing.x4}px;
      right: ${theme.spacing.x4}px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      top: ${theme.spacing.x5}px;
      right: ${theme.spacing.x4}px;
    }
  `,
])

interface SocialLink {
  key: string
  title?: string
  href: string
  icon:
    | ''
    | 'vimeo'
    | 'linkedin'
    | 'instagram'
    | 'facebook'
    | 'twitter'
    | 'youtube'
}

interface Link {
  key: string
  title: string
  href: string
}

interface MainMenuLink extends Link {
  subItems?: Link[]
}

interface Props extends Omit<ComponentProps<typeof Container>, 'children'> {
  mainNavigation: MainMenuLink[]
  socials?: SocialLink[]
  subNavigation?: Link[]
  legalNavigation?: Link[]
  close: () => void
  menuCloseTitle?: string
}

const SiteNavigation = ({
  mainNavigation,
  subNavigation,
  legalNavigation,
  socials,
  close,
  menuCloseTitle,
  ...others
}: Props) => {
  const ref = useDisableBodyScroll()

  useEffect(() => {
    showNavigationBarVar(false)
  }, [])

  return (
    <Container ref={ref} {...others}>
      {currentSite === Site.Yachting && (
        <StyledAnimateText delay={600} direction="down">
          <SlantedYachtingGradient />
        </StyledAnimateText>
      )}
      <LeftPane>
        <AnimateText delay={600} direction="down">
          <Link href="/">
            <DesktopLogo />
          </Link>
        </AnimateText>
      </LeftPane>

      <RightPane>
        {currentSite === Site.Yachting && (
          <YachtingCloseButton onClick={close}>
            {menuCloseTitle && (
              <HamburgerTitle>
                <HamburgerTitleContent>{menuCloseTitle}</HamburgerTitleContent>
                <StyledSlantIcon />
                <StyledCrossIcon />
              </HamburgerTitle>
            )}
          </YachtingCloseButton>
        )}
        {currentSite === Site.Xplorer && (
          <XplorerCloseButton onClick={close}>
            <StyledCloseLightXplorerIcon />
          </XplorerCloseButton>
        )}
        {currentSite === Site.YachtSupport && (
          <YachtSupportCloseButton onClick={close}>
            <StyledCloseYachtSupportIcon />
          </YachtSupportCloseButton>
        )}
        {currentSite !== Site.Xplorer &&
          currentSite !== Site.Yachting &&
          currentSite !== Site.YachtSupport && (
            <StyledCloseButton onClick={close} />
          )}
        <Menu>
          <MobileLogoWrapper>
            <Logo.Simple />
          </MobileLogoWrapper>
          {currentSite === Site.Xplorer && (
            <DesktopLogoWrapper>
              <AnimateText delay={600} direction="down">
                <Link href="/">
                  <DesktopLogo />
                </Link>
              </AnimateText>
            </DesktopLogoWrapper>
          )}
          {currentSite === Site.YachtSupport && (
            <DesktopLogoWrapper>
              <AnimateText delay={600} direction="down">
                <Link href="/">
                  <DesktopLogo />
                </Link>
              </AnimateText>
            </DesktopLogoWrapper>
          )}
          <MainNavigationWrapper>
            <MainNavigation>
              {mainNavigation.map(({ key, title, ...others }, index) => (
                <MainNavigationItem key={key}>
                  <StyledSiteNavigationMainMenuItem {...others}>
                    <AnimateText delay={400 + index * 120}>{title}</AnimateText>
                  </StyledSiteNavigationMainMenuItem>
                </MainNavigationItem>
              ))}
            </MainNavigation>
          </MainNavigationWrapper>

          <Footer>
            {subNavigation && subNavigation.length > 0 && (
              <SubMenu>
                {subNavigation.map(({ key, title, href }, index) => (
                  <SubMenuItem key={key}>
                    <StyledSubMenuItemAnimateText
                      delay={600 + mainNavigation.length * 50 + index * 50}
                    >
                      <StyledSubMenuLink href={href}>{title}</StyledSubMenuLink>
                    </StyledSubMenuItemAnimateText>
                  </SubMenuItem>
                ))}
              </SubMenu>
            )}
            {legalNavigation && legalNavigation.length > 0 && (
              <LegalMenu>
                {legalNavigation.map(({ key, title, href }, index) => (
                  <SubMenuItem key={key}>
                    <SubMenuLinkWrapper
                      delay={600 + mainNavigation.length * 50 + index * 50}
                    >
                      <StyledLegalMenuLink href={href}>
                        {title}
                      </StyledLegalMenuLink>
                    </SubMenuLinkWrapper>
                  </SubMenuItem>
                ))}
              </LegalMenu>
            )}
            {socials && socials.length > 0 && (
              <AnimateText
                delay={600 + mainNavigation.length * 50}
                direction="static"
              >
                <Socials>
                  {socials.map(
                    ({ key, icon, title, href }) =>
                      icon && (
                        <Social key={key}>
                          <SocialLink href={href} title={title}>
                            <StyledSocialIcon icon={icon} />
                          </SocialLink>
                        </Social>
                      ),
                  )}
                </Socials>
              </AnimateText>
            )}
          </Footer>
        </Menu>
      </RightPane>
    </Container>
  )
}

export default SiteNavigation
