import { css, keyframes, Theme } from '@emotion/react'
import styled from '@emotion/styled'
import {
  ComponentProps,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'

import AnimateText, { hiddenClassName } from './AnimateText'
import BackgroundSvg from '../icons/IconButton.svg'

const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'weight',
})<{ weight?: 'bold' | 'default' }>(({ weight }) => [
  css`
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 3.5px;
    text-align: left;
    font-weight: ${weight === 'bold' ? 600 : 500};
  `,
])
const CircledIconContainer = styled.div(
  ({ theme }) =>
    css`
      position: relative;
      color: ${theme.colors.amels.hazySunsetOrange};
      transition: color 400ms ease-in-out;
      width: 32px;
      height: 32px;
    `,
)
const IconContainer = styled.div(
  () =>
    css`
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    `,
)
const ringLength = 522
const Background = styled(BackgroundSvg)(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: visible;
    .circle {
      opacity: 0;
      will-change: opacity;
      transition: opacity 400ms ease-in-out;
      fill: var(--accent-color, ${theme.colors.amels.hazySunsetOrange});
    }
    .ring {
      stroke: var(--accent-color, ${theme.colors.amels.hazySunsetOrange});
    }

    @media (prefers-reduced-motion: no-preference) {
      .ring {
        stroke-dasharray: ${ringLength};
        stroke-dashoffset: -${ringLength};
        transform: rotate(-90deg);
        transform-origin: center;
        :not(.${hiddenClassName} &) {
          animation: ${keyframes`
            0% {
              stroke-dashoffset: ${ringLength};
            }
            30% {
              transform: rotate(-90deg);
            }
            to {
              stroke-dashoffset: 0;
              transform: rotate(90deg);
            }
          `} 1000ms ease-in-out forwards;
          animation-delay: var(--animation-delay, 0ms);
        }
      }
    }
  `,
)
const Icon = styled.div(
  () => css`
    z-index: 1;
    @media (prefers-reduced-motion: no-preference) {
      opacity: 0;
      will-change: opacity;
      transition: opacity 400ms ease-in-out;
      :not(.${hiddenClassName} &) {
        opacity: 1;
        transition-delay: calc(var(--animation-delay, 0ms) + 600ms);
      }
    }
  `,
)
// This div was created for the Y-translation on hover. If we would translate
// the entire button, the clickable area would move which could be awkward if
// the user's cursor was at the bottom as it would move away from the cursor.
const Content = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`
export const hoverCss = (theme: Theme) => css`
  :hover {
    ${CircledIconContainer} {
      color: ${theme.colors.amels.silk};
      ${Background} {
        .circle {
          opacity: 1;
        }
      }
    }

    // Grow the ring
    ${Background} {
      .ring {
        stroke-dasharray: 0;
        stroke-dashoffset: 0;
        will-change: opacity;
        animation: ${keyframes`
          from {
            r: 83;
            opacity: 1;
          }
          to {
            r: 120;
            opacity: 0;
          }
        `} 400ms linear forwards !important;
      }
    }
  }
`
export const activeCss = (theme: Theme) => css`
  // Background/foreground color
  :not(:hover) {
    // Only change the background/foreground colors when not hovering as it
    // would otherwise cause a weird color jump when the animation is
    // complete and the hover state is applied
    ${CircledIconContainer} {
      color: ${theme.colors.amels.silk};
      animation: ${keyframes`
        to {
          color: ${theme.colors.amels.hazySunsetOrange};
        }
      `} 600ms ease-in-out forwards;
      animation-delay: 300ms;
      ${Background} {
        .circle {
          opacity: 1;
          will-change: opacity;
          animation: ${keyframes`
            to {
              opacity: 0;
            }
          `} 600ms ease-in-out forwards;
          animation-delay: 300ms;
        }
      }
    }
  }

  // Move the icon
  ${Icon} {
    position: relative;
    animation: ${keyframes`
      0% {
        left: 0%;
      }
      50% {
        left: 100%;
      }
      50.1% {
        left: 100%;
        display: none;
      }
      50.2% {
        left: -100%;
        display: block;
      }
      100% {
        left: 0%;
      }
    `} 600ms ease-in-out forwards;
    animation-delay: 200ms;
  }
`
export const useInteractWithIconInteractable = () => {
  const [active, setActive] = useState(false)
  useEffect(() => {
    if (!active) {
      return undefined
    }

    const timer = setTimeout(() => {
      setActive(false)
    }, 800)
    return () => clearTimeout(timer)
  }, [active])

  const handleClick = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (onClick?: (e: any) => void) => (e: any) => {
      setActive(true)
      setTimeout(() => {
        onClick?.(e)
      }, 500)
    },
    [],
  )

  return [active, handleClick] as const
}

interface Props extends ComponentProps<typeof Container> {
  icon: ReactNode
  weight?: 'bold' | 'default'
  children: ReactNode
}

const IconInteractable = ({ icon, weight, children, ...others }: Props) => (
  <Container weight={weight} {...others}>
    <Content>
      <CircledIconContainer>
        <Background />

        <IconContainer>
          <Icon>{icon}</Icon>
        </IconContainer>
      </CircledIconContainer>

      <AnimateText direction="static" delay={400}>
        {children}
      </AnimateText>
    </Content>
  </Container>
)

export default IconInteractable
