import { useEffect, useState } from 'react'

/**
 * Some components should only be rendered on the client-side, such as those
 * that rely on useLayoutEffect. See https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
 * for more info.
 */
const useIsClientSideRender = () => {
  const [isClientSideRendering, setIsClientSideRendering] = useState(false)
  useEffect(() => {
    setIsClientSideRendering(true)
  }, [])
  return isClientSideRendering
}

export default useIsClientSideRender
