import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps } from 'react'

import SidePanel from './SidePanel'

const Container = styled.div(
  () => css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 100;
  `,
)

const SidePanelFixed = (props: ComponentProps<typeof SidePanel>) => (
  <Container>
    <SidePanel {...props} />
  </Container>
)

export default SidePanelFixed
