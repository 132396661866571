import { ComponentProps, ReactNode, useState } from 'react'
import Button from './Button'
import SidePanelFixed from './SidePanelFixed'
import SidePanelTransition from './SidePanelTransition'
import Portal from './Portal'
import useIsClientSideRender from '../utils/useIsClientSideRender'

interface Props extends ComponentProps<typeof Button> {
  children: ReactNode
  sidePanel: ReactNode
  'data-testid'?: string
}

const SidePanelButton = ({
  children,
  sidePanel,
  'data-testid': testid,
  ...others
}: Props) => {
  const [isOpen, setOpen] = useState(false)
  const isClientSideRender = useIsClientSideRender()

  return (
    <>
      <Button {...others} onClick={() => setOpen(true)} data-testid={testid}>
        {children}
      </Button>

      {isClientSideRender && (
        <Portal>
          <SidePanelTransition isOpen={isOpen}>
            {isOpen && (
              <SidePanelFixed
                close={() => setOpen(false)}
                data-testid={`${testid}.sidePanel`}
              >
                {sidePanel}
              </SidePanelFixed>
            )}
          </SidePanelTransition>
        </Portal>
      )}
    </>
  )
}

export default SidePanelButton
