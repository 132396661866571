import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'

import ChevronRight from '../icons/ChevronRight.svg'
import IconLink from './IconLink'
import Link from './Link'
import { currentSite } from '../sites'
import LinkButton from './LinkButton'
import { isSiteXplorer, isSiteYachting, isSiteYachtSupport } from '../themes'

const StyledLinkContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'alignment',
})<{
  alignment: 'left' | 'center' | 'right'
}>(({ theme, alignment }) => [
  alignment === 'left' &&
    css`
      display: flex;
      justify-content: start;
    `,
  alignment === 'center' &&
    css`
      display: flex;
      justify-content: center;
    `,
  alignment === 'right' &&
    css`
      display: flex;
      justify-content: flex-end;
    `,
  isSiteYachting(theme) &&
    css`
      margin-top: ${theme.spacing.x6}px;
    `,
  isSiteXplorer(theme) &&
    css`
      width: fit-content;
    `,
  isSiteYachtSupport(theme) &&
    css`
      width: fit-content;
      margin: auto;
    `,
])
const StyledChevronRight = styled(ChevronRight)`
  height: 16px;
`

interface Props extends Omit<ComponentProps<typeof Link>, 'href' | 'children'> {
  href: string
  children: ReactNode
  alignment?: 'left' | 'center' | 'right'
}

const RichTextLink = ({
  href,
  children,
  alignment = 'left',
  ...others
}: Props) => {
  if (currentSite === 'yachting') {
    return (
      <StyledLinkContainer alignment={alignment}>
        <LinkButton href={href} variant="primary" {...others}>
          {children}
        </LinkButton>
      </StyledLinkContainer>
    )
  }
  if (currentSite === 'xplorer') {
    return (
      <StyledLinkContainer alignment={alignment}>
        <LinkButton href={href} variant="primary" {...others}>
          {children}
        </LinkButton>
      </StyledLinkContainer>
    )
  }
  if (currentSite === 'yachtsupport') {
    return (
      <StyledLinkContainer alignment={alignment}>
        <LinkButton href={href} variant="primary" {...others}>
          {children}
        </LinkButton>
      </StyledLinkContainer>
    )
  }
  return (
    <StyledLinkContainer alignment={alignment}>
      <IconLink href={href} icon={<StyledChevronRight />} {...others}>
        {children}
      </IconLink>
    </StyledLinkContainer>
  )
}

export default RichTextLink
