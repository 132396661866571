import RichTextVideo from '../../components/RichTextVideo'
import { RichTextVideoStoryblok } from '../storyblok.generated'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'
import { mapVideo } from '../../utils/mapVideo'

interface Props {
  content: RichTextVideoStoryblok
}

const RichTextVideoBlok = ({ content: { video, image }, ...others }: Props) => {
  return (
    <RichTextVideo
      video={mapVideo(video).src}
      image={mapImage(image) ?? getRequiredImageFallback()}
      {...others}
    />
  )
}

export default RichTextVideoBlok
