import Footer from '../patterns/Footer'
import notEmpty from '../utils/notEmpty'
import storyblokLinkResolver from '../utils/storyblokLinkResolver'
import NewsletterFormBlok from './NewsletterShortFormBlok'
import { FooterStoryblok } from './storyblok.generated'

interface Props {
  content: FooterStoryblok
}

const FooterBlok = ({
  content: {
    newsletterTitle,
    newsletterText,
    newsletterEmailInputBlock,
    newsletterPrivacyPolicyBlock,
    newsletterSubmitBlock,
    newsletterThankYouBlock,
    newsletterErrorBlock,
    newsletterPardotConfiguration,
    columnBlocks,
    socialBlocks,
    copyright,
    legalLinkBlocks,
    logoFootnote,
  },
  ...others
}: Props) => (
  <Footer
    newsletterForm={
      <NewsletterFormBlok
        newsletterTitle={newsletterTitle}
        newsletterText={newsletterText}
        emailBlock={newsletterEmailInputBlock}
        privacyPolicyBlock={newsletterPrivacyPolicyBlock}
        submitBlock={newsletterSubmitBlock}
        thankYouBlock={newsletterThankYouBlock}
        errorBlock={newsletterErrorBlock}
        pardotConfiguration={newsletterPardotConfiguration}
      />
    }
    columns={columnBlocks?.map(({ _uid, title, codeId, linkBlocks }) => ({
      key: _uid,
      title,
      codeId,
      links: linkBlocks.map(({ _uid, title, link }) => ({
        key: _uid,
        title,
        href: storyblokLinkResolver(link),
      })),
    }))}
    socials={socialBlocks
      ?.map(({ _uid, icon, link }) =>
        icon !== ''
          ? {
              key: _uid,
              icon,
              href: storyblokLinkResolver(link),
            }
          : undefined,
      )
      .filter(notEmpty)}
    copyright={copyright}
    logoFootnote={logoFootnote}
    legalLinks={legalLinkBlocks?.map((block) => ({
      key: block._uid,
      title: block.title,
      href:
        block.component === 'linkWithTitle'
          ? // eslint-disable-next-line security/detect-non-literal-fs-filename
            storyblokLinkResolver(block.link)
          : undefined,
    }))}
    {...others}
  />
)

export default FooterBlok
