import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps } from 'react'

import VideoPlayer from './VideoPlayer'
import {
  isSiteYachting,
  isThemeYachtingWomenInYachtingLandingspage,
} from '../themes'
import makeVideoSources from '../utils/makeVideoSources'
import { createImageLoader } from '../utils/createImageLoader'

const StyledVideoContainer = styled('div')(({ theme }) => [
  css`
    display: flex;
    justify-content: center;
    margin-top: ${theme.spacing.x6}px;
  `,
  isSiteYachting(theme) &&
    css`
      margin: ${theme.spacing.x6}px auto 0;
      max-width: 640px;
    `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      margin: ${theme.spacing.x7}px auto 0;
      max-width: none;

      > div {
        width: 100%;

        > video {
          width: 100%;
        }
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin: 0;
        margin: ${theme.spacing.x10}px auto 0;
      }
    `,
])

interface Props extends Omit<ComponentProps<typeof VideoPlayer>, 'children'> {
  image: {
    src: string
    alt?: string
  }
  video: string
}

const RichTextVideo = ({ image, video, ...others }: Props) => {
  const imageLoader = createImageLoader(image.src)

  const poster = imageLoader.loader
    ? imageLoader.loader({ src: imageLoader.src as string, width: 1920 })
    : image.src

  return (
    <StyledVideoContainer>
      <VideoPlayer poster={poster} {...others}>
        {makeVideoSources(video)}
      </VideoPlayer>
    </StyledVideoContainer>
  )
}

export default RichTextVideo
