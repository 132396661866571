/* eslint-disable @typescript-eslint/no-explicit-any */

const storyblokLinkResolver = (
  link:
    | {
        cached_url?: string
        linktype?: string
        [k: string]: any
      }
    | {
        id?: string
        href?: string
        cached_url?: string
        linktype?: 'story'
        [k: string]: any
      }
    | {
        id?: string
        cached_url?: string
        linktype?: 'story'
        [k: string]: any
        story: {
          uuid: string
          url: string
          full_slug: string
        }
      }
    | {
        url?: string
        cached_url?: string
        linktype?: 'asset'
        [k: string]: any
      }
    | {
        href?: string
        linktype?: 'url'
        [k: string]: any
      }
    | {
        href: string
        linktype: null
        [k: string]: any
      }
    | {
        cached_url?: string
        linktype?: 'url'
        [k: string]: any
      }
    | {
        email?: string
        linktype?: 'email'
        [k: string]: any
      },
) => {
  switch (link.linktype) {
    case 'email':
      return `mailto:${link.email}`
    case 'asset':
    case 'url':
      return link.url ?? link.href
    case 'story':
      // Disable it here as fallback when no story was resolved
      // eslint-disable-next-line no-restricted-properties
      return link?.story?.url ?? link.href ?? link.cached_url
    default:
      // Disable it here as fallback when no story was resolved
      // eslint-disable-next-line no-restricted-properties
      return link.href || link.url || link.cached_url
  }
}

export default storyblokLinkResolver
