import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ElementType, forwardRef, InputHTMLAttributes } from 'react'
import { isSiteYachtSupport } from '../themes'

const Container = styled('label', {
  shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'as',
})<{ disabled?: boolean }>(({ disabled }) => [
  css`
    display: inline-block;
    width: 24px;
    height: 24px;
    position: relative;
    input[type='checkbox']:focus + * {
      outline: currentColor auto 1px;
      outline: -webkit-focus-ring-color auto 1px;
    }
    input[type='checkbox']:checked + * + * {
      transform: scale(1);
    }
  `,
  disabled &&
    css`
      filter: grayscale(100%);
    `,
])
const Background = styled('div')(({ theme }) => [
  css`
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 1px solid currentColor;

    transition: box-shadow 100ms ease-in-out;
    label:hover & {
      box-shadow: inset 1px 2px 5px -2px rgb(0 0 0 / 40%);
    }
  `,
  isSiteYachtSupport(theme) &&
    css`
      border-radius: initial;
    `,
])

const Input = styled.input`
  opacity: 0;
  position: absolute;
`
const Checkmark = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  transition: transform 200ms cubic-bezier(0.2, 0.9, 0.42, 1);
  transform: scale(0);
  border-radius: 2px;
  background: currentColor;
`

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  as?: ElementType
}

// eslint-disable-next-line react/display-name
const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ disabled, value, className, as, ...others }: Props, ref) => (
    <Container className={className} disabled={disabled} as={as}>
      <Input
        type="checkbox"
        value={value}
        disabled={disabled}
        ref={ref}
        {...others}
      />
      <Background />
      <Checkmark />
    </Container>
  ),
)

export default Checkbox
