import { useCallback, useEffect, useState } from 'react'
import { storyblokBridgeLoaderPromise } from '../components/StoryblokBridgeLoader'

import { StoryblokStory } from './storyblok'

const useStoryblokBridge = <T extends StoryblokStory | undefined>(
  uuid: string | undefined,
  onNewStory: (newStory: T) => void,
) => {
  useEffect(() => {
    storyblokBridgeLoaderPromise.then((storyblokBridge) => {
      storyblokBridge.on('input', (event) => {
        if (event === undefined) {
          return
        }

        if (event.action === 'input' && event.story.uuid === uuid) {
          onNewStory(event.story as T)
        }
      })
    })
  }, [uuid, onNewStory])
}

// React hook which updates the InitialStory when the user is in the real time editor
export const useStory = <T extends StoryblokStory | undefined>(story: T): T => {
  const [modifiedStory, setModifiedStory] = useState<T | undefined>()

  const handleStoryChange = useCallback(
    (newStory: T) => setModifiedStory(newStory),
    [],
  )

  useStoryblokBridge<T>(story?.uuid, handleStoryChange)

  useEffect(() => {
    setModifiedStory(undefined)
  }, [story])

  // useState will only update *after* the render, but we need the story to
  // always represent the current story. This check ensures that if we change
  // stories, the new story is immediately visible.
  //
  // This is necessary because of the story's `rels` field being used in context
  // in the Page component. Since that immediately updates, we also need the
  // story to immediately update.
  // I imagine this might cause a new issue where there is a delay before the
  // preview story is shown during which the old story is visible. This in turn
  // may also cause crashes. Fixing this will probably require also fetching
  // the rels from the storyblokBridge to ensure they also preview, and to delay
  // rendering the component until all preview data is loaded properly.
  // This is a considerable change that I am not going to make right now as the
  // issue may only occur when previewing.
  // TODO: Fix the above
  if (modifiedStory && modifiedStory.uuid === story?.uuid) {
    return modifiedStory
  }
  return story
}
