import { css } from '@emotion/react'
import styled from '@emotion/styled'

// TODO: Accessibility (change focus, aria-hidden on other content etc)
// TODO: Close on escape key

const ModalFixed = styled.div(
  () => css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 10;
  `,
)

export default ModalFixed
