/* eslint-disable no-param-reassign */
import { MutableRefObject, useEffect } from 'react'

const useVideoKeyboardControls = (
  containerRef: MutableRefObject<HTMLDivElement | null>,
  videoRef: MutableRefObject<HTMLVideoElement | null>,
  seekBarRef: MutableRefObject<HTMLButtonElement | null>,
) => {
  useEffect(() => {
    const elem = containerRef.current
    if (!elem) {
      return
    }

    const handleKeyUp = (e: KeyboardEvent) => {
      if (!videoRef.current) {
        return
      }
      switch (e.key) {
        case ' ': // Spacebar
        case 'MediaPlay':
        case 'MediaPause':
          if (videoRef.current.paused) {
            videoRef.current.play()
          } else {
            videoRef.current.pause()
          }
          break
        case 'ArrowLeft':
        case 'MediaRewind':
          videoRef.current.currentTime -= 5
          seekBarRef.current?.focus()
          break
        case 'ArrowRight':
        case 'MediaFastForward':
          videoRef.current.currentTime += 5
          seekBarRef.current?.focus()
          break
        case 'ArrowUp':
        case 'AudioVolumeUp':
          videoRef.current.volume = Math.min(1, videoRef.current.volume + 0.1)
          break
        case 'ArrowDown':
        case 'AudioVolumeDown':
          videoRef.current.volume = Math.max(0, videoRef.current.volume - 0.1)
          break
        case 'F':
          videoRef.current.requestFullscreen?.()
          break
        default:
          break
      }
    }

    elem.addEventListener('keyup', handleKeyUp)
    // eslint-disable-next-line consistent-return
    return () => elem.removeEventListener('keyup', handleKeyUp)
  }, [containerRef, videoRef, seekBarRef])
}

export default useVideoKeyboardControls
