import { SiteStoryblok } from '../storyblok.generated'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'
import TopBar from '../../patterns/TopBar/TopBar'

export const hasTopBar = ({ topBarBlock }: SiteStoryblok) =>
  topBarBlock && !!topBarBlock.length

export const getTopBarItems = ({ topBarBlock }: SiteStoryblok) => {
  if (!topBarBlock || topBarBlock.length === 0) {
    return { label: '', links: [] }
  }

  const { links } = topBarBlock[0]

  return {
    label: topBarBlock[0].accessibilityLabel,
    links: links.map(({ title, link, linkTarget }) => ({
      title,
      linkTarget,
      link: storyblokLinkResolver(link),
    })),
  }
}

interface Props {
  siteStory: SiteStoryblok
}

const TopBarBlok = ({ siteStory }: Props) => {
  return <TopBar {...getTopBarItems(siteStory)} />
}

export default TopBarBlok
