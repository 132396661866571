import { css, Theme } from '@emotion/react'
import styled from '@emotion/styled'
import { forwardRef, SelectHTMLAttributes } from 'react'
import { FieldError } from 'react-hook-form'

import SelectArrow from '../icons/yachting/SelectArrow.svg'
import ChevronDown from '../icons/xplorer/ChevronDown.svg'
import FormField from './FormField'
import {
  isSiteAmels,
  isSiteXplorer,
  isSiteYachting,
  isSiteYachtSupport,
} from '../themes'

const StyledFormField = styled(FormField)(({ theme, variant }) => [
  css`
    /* width: 100%; */
  `,
  isSiteAmels(theme) &&
    css`
      width: auto;
    `,
  variant === 'topbar' &&
    css`
      max-width: 100%;
      width: 100%;

      @media screen and (min-width: ${theme.breakpoints.mobileMedium}px) {
        max-width: 180px;
      }
    `,
])
const LabelAbove = styled.div(
  ({ theme }) => css`
    text-align: left;
    text-transform: uppercase;
    color: ${theme.colors.amels.beach};
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 3.43px;
    margin-bottom: ${theme.spacing.x1}px;
  `,
)
const InputContainer = styled('div', {
  shouldForwardProp: (prop) =>
    !['error', 'fullWidth', 'variant'].includes(prop),
})<{
  error?: boolean
  fullWidth?: boolean
  variant?: 'topbar'
}>(({ fullWidth, variant }) => [
  css`
    position: relative;
  `,
  !fullWidth &&
    css`
      display: inline-block;
    `,
  variant === 'topbar' &&
    css`
      display: flex;
      align-items: center;
    `,
])
export const Line = styled('div', {
  shouldForwardProp: (prop) => prop !== 'error' && prop !== 'colorScheme',
})<{
  error?: boolean
  colorScheme?: 'light' | 'dark'
}>(({ theme, error, colorScheme }) => [
  css`
    display: block;
    width: 100%;
    height: 1px;
    background: currentColor;
  `,
  isSiteAmels(theme) &&
    css`
      background: ${theme.colors.amels.deepBayAqua};
    `,
  isSiteXplorer(theme) &&
    css`
      background: ${colorScheme === 'dark'
        ? theme.colors.xplorer.white
        : theme.colors.xplorer.black};
    `,
  isSiteYachtSupport(theme) &&
    css`
      background: ${colorScheme === 'dark'
        ? theme.colors.yachtSupport.white
        : theme.colors.yachtSupport.black};
    `,
  error &&
    css`
      background: ${theme.colors.amels.hazySunsetOrange};
    `,
])
const Select = styled('select', {
  shouldForwardProp: (prop) =>
    prop !== 'error' && prop !== 'colorScheme' && prop !== 'variant',
})<{
  error?: boolean
  colorScheme?: 'light' | 'dark'
  variant?: 'topbar'
}>(({ theme, error, colorScheme, variant }) => [
  css`
    border: 0;
    padding: ${theme.spacing.x1}px ${theme.spacing.x3}px ${theme.spacing.x1}px;
    margin: 0 -${theme.spacing.x1}px;
    min-width: 100%;

    :focus {
      outline: 0;
    }

    // Prevent option text from being the wrong color on Windows
    option {
      color: initial;
    }
  `,
  isSiteAmels(theme) &&
    css`
      color: ${theme.colors.amels.deepBayAqua};

      :focus + ${Line} {
        background: ${theme.colors.amels.bayGold};
      }
    `,
  isSiteYachting(theme) &&
    css`
      background: none;

      :focus + ${Line} {
        background: ${theme.colors.yachting.orange};
      }
    `,
  isSiteXplorer(theme) &&
    css`
      padding: ${theme.spacing.x1}px;
      background: none;
      color: ${colorScheme === 'dark'
        ? theme.colors.xplorer.white
        : theme.colors.xplorer.black};

      :focus + ${Line} {
        background: ${theme.colors.xplorer.secondaryDamenYachtingOrange};
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      padding: ${theme.spacing.x2}px ${theme.spacing.x1}px;
      background: none;
      color: ${colorScheme === 'dark'
        ? theme.colors.yachtSupport.white
        : theme.colors.yachtSupport.black};

      :focus + ${Line} {
        background: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
      }
    `,
  error &&
    css`
      color: ${theme.colors.amels.hazySunsetOrange};
      opacity: 0.5;
    `,
  variant === 'topbar' &&
    css`
      margin: 0;
      padding: 2px ${theme.spacing.x2}px;
      color: ${theme.colors.xplorer.white};
      background: none;
      font-size: 16px;

      option {
        color: ${theme.colors.xplorer.black};
      }
    `,
])
const Arrow = styled(SelectArrow)(({ theme, variant }) => [
  css`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    height: 12px;
  `,
  isSiteAmels(theme) &&
    css`
      display: none;
    `,
  isSiteYachting(theme) &&
    css`
      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        height: 16px;
      }
    `,
  isSiteXplorer(theme) &&
    css`
      display: none;
    `,
  isSiteYachtSupport(theme) &&
    css`
      display: none;
    `,
  variant === 'topbar' &&
    css`
      display: none;
    `,
])

const Chevron = styled(ChevronDown)(({ theme, variant }) => [
  css`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    height: 12px;

    path {
      fill: currentColor;
    }
  `,
  isSiteAmels(theme) &&
    css`
      display: none;
    `,
  isSiteYachting(theme) &&
    css`
      display: none;
    `,
  isSiteXplorer(theme) &&
    css`
      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        height: 16px;
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        height: 16px;
      }
    `,
  variant === 'topbar' &&
    css`
      display: block;
      pointer-events: none;
      right: ${theme.spacing.x1}px;
      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        height: 16px;
      }
    `,
])

export const filledVariantCss = (theme: Theme) => css`
  ${InputContainer} {
    background-color: ${theme.colors.amels.silk};

    ${Line} {
      display: none;
    }
  }
`

export const LabelHidden = styled.label(
  () => css`
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  `,
)

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  label: string
  labelPlacement?: 'inside' | 'above' | 'hidden'
  colorScheme?: 'light' | 'dark'
  options: Array<{
    key: string
    value: string
  }>
  noInitialOption?: boolean
  error?: FieldError
  fullWidth?: boolean
  variant?: 'topbar'
  'data-testid'?: string
}

// eslint-disable-next-line react/display-name
const FormFieldSelect = forwardRef<HTMLSelectElement, Props>(
  (
    {
      label,
      labelPlacement = 'inside',
      colorScheme,
      options,
      noInitialOption,
      error,
      required,
      fullWidth = true,
      'data-testid': testid,
      className,
      variant,
      ...others
    }: Props,
    ref,
  ) => (
    <StyledFormField
      error={error}
      data-testid={testid}
      className={className}
      variant={variant}
    >
      {labelPlacement === 'above' && (
        <LabelAbove>
          {label} {required ? '*' : ''}
        </LabelAbove>
      )}
      {labelPlacement === 'hidden' && <LabelHidden>{label}</LabelHidden>}
      <InputContainer
        error={Boolean(error)}
        fullWidth={fullWidth}
        variant={variant}
      >
        <Select
          aria-required={required}
          aria-invalid={Boolean(error)}
          error={Boolean(error)}
          colorScheme={colorScheme}
          variant={variant}
          ref={ref}
          {...others}
        >
          {!noInitialOption && (
            <option hidden={required} value="">
              {labelPlacement === 'inside' && label}{' '}
              {labelPlacement === 'inside' && required ? '*' : ''}
            </option>
          )}
          {options.map(({ key, value }) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </Select>
        {variant !== 'topbar' && (
          <Line error={Boolean(error)} colorScheme={colorScheme} />
        )}
        <Arrow variant={variant} />
        <Chevron variant={variant} />
      </InputContainer>
    </StyledFormField>
  ),
)

export default FormFieldSelect
