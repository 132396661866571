import { css } from '@emotion/react'
import styled from '@emotion/styled'

const Backdrop = styled.div(
  () => css`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.5);
  `,
)

export default Backdrop
