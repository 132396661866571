import { MutableRefObject, useEffect, useState } from 'react'

const useVideoTimePlayed = (
  videoRef: MutableRefObject<HTMLVideoElement | null>,
) => {
  const [timePlayed, setTimePlayed] = useState(0)
  const [videoDuration, setVideoDuration] = useState(0)
  useEffect(() => {
    const elem = videoRef.current
    if (!elem) {
      return
    }

    const updateTimePlayed = () => {
      setTimePlayed(elem.currentTime)
      setVideoDuration(elem.duration)
    }
    updateTimePlayed()

    elem.addEventListener('timeupdate', updateTimePlayed)
    elem.addEventListener('durationchange', updateTimePlayed)
    // eslint-disable-next-line consistent-return
    return () => {
      elem.removeEventListener('timeupdate', updateTimePlayed)
      elem.removeEventListener('durationchange', updateTimePlayed)
    }
  }, [videoRef])

  return [timePlayed, videoDuration] as const
}

export default useVideoTimePlayed
