import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Link from './Link'
import {
  isSiteAmels,
  isSiteXplorer,
  isSiteYachtSupport,
  isThemeYachtingWomenInYachtingLandingspage,
} from '../themes'

const RecaptchaDisclaimerDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'colorScheme',
})<Props>(({ theme, color = 'deepBayAqua30' }) => [
  css`
    a {
      color: inherit;
    }
  `,
  isSiteAmels(theme) &&
    css`
      font-size: 12px;
      color: ${color === 'deepBayAqua30'
        ? theme.colors.amels.deepBayAqua30
        : theme.colors.amels.deepBayAqua60};
    `,
  isSiteXplorer(theme) &&
    css`
      font-size: 16px;
      color: ${color === 'primaryXPLavaBlack'
        ? theme.colors.xplorer.primaryXPLavaBlack
        : theme.colors.xplorer.white};
    `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      margin: ${theme.spacing.x5}px 0;
    `,
  isSiteYachtSupport(theme) &&
    css`
      font-size: ${theme.text.bodySmall(theme)};
      color: ${theme.colors.yachtSupport.grey05};
      margin-bottom: ${theme.spacing.x4}px;
    `,
])
const StyledLink = styled(Link)`
  text-decoration: underline;
`

interface Props {
  color?: 'deepBayAqua60' | 'deepBayAqua30' | 'primaryXPLavaBlack'
}

// This is the required text by Google Recaptcha if you don't use the widget, so
// this is not configurable
const RecaptchaDisclaimer = ({ color }: Props) => (
  <RecaptchaDisclaimerDiv color={color}>
    This site is protected by reCAPTCHA and the Google{' '}
    <StyledLink href="https://policies.google.com/privacy">
      Privacy Policy
    </StyledLink>{' '}
    and{' '}
    <StyledLink href="https://policies.google.com/terms">
      Terms of Service
    </StyledLink>{' '}
    apply.
  </RecaptchaDisclaimerDiv>
)

export default RecaptchaDisclaimer
