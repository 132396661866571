import NextHead from 'next/head'

import { GoogleTagManagerScript } from './GoogleTagManager'

export type Props = {
  robots: string
  title?: string
  description?: string
  openGraph: {
    image?: string
    title?: string
    description?: string
  }
  twitter: {
    title?: string
    image?: string
    description?: string
  }
  canonical: string
}
const Head = ({
  title,
  canonical,
  robots,
  description,
  twitter,
  openGraph,
}: Props) => {
  return (
    <>
      <NextHead>
        <meta charSet="UTF-8" />
        <title>{title}</title>
        <link rel="canonical" href={canonical} />
        <meta name="robots" content={robots} />
        <meta name="description" content={description} />
        <meta name="og:type" content="website" />
        <meta name="og:title" content={openGraph.title ?? title} />
        <meta
          name="og:description"
          content={openGraph.description ?? description}
        />
        <meta name="og:url" content={canonical} />
        <meta name="og:image" content={openGraph.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={twitter.title ?? title} />
        <meta
          name="twitter:description"
          content={twitter.description ?? description}
        />
        <meta name="twitter:image" content={twitter.image ?? openGraph.image} />
        <link rel="preconnect" href="https://res.cloudinary.com/" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#00606f" />
        <meta name="msapplication-TileColor" content="#faf7ee" />
        <meta name="theme-color" content="#faf7ee" />
        <link rel="alternate" hrefLang="en" href={canonical} />
        <link rel="alternate" hrefLang="x-default" href={canonical} />
      </NextHead>
      <GoogleTagManagerScript />
    </>
  )
}

export default Head
