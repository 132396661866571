import { useAppContext } from '../components/RuntimeConfigProvider'
import { currentSite, Site } from '../sites'

// TODO: all logic for hostname is now on a single place, we should use ENV variables for this.
export const getHostNameForSite = (
  site: Site,
  defaultHostName: string | undefined = undefined,
) => {
  // eslint-disable-next-line default-case
  switch (site) {
    case currentSite:
      return defaultHostName
    case Site.Amels:
      return 'https://www.amelsyachting.com'
    case Site.Xplorer:
      return 'https://www.xploreryachting.com'
    case Site.Yachting:
      return 'https://www.damenyachting.com'
    case Site.YachtSupport:
      return 'https://www.yachtsupport.com'
  }
  throw new Error('not reachable')
}

export const useCurrentHostname = () => {
  const { publicRuntimeConfig } = useAppContext()

  const defaultHostName = publicRuntimeConfig.NEXT_PUBLIC_HOSTNAME

  return {
    hostName: defaultHostName,
    getHostNameForSite: (site: Site) =>
      getHostNameForSite(site, defaultHostName),
  }
}
