import {
  FieldValues,
  useForm,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form'
import {
  useRecaptchaWidget,
  UseRecaptchaProps,
  UseRecaptchaReturn,
} from './useRecaptcha'

const useRecaptchaForm = <
  TFieldValues extends FieldValues = FieldValues,
  // Disabled because this is the exact typing from 'react-hook-form'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any,
>(
  props?: UseFormProps<
    TFieldValues & { gRecaptchaResponse: string },
    TContext
  > &
    UseRecaptchaProps,
): UseFormReturn<TFieldValues & { gRecaptchaResponse: string }, TContext> &
  UseRecaptchaReturn => {
  const formProps = useForm(props)
  const recaptchaProps = useRecaptchaWidget()

  return { ...formProps, ...recaptchaProps }
}

export { useRecaptchaForm }
