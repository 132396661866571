const makeCloudinaryVideoUrl = ({
  publicId,
  format = 'mp4',
  transformations = [],
}: {
  publicId: string
  format?: string
  transformations?: string[]
}) => {
  const path = [
    process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
    'video',
    'upload',
    ...transformations,
    `${publicId}.${format}`,
  ]

  return `https://res.cloudinary.com/${path.join('/')}`
}

export default makeCloudinaryVideoUrl
