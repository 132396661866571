import styled from '@emotion/styled'
import { ReactNode } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import squarePolygon from '../utils/squarePolygon'
import { LeftPane, RightPane, StyledAnimateText } from './SiteNavigation'

const transitionDuration = 800
const transitionName = 'site-navigation-transition'
const Container = styled.div`
  &.${transitionName}-enter {
    ${LeftPane} {
      clip-path: ${squarePolygon(0, 0, 0, 1)};
    }
    ${RightPane} {
      clip-path: ${squarePolygon(0, 1, 0, 0)};
    }
  }
  &.${transitionName}-enter-active {
    ${LeftPane} {
      clip-path: ${squarePolygon()};
      transition: clip-path ${transitionDuration}ms ease-in-out;
    }
    ${RightPane} {
      clip-path: ${squarePolygon()};
      transition: clip-path ${transitionDuration}ms ease-in-out;
    }
    ${StyledAnimateText} {
      clip-path: ${squarePolygon()};
      transition: clip-path ${transitionDuration}ms ease-in-out;
    }
  }
  &.${transitionName}-exit {
    ${LeftPane} {
      clip-path: ${squarePolygon()};
    }
    ${RightPane} {
      clip-path: ${squarePolygon()};
    }
    ${StyledAnimateText} {
      clip-path: ${squarePolygon()};
    }
  }
  &.${transitionName}-exit-active {
    ${LeftPane} {
      clip-path: ${squarePolygon(0, 0, 0, 1)};
      transition: clip-path ${transitionDuration}ms ease-in-out;
    }
    ${RightPane} {
      clip-path: ${squarePolygon(0, 1, 0, 0)};
      transition: clip-path ${transitionDuration}ms ease-in-out;
    }
    ${StyledAnimateText} {
      clip-path: ${squarePolygon(0, 1, 0, 0)};
      transition: clip-path ${transitionDuration}ms ease-in-out;
    }
  }
`

interface Props {
  children: ReactNode
  isOpen: boolean
}

const SiteNavigationTransition = ({ children, isOpen }: Props) => (
  <TransitionGroup component={null}>
    {isOpen && (
      <CSSTransition classNames={transitionName} timeout={transitionDuration}>
        <Container>{children}</Container>
      </CSSTransition>
    )}
  </TransitionGroup>
)

export default SiteNavigationTransition
