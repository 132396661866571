const squarePolygon = (
  // percentage that needs to be cut off
  left = 0,
  top = 0,
  right = 0,
  bottom = 0,
) =>
  `polygon(${[
    `${left * 100}% ${top * 100}%`,
    `${(1 - right) * 100}% ${top * 100}%`,
    `${(1 - right) * 100}% ${(1 - bottom) * 100}%`,
    `${left * 100}% ${(1 - bottom) * 100}%`,
  ].join(', ')})`

export default squarePolygon
