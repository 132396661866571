import { createContext, ReactElement, useContext } from 'react'

import {
  StoryblokLink,
  StoryblokStory,
  StoryblokStoryResponse,
} from '../utils/storyblok'
import { SiteStoryblok } from './storyblok.generated'

export interface PageContext {
  rels: StoryblokStory[]
  links: StoryblokLink[]
  siteStory?: StoryblokStoryResponse<StoryblokStory<SiteStoryblok>>
  SectionIndicator?: (props: { variant: 'light' | 'dark' }) => ReactElement
}
const PageContext = createContext<PageContext>({
  rels: [],
  links: [],
  siteStory: undefined,
  SectionIndicator: undefined,
})
export const usePageContext = () => useContext(PageContext)
export const PageContextProvider = PageContext.Provider
