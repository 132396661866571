const makeCloudinaryImageUrl = ({
  publicId,
  transformations = [],
}: {
  publicId: string
  transformations?: string[]
}) => {
  const path = [
    process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
    'image',
    'upload',
    transformations.join(','), // We combine all transformations into a "single" path so scale options work as expected with widht/height instead of seperatly
    publicId,
  ]

  return `https://res.cloudinary.com/${path.join('/')}`
}

export default makeCloudinaryImageUrl
