import { FunctionComponent } from 'react'

import RichTextLinkBlok from './RichTextLinkBlok'
import RichTextTableBlok from './RichTextTableBlok'
import RichTextVideoBlok from './RichTextVideoBlok'
import RichTextBynderImageBlok from './RichTextBynderImageBlok'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const components: Record<string, FunctionComponent<any>> = {
  richTextLink: RichTextLinkBlok,
  richTextTable: RichTextTableBlok,
  richTextVideo: RichTextVideoBlok,
  bynderImage: RichTextBynderImageBlok,
}

export default components
