import RichTextLink from '../../components/RichTextLink'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'
import { RichTextLinkStoryblok } from '../storyblok.generated'

interface Props {
  content: RichTextLinkStoryblok
}

const RichTextLinkBlok = ({
  content: { link, text, alignment },
  ...others
}: Props) =>
  link && (
    <RichTextLink
      href={storyblokLinkResolver(link)}
      alignment={alignment}
      {...others}
    >
      {text}
    </RichTextLink>
  )

export default RichTextLinkBlok
