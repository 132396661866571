import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { forwardRef, InputHTMLAttributes } from 'react'
import { FieldError } from 'react-hook-form'

import FormField from './FormField'
import {
  isSiteAmels,
  isSiteXplorer,
  isSiteYachting,
  isSiteYachtSupport,
} from '../themes'

const Line = styled('div', {
  shouldForwardProp: (prop) => prop !== 'error',
})<{ error?: boolean }>(({ theme, error }) => [
  css`
    display: block;
    width: 100%;
    height: 1px;
    background: currentColor;
  `,
  error &&
    isSiteAmels(theme) &&
    css`
      background: ${theme.colors.amels.hazySunsetOrange};
    `,
  error &&
    isSiteYachting(theme) &&
    css`
      background: ${theme.colors.yachting.orange};
    `,
  error &&
    isSiteXplorer(theme) &&
    css`
      background: ${theme.colors.xplorer.error};
    `,
])
const Input = styled('input', {
  shouldForwardProp: (prop) => prop !== 'error',
})<{ error?: boolean }>(({ theme, error }) => [
  css`
    width: calc(100% + 8px * 2); // to compensate the negative margins
    border: 0;
    color: inherit;
    padding: 8px 8px;
    margin: 0 -8px;

    :focus {
      outline: 0;
    }
    :focus + ${Line} {
      background: ${theme.colors.amels.bayGold};
    }
  `,
  isSiteAmels(theme) &&
    css`
      :focus + ${Line} {
        background: ${theme.colors.amels.bayGold};
      }
    `,
  isSiteXplorer(theme) &&
    css`
      :focus + ${Line} {
        background: ${theme.colors.xplorer.secondaryDamenYachtingOrange};
      }

      ::placeholder {
        color: inherit;
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      padding: ${theme.spacing.x2}px ${theme.spacing.x1}px;

      :focus + ${Line} {
        background: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
      }

      ::placeholder {
        color: inherit;
      }
    `,
  isSiteYachting(theme) &&
    css`
      :focus + ${Line} {
        background: ${theme.colors.yachting.orange};
      }
    `,
  error &&
    isSiteAmels(theme) &&
    css`
      color: ${theme.colors.amels.hazySunsetOrange};
      opacity: 0.5;

      ::placeholder {
        color: ${theme.colors.amels.hazySunsetOrange};
      }
    `,
  error &&
    isSiteYachting(theme) &&
    css`
      color: ${theme.colors.yachting.orange};
      opacity: 0.5;

      ::placeholder {
        color: ${theme.colors.yachting.orange};
      }
    `,
])

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  error?: FieldError
  'data-testid'?: string
}

// eslint-disable-next-line react/display-name
const FormFieldText = forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      error,
      required,
      'data-testid': testid,
      className,
      ...others
    }: Props,
    ref,
  ) => (
    <FormField error={error} data-testid={testid} className={className}>
      <Input
        type="text"
        placeholder={`${label} ${required ? '*' : ''}`}
        aria-required={required}
        aria-invalid={Boolean(error)}
        error={Boolean(error)}
        ref={ref}
        {...others}
      />
      <Line error={Boolean(error)} />
    </FormField>
  ),
)

export default FormFieldText
