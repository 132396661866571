/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/media-has-caption */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  HTMLAttributes,
  MouseEvent as ReactMouseEvent,
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'
import useVideoIsPlaying from '../utils/useVideoIsPlaying'
import useVideoKeyboardControls from '../utils/useVideoKeyboardControls'
import useVideoSeekBar from '../utils/useVideoSeekBar'
import useVideoTimePlayed from '../utils/useVideoTimePlayed'

import PlayIcon from '../icons/PlayButton.svg'
import PauseIcon from '../icons/PauseButton.svg'
import FullscreenIcon from '../icons/Fullscreen.svg'
import MoveSliderIcon from '../icons/MoveSlider.svg'

const Video = styled.video`
  max-width: 100%;
  max-height: 100%;
`
const Controls = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  color: #fff;
`
const Container = styled.div`
  position: relative;

  ${Controls} {
    opacity: 0;
    will-change: opacity;
    transition: opacity ease-out 400ms;
  }
  :hover {
    ${Controls} {
      opacity: 1;
    }
  }
`
const PlayButton = styled.button`
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border: 0;
  padding: 0;
`
const PauseButton = styled.button`
  position: absolute;
  left: 24px;
  bottom: 38.6px;
  width: 30px;
  height: 30px;
  border: 0;
  padding: 0;
`
const TimeIndicator = styled.div`
  position: absolute;
  left: 70px;
  bottom: 49.6px;
  line-height: 1;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0.5px;
`
const FullScreenButton = styled.button`
  position: absolute;
  right: 24px;
  bottom: 47.6px;
  width: 28px;
  height: 28px;
  border: 0;
  padding: 8px;
  margin-right: -8px;
  margin-bottom: -8px;
`
const Buffer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.5);
`
const StyledMoveSliderIcon = styled(MoveSliderIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 7px;
  opacity: 0;
  will-change: opacity;
  transition: opacity 200ms ease-in-out;
`
const CurrentPositionIndicator = styled.div(
  () => css`
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 15px;
    border: 0;
    padding: 5px;
    transition: left linear 200ms, padding ease-out 400ms;

    ::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background: #fff;
      width: 5px;
      height: 5px;
      transition: all ease-out 400ms;
      color: transparent;
    }
  `,
)
const SeekBarButton = styled.button(
  ({ theme }) => css`
    position: absolute;
    left: 24px;
    right: 24px;
    bottom: 24px;
    border: 0;
    padding: 10px;
    margin-bottom: -10px;

    :hover,
    :active,
    :focus {
      ${CurrentPositionIndicator} {
        ::before {
          width: 15px;
          height: 15px;
        }
        padding: 4px;
        color: ${theme.colors.amels.deepBayAqua};
        ${StyledMoveSliderIcon} {
          opacity: 1;
        }
      }
    }
  `,
)
const Played = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 0%;
  height: 1px;
  background: #fff;
  transition: width linear 200ms;
`

const formatTime = (seconds: number) =>
  `${Math.floor(seconds / 60)}:${String(Math.round(seconds % 60)).padStart(
    2,
    '0',
  )}`
const useVideoIsActive = (
  videoRef: MutableRefObject<HTMLVideoElement | null>,
) => {
  const [isActive, setIsActive] = useState(false)
  useEffect(() => {
    const elem = videoRef.current
    if (!elem) {
      return
    }

    const handlePlay = () => setIsActive(true)

    elem.addEventListener('play', handlePlay)
    // eslint-disable-next-line consistent-return
    return () => {
      elem.removeEventListener('play', handlePlay)
    }
  }, [videoRef])

  return isActive
}

interface Props extends Omit<HTMLAttributes<HTMLVideoElement>, 'onClick'> {
  children: ReactNode
  poster?: string
}

const VideoPlayer = ({ children, className, ...others }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)

  const isActive = useVideoIsActive(videoRef)
  const isPlaying = useVideoIsPlaying(videoRef)
  const [timePlayed, videoDuration] = useVideoTimePlayed(videoRef)

  const togglePlay = () =>
    !isPlaying ? videoRef.current?.play() : videoRef.current?.pause()

  const seekBarRef = useRef<HTMLButtonElement>(null)
  const handleSeekBarMouseDown = useVideoSeekBar(videoRef, seekBarRef)
  useVideoKeyboardControls(containerRef, videoRef, seekBarRef)

  const handleSeekBarClick = (e: ReactMouseEvent<HTMLButtonElement>) => {
    // Avoid clicking the seek bar from propagating and pausing the video
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <Container
      className={className}
      tabIndex={0}
      role="group"
      onClick={togglePlay}
      ref={containerRef}
      data-testid="videoPlayer"
    >
      <Video playsInline ref={videoRef} {...others}>
        {children}
      </Video>
      {!isActive && (
        <PlayButton
          onClick={(e) => {
            e.preventDefault()
            // Prevent the container's click from being triggered too
            e.stopPropagation()
            togglePlay()
          }}
        >
          <PlayIcon aria-label="Play" />
        </PlayButton>
      )}
      {isActive && (
        <Controls data-testid="videoPlayer.controls">
          <PauseButton onClick={togglePlay}>
            {isPlaying ? (
              <PauseIcon aria-label="Pause" />
            ) : (
              <PlayIcon aria-label="Play" />
            )}
          </PauseButton>
          <TimeIndicator>
            {formatTime(timePlayed)} / {formatTime(videoDuration)}
          </TimeIndicator>
          <FullScreenButton
            onClick={() => videoRef.current?.requestFullscreen?.()}
          >
            <FullscreenIcon aria-label="Fullscreen" />
          </FullScreenButton>
          <SeekBarButton
            ref={seekBarRef}
            onClick={handleSeekBarClick}
            onMouseDown={handleSeekBarMouseDown}
          >
            <Buffer />
            <Played
              style={{ width: `${(timePlayed / videoDuration) * 100}%` }}
            />
            <CurrentPositionIndicator
              style={{
                left: `${(timePlayed / videoDuration) * 100}%`,
              }}
            >
              <StyledMoveSliderIcon aria-label="Move slider" />
            </CurrentPositionIndicator>
          </SeekBarButton>
        </Controls>
      )}
    </Container>
  )
}

export default VideoPlayer
