import Head from 'next/head'
import Script from 'next/script'
import { useInView } from 'react-intersection-observer'

const RecaptchaLoader = () => {
  const [containerRef, inView] = useInView({
    rootMargin: '200px 0px',
    triggerOnce: true,
  })

  return (
    <>
      <div data-testid="recaptcha.loader" ref={containerRef} />
      <Head>
        <link rel="preconnect" href="https://recaptcha.net" />
      </Head>
      {/* Only load recaptcha when the form is visible as this library is quite "big" */}
      {inView && (
        <Script
          id="googleRecaptcha"
          /* We want to force EN to have a consistent experience on the website */
          src="https://recaptcha.net/recaptcha/api.js?render=explicit&hl=en"
          strategy="afterInteractive"
        />
      )}
    </>
  )
}
export default RecaptchaLoader
