import { useTheme } from '@emotion/react'
import { useEffect, useState } from 'react'

import useViewportSize from './useViewportSize'

/**
 * Determine whether the mobile breakpoint is currently active.
 *
 * Only use this when the same is impossible to reach using CSS @media tags.
 * When used incorrectly, this will cause hydration issues after SSG. Avoid
 * using this as much as possible.
 */
const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false)
  const theme = useTheme()
  const viewportSize = useViewportSize()
  useEffect(() => {
    setIsMobile(
      viewportSize !== undefined &&
        viewportSize.width < theme.breakpoints.mobileMax,
    )
  }, [viewportSize, theme])
  return isMobile
}

export default useIsMobile
