import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps } from 'react'

const Container = styled.div(
  ({ theme }) => css`
    border: 1px solid ${theme.colors.amels.hazySunsetOrange};
    padding: ${theme.spacing.x1}px;
  `,
)

interface Props extends Omit<ComponentProps<typeof Container>, 'children'> {
  name: string
  props?: unknown
}

const MissingBlokComponent = ({ name, props, ...others }: Props) => {
  if (process.env.NODE_ENV !== 'development') {
    return null
  }

  if (['contentWithInlineCarousel'].includes(name)) {
    // eslint-disable-next-line no-console
    console.info('This blok has been deprecated:', name, props)

    return <Container {...others}>Deprecated blok: {name}</Container>
  }

  // eslint-disable-next-line no-console
  console.warn('Missing component for blok:', name, props)

  return <Container {...others}>Missing component for blok: {name}</Container>
}

export default MissingBlokComponent
