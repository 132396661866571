import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'

import useDisableBodyScroll from '../utils/useDisableBodyScroll'
import Backdrop from './Backdrop'
import CloseButton from './CloseButton'
import { isSiteXplorer, isSiteYachting, isSiteYachtSupport } from '../themes'

const StyledCloseButton = styled(CloseButton)(({ theme }) => [
  css`
    position: absolute;
    top: ${theme.spacing.x2}px;
    right: ${theme.spacing.x2}px;
    width: ${theme.spacing.x9}px;
    height: ${theme.spacing.x9}px;
    z-index: 101;
  `,
  isSiteYachting(theme) &&
    css`
      width: ${theme.spacing.x8}px;
      height: ${theme.spacing.x8}px;
    `,
  isSiteXplorer(theme) &&
    css`
      padding: 0;
      border-radius: 50%;
      top: ${theme.spacing.x4}px;
      right: ${theme.spacing.x4}px;
      width: ${theme.spacing.x5}px;
      height: ${theme.spacing.x5}px;
    `,
  isSiteYachtSupport(theme) &&
    css`
      padding: 0;
      top: ${theme.spacing.x4}px;
      right: ${theme.spacing.x4}px;
      width: ${theme.spacing.x5}px;
      height: ${theme.spacing.x5}px;
    `,
])
export const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant?: 'light' | 'dark' }>(({ theme, variant = 'light' }) => [
  css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: ${theme.colors.amels.silk};
    color: #000;
    padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;
    line-height: 1.75;
    letter-spacing: 1px;
    font-weight: 400;
    overflow-y: auto;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      max-width: 584px;
      padding: ${theme.spacing.x10}px ${theme.spacing.x12}px
        ${theme.spacing.x10}px ${theme.spacing.x8}px;
    }
  `,
  isSiteYachting(theme) &&
    css`
      background-color: ${theme.colors.amels.silk};
      color: ${theme.colors.yachting.darkBlue};
    `,
  variant === 'dark' &&
    css`
      background: ${theme.colors.amels.deepBayAqua};
      color: ${theme.colors.amels.silk};

      ${StyledCloseButton} {
        color: ${theme.colors.amels.silk};
      }
    `,
  variant === 'dark' &&
    isSiteYachting(theme) &&
    css`
      background-color: ${theme.colors.yachting.darkBlue};
      color: ${theme.colors.amels.silk};
    `,
  isSiteXplorer(theme) &&
    css`
      line-height: 1.5;
      padding: ${theme.spacing.x8}px ${theme.spacing.x3}px;
      background-color: ${theme.colors.xplorer.secondaryXPMistGrey};
      color: ${theme.colors.xplorer.primaryXPLavaBlack};
    `,
  isSiteYachtSupport(theme) &&
    css`
      line-height: 1.5;
      background-color: ${theme.colors.yachtSupport.white};
      color: ${theme.colors.yachtSupport.primaryYSCarbonBlack};
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        max-width: 475px;
        padding: ${theme.spacing.x10}px ${theme.spacing.x8}px
          ${theme.spacing.x5}px ${theme.spacing.x6}px;
      }
    `,
])
export const StyledBackdrop = styled(Backdrop)(({ theme }) => [
  css`
    position: absolute;
    display: none;
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: block;
      width: calc(100% - 584px);
    }
  `,
  isSiteYachtSupport(theme) &&
    css`
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        width: calc(100% - 475px);
      }
    `,
])

interface Props extends ComponentProps<typeof Container> {
  children: ReactNode
  close: () => void
  'data-testid'?: string
  variant?: 'light' | 'dark'
  disableBodyScroll?: boolean
}

const SidePanel = ({
  children,
  close,
  'data-testid': testid = 'sidePanel',
  variant,
  disableBodyScroll = true,
  ...others
}: Props) => {
  const containerRef = useDisableBodyScroll(disableBodyScroll)

  return (
    <>
      <StyledCloseButton
        variant={variant === 'dark' ? 'light' : undefined}
        onClick={close}
        data-testid={`${testid}.close`}
      />
      <StyledBackdrop onClick={close} data-testid={`${testid}.backdrop`} />
      <Container
        {...others}
        variant={variant}
        data-testid={testid}
        ref={containerRef}
      >
        {children}
      </Container>
    </>
  )
}

export default SidePanel
