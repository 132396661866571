import Script from 'next/script'

import { createGoogleTagManagerScript } from '../utils/createGoogleTagManagerScript'
import { useAppContext } from './RuntimeConfigProvider'

const SCRIPT_GTM = 'script-gtm'

export const GoogleTagManagerScript = () => {
  const { publicRuntimeConfig } = useAppContext()

  if (publicRuntimeConfig.NEXT_PUBLIC_GTM_CODE) {
    return (
      <Script
        id={SCRIPT_GTM}
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: createGoogleTagManagerScript(
            publicRuntimeConfig.NEXT_PUBLIC_GTM_CODE,
          ),
        }}
      />
    )
  }

  return null
}

export const GoogleTagManagerNoScript = () => {
  // eslint-disable-next-line dot-notation, prefer-destructuring
  const NEXT_PUBLIC_GTM_CODE = process.env['NEXT_PUBLIC_GTM_CODE']

  if (NEXT_PUBLIC_GTM_CODE) {
    return (
      <noscript>
        <iframe
          title="gtm"
          src={`https://www.googletagmanager.com/ns.html?id=${NEXT_PUBLIC_GTM_CODE}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    )
  }

  return null
}
