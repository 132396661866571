// eslint-disable-next-line no-restricted-imports
import { ImageLoader, ImageProps } from 'next/image'
import { mapImage } from './mapImage'
import { getNextImageLoader } from './getNextImageLoader'

export const createImageLoader = (
  src: string,
  aspectRatio?: string,
): { src: ImageProps['src']; loader: ImageLoader | undefined } => {
  const imageData = mapImage(src)

  if (!imageData) {
    return {
      src,
      loader: undefined,
    }
  }

  return getNextImageLoader(imageData.src, {
    aspectRatio: aspectRatio ?? '16:9',
  })
}
