import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ElementType, ReactNode } from 'react'
import {
  MARK_LINK,
  NODE_QUOTE,
  NODE_IMAGE,
  render,
  RenderOptions,
  StoryblokRichtext,
  NODE_PARAGRAPH,
} from 'storyblok-rich-text-react-renderer'

import components from '../bloks/richtext'
import storyblokLinkResolver from '../utils/storyblokLinkResolver'
import Link from './Link'
import MissingBlokComponent from './MissingBlokComponent'
import Image from './Image'
import {
  isSiteAmels,
  isThemeAmelsLandingspage,
  isSiteYachting,
  isSiteXplorer,
  isSiteYachtSupport,
} from '../themes'
import { RichtextStoryblok } from '../bloks/storyblok.generated'
import { secondaryOutlineButtonCssXplorer } from './Button'

const Container = styled('div')(({ theme }) => [
  css`
    // Base elements
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: ${theme.spacing.x5}px 0 0;
      ${theme.text.body(theme)}
      font-weight: 600;

      & + p {
        margin-top: 0;
      }
    }
    .h2 {
      ${theme.text.heading2(theme)}
      display: block;
      margin: ${theme.spacing.x5}px 0 ${theme.spacing.x2}px;
    }
    ul,
    ol {
      margin: ${theme.spacing.x5}px 0;
      padding-left: ${theme.spacing.x4}px;
      li {
        padding-left: ${theme.spacing.x1}px;
      }
      li > p:first-child {
        margin-top: 0;
      }
      li > p:last-child {
        margin-bottom: 0;
      }
    }

    // Custom classes
    .center {
      display: block;
      text-align: center;
    }
    .quote-author {
      color: ${theme.colors.amels.beach};
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 3.5px;
    }
  `,
  isSiteAmels(theme) &&
    css`
      h2 {
        color: ${theme.colors.amels.bayGold};

        @media screen and (min-width: ${theme.breakpoints.desktop}px) {
          font-size: 48px;
        }
      }
    `,
  isSiteYachting(theme) &&
    css`
      h2 {
        ${theme.text.heading2(theme)}
      }
      h3 {
        ${theme.text.heading3(theme)}
      }
      h4 {
        ${theme.text.heading4(theme)}
      }
    `,
  isThemeAmelsLandingspage(theme) &&
    css`
      h2 {
        ${theme.text.heading2(theme)}
        margin-bottom: ${theme.spacing.x4}px;
      }
      h3 {
        ${theme.text.heading3(theme)}
        margin-bottom: ${theme.spacing.x4}px;
      }
      h4 {
        ${theme.text.heading4(theme)}
        margin-bottom: ${theme.spacing.x4}px;
      }
    `,
  isSiteXplorer(theme) &&
    css`
      h1 {
        ${theme.text.heading1(theme)};
        margin: 0 0 ${theme.spacing.x4}px;

        @media screen and (min-width: ${theme.breakpoints.tablet}px) {
          margin: 0 0 ${theme.spacing.x5}px;
        }
      }
      h2 {
        ${theme.text.heading2(theme)}
        margin: 0 0 ${theme.spacing.x4}px;

        @media screen and (min-width: ${theme.breakpoints.tablet}px) {
          margin: 0 0 ${theme.spacing.x5}px;
        }
      }
      h3 {
        ${theme.text.heading3(theme)}
        margin: 0 0 ${theme.spacing.x4}px;

        @media screen and (min-width: ${theme.breakpoints.tablet}px) {
          margin: 0 0 ${theme.spacing.x5}px;
        }
      }
      h4 {
        ${theme.text.heading4(theme)}
        margin: 0 0 ${theme.spacing.x4}px;

        @media screen and (min-width: ${theme.breakpoints.tablet}px) {
          margin: 0 0 ${theme.spacing.x5}px;
        }
      }
      h5 {
        ${theme.text.heading5(theme)}
        margin: 0 0 ${theme.spacing.x4}px;

        @media screen and (min-width: ${theme.breakpoints.tablet}px) {
          margin: 0 0 ${theme.spacing.x5}px;
        }
      }
      h6 {
        ${theme.text.heading6(theme)}
        margin: 0 0 ${theme.spacing.x4}px;

        @media screen and (min-width: ${theme.breakpoints.tablet}px) {
          margin: 0 0 ${theme.spacing.x5}px;
        }
      }
      p {
        margin: 0 0 ${theme.spacing.x2}px;

        @media screen and (min-width: ${theme.breakpoints.tablet}px) {
          margin: 0 0 ${theme.spacing.x3}px;
        }
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      h1 {
        ${theme.text.heading1(theme)};
      }
      h2 {
        ${theme.text.heading2(theme)};
      }
      h3 {
        ${theme.text.heading3(theme)};
      }
      h4 {
        ${theme.text.heading4(theme)};
      }
      h5 {
        ${theme.text.heading5(theme)};
      }
      h6 {
        ${theme.text.heading6(theme)};
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        > .h2 {
          margin: 0;
        }
        margin: 0 0 ${theme.spacing.x4}px;

        @media screen and (min-width: ${theme.breakpoints.tablet}px) {
          margin: 0 0 ${theme.spacing.x5}px;
        }
      }
      .overlineMedium {
        ${theme.text.overLineMedium(theme)};
        color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
      }
    `,
])

const StyledLink = styled(Link)(({ theme }) => [
  isSiteYachting(theme) &&
    css`
      color: ${theme.colors.yachting.orange};

      > * {
        color: ${theme.colors.yachting.orange} !important;
      }
    `,
  isSiteXplorer(theme) &&
    css`
      ${secondaryOutlineButtonCssXplorer(theme)};

      padding: 0;
      color: ${theme.colors.xplorer.secondaryDamenYachtingOrange};
      border: none;

      :hover {
        color: ${theme.colors.xplorer.secondaryDamenYachtingOrange};

        ::before {
          background: none;
        }
      }
    `,
])

// eslint-disable-next-line react/jsx-no-useless-fragment
export const dummyResolver = (children: ReactNode) => <>{children}</>

const linkResolver = (
  children: ReactNode,
  linkData: { href?: string; target?: string; linktype?: 'email' | string },
) => {
  if (!linkData.href) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  const href = storyblokLinkResolver(linkData)

  if (!href) {
    // To avoid crashing the production app when there's a content issue, we
    // just render the link as text when the href is missing.
    const error = 'Required prop `href` is missing for link'
    // eslint-disable-next-line no-console
    console.error(error, children)
    if (process.env.NODE_ENV === 'development') {
      throw new Error(error)
    }

    // TODO: Sentry.captureException(error)

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  return (
    <StyledLink href={href} target={linkData.target}>
      {children}
    </StyledLink>
  )
}
const Quote = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.amels.bodyText};
    font-style: italic;
    max-width: 422px;
    letter-spacing: 0.5px;
  `,
)
const quoteResolver = (children: ReactNode) => <Quote>{children}</Quote>
// A p tag is not allowed within a span tag
// As we use a span tag within the h1 tag for the SimpleHeader (as a div is not allowed within a h1 tag),
// we need to convert the p tag to a span to make the html valid
const paragraphInSpanResolver = (children: ReactNode) => (
  <span style={{ display: 'block' }}>{children}</span>
)
const paragraphResolver = (children: ReactNode) => <p>{children}</p>

const imageResolver = (
  _children: ReactNode,
  props: {
    alt?: string
    title?: string
    src?: string
  },
) =>
  props.src ? (
    <Image
      src={props.src}
      alt={props.alt}
      width="0"
      height="0"
      sizes="100vw"
      style={{ width: '100%', height: 'auto' }} // Maintain original image as they are uploaded to size
      transformations={['c_limit']} // Prevent image from being "Upscaled"
    />
  ) : null

const defaultBlokResolver = (name: string, props: Record<string, unknown>) => {
  // eslint-disable-next-line security/detect-object-injection
  const Component = components[name]
  if (!Component) {
    return <MissingBlokComponent name={name} props={props} />
  }

  return <Component content={props} />
}

interface Props extends RenderOptions {
  children: StoryblokRichtext | RichtextStoryblok | undefined
  // This is literally the type from the module
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: ElementType<any> | undefined
}

const RichText = ({ children, as, ...options }: Props) => (
  <Container as={as}>
    {render(children, {
      ...options,
      defaultBlokResolver,
      markResolvers: {
        [MARK_LINK]: linkResolver,
        ...options.markResolvers,
      },
      nodeResolvers: {
        [NODE_QUOTE]: quoteResolver,
        [NODE_IMAGE]: imageResolver,
        [NODE_PARAGRAPH]:
          as === 'span' ? paragraphInSpanResolver : paragraphResolver,
        ...options.nodeResolvers,
      },
    })}
  </Container>
)

export default RichText
