import mapCloudinaryAssetName from './mapCloudinaryAssetName'

interface BynderVideo {
  id: string
  name?: string
  files: {
    original?: {
      url: string
      width: number | null
      height: number | null
      fileSize: number | null
    }
  }
  previewUrls: string[]
  databaseId: string
  description?: string
}

interface BynderVideoStoryblok {
  title?: string
  video: BynderVideo[]
  component: 'bynderVideo'
}

interface VideoData {
  src: string
  name: string
  description?: string
  id?: string | number
}

function isBynderVideo(unknown: unknown): unknown is BynderVideoStoryblok {
  return (unknown as BynderVideoStoryblok)?.video?.[0]?.files !== undefined
}

export const mapVideo = (
  video: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | Record<string, any>
    | BynderVideoStoryblok[]
    | BynderVideoStoryblok
    | undefined,
): VideoData => {
  if (!video) {
    return { src: '', name: '' }
  }

  const videoObject = Array.isArray(video) ? video[0] : video

  if (isBynderVideo(videoObject)) {
    const baseUrl = videoObject.video[0].previewUrls?.[0]

    if (!baseUrl) {
      return { src: '', name: '' }
    }

    const fileExtension = baseUrl?.split('.').pop()
    const lastSlashIndex = baseUrl.lastIndexOf('/')
    const seoTitle =
      videoObject.title && videoObject.title?.length > 1
        ? videoObject.title.replaceAll(' ', '-')
        : null
    const formattedUrl =
      seoTitle && lastSlashIndex !== -1
        ? `${baseUrl.substring(0, lastSlashIndex)}/${seoTitle}.${fileExtension}`
        : baseUrl

    return {
      src: formattedUrl,
      name: videoObject.video[0].name ?? '',
      id: videoObject.video[0].id,
    }
  }

  return {
    src:
      typeof videoObject?.filename !== 'undefined' ? videoObject?.filename : '',
    name: mapCloudinaryAssetName(videoObject),
    id: videoObject?.id,
  }
}
