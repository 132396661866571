import { css, Theme } from '@emotion/react'
import styled from '@emotion/styled'
import { MouseEvent, ReactNode, useEffect, useRef, useState } from 'react'

import { invisibleButtonCss } from './Button'
import ChevronRight from '../icons/ChevronRight.svg'
import PlusRoundedYachtSupport from '../icons/yachtsupport/PlusRounded.svg'
import MinusRoundedYachtSupport from '../icons/yachtsupport/MinusRounded.svg'
import Slant from '../icons/yachting/Slant.svg'
import ArrowRightYachting from '../icons/yachting/ArrowRight.svg'
import Link from './Link'
import useIsMobile from '../utils/useIsMobile'
import {
  isSiteAmels,
  isThemeAmelsLandingspage,
  isSiteYachting,
  isThemeYachtingWomenInYachtingLandingspage,
  isSiteXplorer,
  isSiteYachtSupport,
} from '../themes'
import { currentSite, Site } from '../sites'

const MainMenuLink = styled(Link)(({ theme }) => [
  css`
    font-size: 40px;

    :hover {
      text-decoration: none;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 72px;
    }
  `,
  isSiteAmels(theme) &&
    css`
      color: ${theme.colors.amels.deepBayAqua};
      font-family: ${theme.fonts.Zermatt.style.fontFamily};
      font-weight: normal;
      line-height: normal;
      letter-spacing: normal;
      text-decoration: none;
    `,
  isThemeAmelsLandingspage(theme) &&
    css`
      color: ${theme.colors.amels.deepBayAqua};
      font-family: ${theme.fonts.Zermatt.style.fontFamily};
      font-weight: normal;
      line-height: normal;
      letter-spacing: normal;
      text-decoration: none;
    `,
  isSiteYachting(theme) &&
    css`
      ${theme.text.heading2(theme)}
      color: ${theme.colors.yachting.white};
      transition: color ease-in-out 0.2s;

      &:hover {
        color: ${theme.colors.yachting.orange};
      }
    `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      font-family: ${theme.fonts.Georgia.style.fontFamily};
      font-size: ${theme.spacing.x5}px;
      font-weight: 400;
      line-height: 1.2;
      color: ${theme.colors.yachting.white};
      transition: color ease-in-out 0.2s;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        font-size: ${theme.spacing.x6}px;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        font-size: 60px;
      }

      &:hover {
        color: ${theme.colors.yachting.orange};
      }
    `,
  isSiteXplorer(theme) &&
    css`
      ${theme.text.heading3(theme)}
      font-size: 32px;
      font-weight: 300;
      color: ${theme.colors.xplorer.primaryXPLavaBlack};

      &:hover {
        color: ${theme.colors.xplorer.white};
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        font-size: 40px;
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      font-size: 32px;
      font-weight: 300;
      line-height: normal;
      color: ${theme.colors.yachtSupport.white};

      &:hover {
        color: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        font-size: 40px;
      }
    `,
])

const StyledArrowRightXplorer = styled.span(({ theme }) => [
  css`
    display: inline-flex;
    font-size: 32px;
    font-weight: 300;
    color: ${theme.colors.xplorer.primaryXPLavaBlack};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 40px;
    }

    :before {
      content: '>';
    }
  `,
])

export const MainMenuItemLinkWrapper = styled.div(({ theme }) => [
  css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  isSiteYachting(theme) &&
    css`
      justify-content: flex-start;
      gap: ${theme.spacing.x2}px;
    `,
  isSiteXplorer(theme) &&
    css`
      gap: ${theme.spacing.x1}px;
      color: ${theme.colors.xplorer.primaryXPLavaBlack};
      line-height: 1;
      justify-content: flex-start;
    `,
  isSiteYachtSupport(theme) &&
    css`
      gap: ${theme.spacing.x1}px;
      color: ${theme.colors.yachtSupport.white};
      line-height: 1;
      justify-content: flex-start;

      &:hover,
      &:hover * {
        color: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
      }
    `,
])
const Toggle = styled.button(({ theme }) => [
  invisibleButtonCss,
  css`
    transition: transform 400ms ease-in-out;

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      pointer-events: none;
      margin-right: ${theme.spacing.x3}px;
      margin-top: 10px;
    }
  `,
  isSiteXplorer(theme) &&
    css`
      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        display: inline-flex;
        height: 48px;
        align-items: center;
        margin-right: 0;
        margin-top: 0;
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      margin-left: auto;
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        display: inline-flex;
        height: 48px;
        align-items: center;
        margin-right: 0;
        margin-top: 0;
      }
    `,
])
const ActionIcon = styled.div(({ theme }) => [
  css`
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 300ms ease-in-out;
    transform: rotate(-90deg);

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      transform: rotate(0deg);
    }
  `,
  isSiteAmels(theme) &&
    css`
      color: ${theme.colors.amels.hazySunsetOrange};
      border: 1px solid ${theme.colors.amels.hazySunsetOrange};
    `,
  isThemeAmelsLandingspage(theme) &&
    css`
      color: ${theme.colors.amels.hazySunsetOrange};
      border: 1px solid ${theme.colors.amels.hazySunsetOrange};
    `,
  isSiteYachting(theme) &&
    css`
      color: ${theme.colors.yachting.white};
    `,
])
const ActionIconYachting = styled.div(({ theme }) => [
  isSiteYachting(theme) &&
    css`
      color: ${theme.colors.yachting.white};
    `,
])
const ActionIconXplorer = styled.div(({ theme }) => [
  isSiteYachting(theme) &&
    css`
      margin-top: 0;
      margin-right: 0;
    `,
])
const ActionIconYachtSupport = styled.div(({ theme }) => [
  isSiteYachtSupport(theme) &&
    css`
      margin-left: ${theme.spacing.x2}px;
    `,
])
const Container = styled.div(
  ({ theme }) => css`
    position: relative;

    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing.x1}px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      display: block;
      width: 50%;
    }

    ${isSiteYachting(theme) &&
    css`
      &:hover {
        ${ActionIconYachting},
        ${MainMenuLink} {
          color: ${theme.colors.yachting.orange};
        }
      }
    `};

    ${isSiteXplorer(theme) &&
    css`
      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        width: auto;
      }
    `}

    ${isSiteYachtSupport(theme) &&
    css`
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        width: auto;
      }
    `}
  `,
)
export const MainNavigationSubItems = styled.ul(({ theme }) => [
  css`
    list-style: none;
    display: none;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      ${Container}:hover & {
        display: block;
      }
    }
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      position: absolute;
      z-index: 1;
      top: 14px;
      left: 100%;
      width: 100%;
      margin-left: -50%;
      padding-left: 50%;
    }
  `,
  isSiteXplorer(theme) &&
    css`
      margin-top: ${theme.spacing.x3}px;

      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        margin-left: 0;
        margin-top: 0;
        top: -${theme.spacing.x1}px;
        padding: ${theme.spacing.x1}px 0 ${theme.spacing.x1}px 100px;
        width: auto;
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      margin-top: ${theme.spacing.x3}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        position: absolute;
        z-index: 1;
        top: 14px;
        left: 100%;
        width: 100%;
        margin-left: -50%;
        padding-left: 50%;
        margin-left: 0;
        margin-top: 0;
        top: -${theme.spacing.x2}px;
        width: auto;
        padding-left: ${theme.spacing.x3}px;
      }
    `,
])
const MainMenuSubLinkItem = styled.li(({ theme }) => [
  css`
    margin-bottom: ${theme.spacing.x2}px;

    &:first-of-type {
      margin-top: ${theme.spacing.x2}px;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: block;
    }
  `,
  isSiteXplorer(theme) &&
    css`
      margin-bottom: ${theme.spacing.x3}px;

      &:first-of-type {
        margin-top: 0;
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      margin-bottom: 0;
      padding-top: ${theme.spacing.x2}px;
      :first-of-type {
        border-top: 1px solid ${theme.colors.yachtSupport.white};
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        border-top: 1px solid ${theme.colors.yachtSupport.white};
        &:last-of-type {
          border-bottom: 1px solid ${theme.colors.yachtSupport.white};
        }
        padding-top: ${theme.spacing.x2}px;
        padding-bottom: ${theme.spacing.x2}px;
        min-width: 286px;

        :not(:first-of-type) {
          a {
            height: 60px;
            display: flex;
            align-items: center;
          }
        }
      }

      &:first-of-type {
        margin-top: 0;
      }
    `,
])
const StyledMainMenuSubLink = styled(Link)(({ theme }) => [
  css`
    font-size: 24px;
    font-weight: 300;
    line-height: normal;
    letter-spacing: normal;
    text-decoration: none;
    white-space: nowrap;

    :hover {
      text-decoration: none;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 32px;
    }
  `,
  isSiteAmels(theme) &&
    css`
      font-family: ${theme.fonts.Zermatt.style.fontFamily};
      color: ${theme.colors.amels.hazySunsetOrange};
    `,
  isThemeAmelsLandingspage(theme) &&
    css`
      font-family: ${theme.fonts.Zermatt.style.fontFamily};
      color: ${theme.colors.amels.hazySunsetOrange};
    `,
  isSiteYachting(theme) &&
    css`
      font-size: ${theme.spacing.x3}px;
      font-weight: 400;
      line-height: 1.2;
      font-family: ${theme.fonts.Georgia.style.fontFamily};
      color: ${theme.colors.yachting.white};
      transition: color ease-in-out 0.2s;

      &:hover {
        color: ${theme.colors.yachting.orange};
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        font-size: 30px;
      }
    `,
  isSiteXplorer(theme) &&
    css`
      ${theme.text.heading3(theme)}
      font-size: 32px;
      font-weight: 300;
      color: ${theme.colors.xplorer.white};
      transition: opacity ease-in-out 0.2s;

      &:hover {
        color: ${theme.colors.xplorer.white};
        opacity: 0.6;
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        font-size: 40px;
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      font-size: 32px;
      font-weight: 300;
      color: ${theme.colors.yachtSupport.white};

      &:hover {
        color: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        font-size: 40px;
        max-height: 48px;
      }
    `,
])
const StyledChevronRight = styled(ChevronRight)`
  height: 16px;
`
const StyledPlusRoundedYachtSupport = styled(PlusRoundedYachtSupport)(
  ({ theme }) => css`
    height: 32px;
    color: ${theme.colors.yachtSupport.white};

    &:hover {
      color: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
    }
  `,
)
const StyledMinusRoundedYachtSupport = styled(MinusRoundedYachtSupport)(
  ({ theme }) => css`
    height: 32px;
    color: ${theme.colors.yachtSupport.white};

    &:hover {
      color: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
    }
  `,
)
const StyledArrowRightYachting = styled(ArrowRightYachting)(
  ({ theme }) => css`
    height: 23px;
    transition: transform 300ms ease-in-out;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      height: 28px;
    }
  `,
)
const StyledSlantYachting = styled(Slant)(
  ({ theme }) => css`
    height: 50px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      height: 66px;
    }
  `,
)

const ToggleIcon = ({ toggled }: { toggled?: boolean }) => {
  switch (currentSite) {
    case Site.Amels:
      return (
        <ActionIcon>
          <StyledChevronRight aria-label={toggled ? 'Collapse' : 'Expand'} />
        </ActionIcon>
      )
    case Site.Xplorer:
      return (
        <ActionIconXplorer>
          <StyledArrowRightXplorer />
        </ActionIconXplorer>
      )
    case Site.YachtSupport:
      return (
        <ActionIconYachtSupport>
          {toggled ? (
            <StyledMinusRoundedYachtSupport
              aria-label={toggled ? 'Collapse' : 'Expand'}
            />
          ) : (
            <StyledPlusRoundedYachtSupport
              aria-label={toggled ? 'Collapse' : 'Expand'}
            />
          )}
        </ActionIconYachtSupport>
      )
    default:
      return (
        <ActionIconYachting>
          <StyledSlantYachting />
          <StyledArrowRightYachting />
        </ActionIconYachting>
      )
  }
}

const toggledCss = (_theme: Theme) => css`
  ${ActionIcon} {
    transform: rotate(90deg);
  }
  ${StyledArrowRightYachting} {
    transform: rotate(90deg);
  }
  ${MainNavigationSubItems} {
    display: block;
  }
`

const pointAtLink = false

interface Props {
  children: ReactNode
  href: string
  subItems?: Array<{
    key: string
    title: string
    href: string
  }>
}

const SiteNavigationMainMenuItem = ({
  children,
  href,
  subItems,
  ...others
}: Props) => {
  const [toggled, setIsToggled] = useState(false)
  const [hoverSubMenuItem, setHoverSubMenuItem] = useState<
    HTMLElement | undefined
  >(undefined)

  const handleMouseEnter = (e: MouseEvent) => {
    setHoverSubMenuItem(e.target as HTMLElement)
  }
  const handleMouseLeave = () => {
    setHoverSubMenuItem(undefined)
  }

  const toggleButtonRef = useRef<HTMLButtonElement | null>(null)
  const isMobile = useIsMobile()

  useEffect(() => {
    const elem = toggleButtonRef.current
    if (!elem) {
      return
    }
    if (!hoverSubMenuItem || isMobile) {
      elem.style.transform = `translateY(0px)`
      return
    }

    const p1 = elem.getBoundingClientRect()
    const p2 = hoverSubMenuItem.getBoundingClientRect()

    if (pointAtLink) {
      const angleDeg =
        (Math.atan2(
          p2.y + p2.height / 2 - (p1.y + p1.height / 2),
          p2.x - (p1.x + p1.width / 2),
        ) *
          180) /
        Math.PI

      elem.style.transform = `rotate(${angleDeg}deg)`
    } else {
      // Reset temporarily to get correct measurements
      elem.style.transition = `none`
      const oldTransform = elem.style.transform
      elem.style.transform = `translateY(0px)`
      const p1 = elem.getBoundingClientRect()
      elem.style.transform = oldTransform
      // This is to force a repaint so CSS transitions are applied.
      // See: https://github.com/reactjs/react-transition-group/blob/85016bfddd3831e6d7bb27926f9f178d25502913/src/CSSTransition.js#L197
      // eslint-disable-next-line no-unused-expressions
      elem.scrollTop
      elem.style.transform = `translateY(${p2.y - p1.y + 2}px)`
      elem.style.transition = `transform 400ms ease-in-out`
    }
  }, [hoverSubMenuItem, isMobile])

  if (!subItems || subItems.length === 0) {
    return (
      <MainMenuLink href={href as string} {...others}>
        {children}
      </MainMenuLink>
    )
  }

  return (
    <Container
      css={toggled ? toggledCss : undefined}
      onMouseLeave={handleMouseLeave}
      {...others}
    >
      <MainMenuItemLinkWrapper>
        <MainMenuLink href={href as string}>{children}</MainMenuLink>
        <Toggle onClick={() => setIsToggled(!toggled)} ref={toggleButtonRef}>
          <ToggleIcon toggled={toggled} />
        </Toggle>
      </MainMenuItemLinkWrapper>
      <MainNavigationSubItems>
        {subItems.map(({ key, href, title }) => (
          <MainMenuSubLinkItem key={key}>
            {currentSite === Site.Amels ? (
              <StyledMainMenuSubLink
                href={href}
                onMouseEnter={handleMouseEnter}
              >
                {title}
              </StyledMainMenuSubLink>
            ) : (
              <StyledMainMenuSubLink href={href}>{title}</StyledMainMenuSubLink>
            )}
          </MainMenuSubLinkItem>
        ))}
      </MainNavigationSubItems>
    </Container>
  )
}

export default SiteNavigationMainMenuItem
