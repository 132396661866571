// Here we configure the loader used in our Image component,
// we want to use the types from next/image
// eslint-disable-next-line no-restricted-imports
import { getImageProps } from 'next/image'
import { ImageProps, ImgProps } from 'next/dist/shared/lib/get-img-props'
import { getNextImageLoader } from './getNextImageLoader'

// We get the src and alt from the image prop, so we don't need them here
// We also don't need the loader prop as we are using our own
type CustomImageProps = Omit<ImageProps, 'src' | 'alt' | 'loader'>

export const getNextImageProps = ({
  image,
  aspectRatio,
  imageProps,
}: {
  image?: {
    src: string
    alt?: string
  }
  aspectRatio?: string
  imageProps?: CustomImageProps
}): ImgProps => {
  const {
    props: { srcSet, ...rest },
  } = getImageProps({
    ...imageProps,
    ...getNextImageLoader(image?.src ?? '', { aspectRatio }),
    alt: image?.alt ?? '',
  })

  return { srcSet, ...rest }
}
