// This is the only place we want to use next/image as this correctly wraps
// next/image to handle/force the user to use relative cloudinary image urls
// but still support additional transforms via the loader infrastructure
// eslint-disable-next-line no-restricted-imports
import NextImage, { ImageProps } from 'next/image'

import styled from '@emotion/styled'
import { getNextImageLoader } from '../utils/getNextImageLoader'

type Props = Omit<ImageProps, 'loader' | 'alt'> &
  Partial<Pick<ImageProps, 'alt'>> & {
    aspectRatio?: string // must be W:H (can be absurd values like 526:426)
    transformations?: string[]
  }

const StyledImage = styled(NextImage)`
  pointer-events: none;
`

const Image = ({ src, transformations, aspectRatio, ...others }: Props) => {
  // The Next.JS loader infrastructure always expects a 'relative' url so we override it in case of a custom loader
  // When its the default Next.JS loader we always supply the full url so Next/Image can proxy the request
  const { src: overridenSrc, loader } = getNextImageLoader(src, {
    aspectRatio,
    transformations,
    ...others,
  })

  // eslint-disable-next-line jsx-a11y/alt-text
  return (
    <StyledImage
      {...others}
      alt={others.alt ?? ''}
      src={overridenSrc}
      loader={loader}
    />
  )
}

export default Image
