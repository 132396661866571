import styled from '@emotion/styled'
import { ComponentProps, forwardRef, ReactNode } from 'react'

import { usePageContext } from '../bloks/PageContext'
import SectionIndicator from './SectionIndicator'

const Container = styled.section`
  position: relative;
  overflow: hidden;
`

interface Props extends Omit<ComponentProps<typeof Container>, 'children'> {
  children: ReactNode
  sectionIndicatorVariant:
    | 'none'
    | ComponentProps<typeof SectionIndicator>['variant']
  /**
   * Set to true for a single section per page, to make the menu visible to
   * screen readers without it appearing repetitively.
   */
  sectionIndicatorAriaVisible?: boolean
}

const Section = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      sectionIndicatorAriaVisible,
      sectionIndicatorVariant,
      ...others
    }: Props,
    ref,
  ) => {
    const { SectionIndicator } = usePageContext()

    return (
      <Container ref={ref} {...others}>
        {children}

        {SectionIndicator && sectionIndicatorVariant !== 'none' && (
          <SectionIndicator
            variant={sectionIndicatorVariant}
            aria-hidden={!sectionIndicatorAriaVisible}
          />
        )}
      </Container>
    )
  },
)
Section.displayName = 'Section'

export default Section
