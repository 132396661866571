import { ComponentProps } from 'react'

import SiteNavigation from '../components/SiteNavigation'
import storyblokLinkResolver from '../utils/storyblokLinkResolver'
import { SiteStoryblok } from './storyblok.generated'

interface Props
  extends Omit<
    ComponentProps<typeof SiteNavigation>,
    'mainNavigation' | 'subNavigation' | 'socials' | 'legalNavigation'
  > {
  siteStory: SiteStoryblok
}

const SiteNavigationBlok = ({
  siteStory: {
    mainNavigationBlocks,
    subNavigationBlocks,
    socialNavigationBlocks,
    legalNavigationBlocks,
    menuCloseTitle,
  },
  ...others
}: Props) => (
  <SiteNavigation
    mainNavigation={mainNavigationBlocks.map(
      ({ _uid, codeId, title, link, subNavigationBlocks }) => ({
        key: _uid,
        id: codeId,
        title,
        href: storyblokLinkResolver(link),
        subItems: subNavigationBlocks?.map(({ _uid, title, link }) => ({
          key: _uid,
          title,
          href: storyblokLinkResolver(link),
        })),
      }),
    )}
    subNavigation={subNavigationBlocks?.map(({ _uid, title, link }) => ({
      key: _uid,
      title,
      href: storyblokLinkResolver(link),
    }))}
    legalNavigation={legalNavigationBlocks?.map(({ _uid, title, link }) => ({
      key: _uid,
      title,
      href: storyblokLinkResolver(link),
    }))}
    socials={socialNavigationBlocks?.map(({ _uid, icon, link }) => ({
      key: _uid,
      icon,
      href: storyblokLinkResolver(link),
    }))}
    menuCloseTitle={menuCloseTitle}
    {...others}
  />
)

export default SiteNavigationBlok
