/**
 * Remove props with undefined value from an object. Useful when JSON.encode-ing
 * an object.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const stripUndefined = <Input extends Record<string, any | undefined>>(
  input: Input,
) =>
  // Source: https://stackoverflow.com/a/38340374/684353
  Object.keys(input).reduce<Record<string, unknown>>((output, key) => {
    // eslint-disable-next-line security/detect-object-injection
    if (input[key] !== undefined) {
      // eslint-disable-next-line security/detect-object-injection,no-param-reassign
      output[key] = input[key]
    }
    return output
  }, {}) as {
    [K in keyof Input]-?: NonNullable<Input[K]>
  }

export default stripUndefined
