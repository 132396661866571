import styled from '@emotion/styled'

const RecaptchaContainer = styled.div`
  display: none;

  .grecaptcha-badge {
    visibility: hidden;
  }
`

export default RecaptchaContainer
