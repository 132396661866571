import { useRouter } from 'next/router'
import { ComponentProps, useEffect } from 'react'

import ModalFixed from '../components/ModalFixed'
import PortfolioNavigationTransition from '../components/PortfolioNavigationTransition'
import SiteNavigationTransition from '../components/SiteNavigationTransition'
import { makeVar, useReactiveVar } from '../utils/reactiveVar'
import NavigationBarBlok from './NavigationBarBlok'
import PortfolioNavigationBlok from './PortfolioNavigationBlok'
import SiteNavigationBlok from './SiteNavigationBlok'
import { showNavigationBarVar } from '../components/StickyNavigationBar'
import { SiteStoryblok } from './storyblok.generated'
import TopBarBlok from './patterns/TopBarBlok'

export const isSiteNavigationMenuOpenVar = makeVar(false)
export const isPortfolioMenuOpenVar = makeVar(false)

interface Props2 {
  siteStory: SiteStoryblok
}

const SiteNavigation = ({ siteStory }: Props2) => {
  const { asPath, push } = useRouter()
  const hash = asPath.split('#')[1]
  const isSiteNavigationMenuOpen = useReactiveVar(isSiteNavigationMenuOpenVar)
  // Needed this way for hydration since this is client-side only
  useEffect(() => {
    isSiteNavigationMenuOpenVar(hash === 'menu')
  }, [hash])
  const isPortfolioMenuOpen = useReactiveVar(isPortfolioMenuOpenVar)
  // Needed this way for hydration since this is client-side only
  useEffect(() => {
    isPortfolioMenuOpenVar(hash === 'portfolio')
  }, [hash])

  return (
    <>
      <SiteNavigationTransition isOpen={isSiteNavigationMenuOpen}>
        <ModalFixed>
          <SiteNavigationBlok
            close={() => {
              push(asPath.split('#')[0], undefined, {
                scroll: false,
              })
              showNavigationBarVar(true)
            }}
            siteStory={siteStory}
          />
        </ModalFixed>
      </SiteNavigationTransition>
      <PortfolioNavigationTransition isOpen={isPortfolioMenuOpen}>
        <ModalFixed>
          <PortfolioNavigationBlok
            close={() => {
              push(asPath.split('#')[0], undefined, {
                scroll: false,
              })
            }}
            siteStory={siteStory}
          />
        </ModalFixed>
      </PortfolioNavigationTransition>
    </>
  )
}

interface Props {
  siteStory: SiteStoryblok
  variant: ComponentProps<typeof NavigationBarBlok>['variant']
}

const HeaderBlok = ({ siteStory, variant, ...others }: Props) => (
  <>
    <TopBarBlok siteStory={siteStory} />
    <NavigationBarBlok siteStory={siteStory} variant={variant} {...others} />

    <SiteNavigation siteStory={siteStory} />
  </>
)

export default HeaderBlok
