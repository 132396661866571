import { css } from '@emotion/react'
import { StyledComponent } from '@emotion/styled'
import { ReactNode, useEffect, useState } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import squarePolygon from '../utils/squarePolygon'
import { hiddenClassName } from './AnimateText'

const transitionDuration = 800
const transitionName = 'side-panel-transition'
const makeBackdropTransition = (
  backdropComponent: StyledComponent<Record<string, unknown>>,
) => css`
  &.${transitionName}-enter {
    ${backdropComponent} {
      clip-path: ${squarePolygon(0, 1, 0, 0)};
    }
  }
  &.${transitionName}-enter-active {
    ${backdropComponent} {
      clip-path: ${squarePolygon()};
      transition: clip-path ${transitionDuration}ms ease-in-out;
    }
  }
  &.${transitionName}-exit {
    ${backdropComponent} {
      clip-path: ${squarePolygon()};
    }
  }
  &.${transitionName}-exit-active {
    ${backdropComponent} {
      clip-path: ${squarePolygon(0, 1, 0, 0)};
      transition: clip-path ${transitionDuration}ms ease-in-out;
    }
  }
`
const makeModalTransition = (
  modalComponent: StyledComponent<Record<string, unknown>>,
) => css`
  &.${transitionName}-enter {
    ${modalComponent} {
      clip-path: ${squarePolygon(0, 0, 0, 1)};
    }
  }
  &.${transitionName}-enter-active {
    ${modalComponent} {
      clip-path: ${squarePolygon()};
      transition: clip-path ${transitionDuration}ms ease-in-out;
    }
  }
  &.${transitionName}-exit {
    ${modalComponent} {
      clip-path: ${squarePolygon()};
    }
  }
  &.${transitionName}-exit-active {
    ${modalComponent} {
      clip-path: ${squarePolygon(0, 0, 0, 1)};
      transition: clip-path ${transitionDuration}ms ease-in-out;
    }
  }
`

interface Props {
  children: ReactNode
  isOpen: boolean
  backdropComponent: StyledComponent<Record<string, unknown>>
  modalComponent: StyledComponent<Record<string, unknown>>
}

const ModalTransition = ({
  children,
  isOpen,
  backdropComponent,
  modalComponent,
}: Props) => {
  const [isTransitioning, setIsTransitioning] = useState(false)
  useEffect(() => {
    if (!isOpen) {
      return
    }
    setIsTransitioning(true)
    const handle = setTimeout(() => {
      setIsTransitioning(false)
    }, transitionDuration)
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(handle)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transitionDuration, isOpen])

  return (
    <TransitionGroup component={null}>
      {isOpen && (
        <CSSTransition classNames={transitionName} timeout={transitionDuration}>
          <div
            css={[
              makeBackdropTransition(backdropComponent),
              makeModalTransition(modalComponent),
            ]}
          >
            <div className={isTransitioning ? hiddenClassName : undefined}>
              {children}
            </div>
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}

export default ModalTransition
