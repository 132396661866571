import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

import Link from '../../components/Link'
import SocialIcon from '../../components/SocialIcon'
import { currentSite } from '../../sites'
import Logo from '../../components/Logo'
import {
  isSiteYachting,
  isThemeYachtingWomenInYachtingLandingspage,
} from '../../themes'
import { FooterProps } from '.'

const Container = styled.footer(
  ({ theme }) => css`
    position: relative;
    padding: ${theme.spacing.x8}px ${theme.spacing.x4}px;
    background: ${theme.colors.amels.deepBayAqua};
    color: ${theme.colors.amels.silk};
    border-top: 4px solid ${theme.colors.amels.hazySunsetOrange};

    ${isSiteYachting(theme) &&
    css`
      background: ${theme.colors.yachting.darkBlue};
      color: ${theme.colors.yachting.white};
      border-top: 8px solid ${theme.colors.yachting.beach};

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          to bottom,
          rgba(7, 49, 75, 0) 0%,
          rgba(0, 0, 0, 1) 100%
        );
        clip-path: polygon(0 0, 100% 0, 100% 36%, 0% 100%);
      }
    `};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x15}px ${theme.spacing.x8}px;

      &::after {
        top: 0;
        width: 50%;
        clip-path: polygon(0 0, 100% 0%, calc(50vw - 263px) 100%, 0% 100%);
      }
    }
  `,
)
const Row = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    gap: ${theme.spacing.x4}px ${theme.spacing.x15}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex-flow: row;
    }

    ${isSiteYachting(theme) &&
    css`
      position: relative;
      z-index: 1;
      gap: ${theme.spacing.x8}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        gap: ${theme.spacing.x11}px;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        gap: ${theme.spacing.x4}px;
      }
    `}
  `,
)
const LeftColumn = styled.div(
  ({ theme }) => css`
    flex: 1 1 33%;

    ${isSiteYachting(theme) &&
    css`
      flex: 1 1 25%;
    `}
  `,
)
const RightColumn = styled.div(
  ({ theme }) => css`
    flex: 1 1 66%;

    ${isSiteYachting(theme) &&
    css`
      flex: 1 1 75%;
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing.x5}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        flex-direction: column;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        flex-direction: row;
      }
    `}
  `,
)
const Title = styled.div(
  ({ theme }) => css`
    font-weight: 600;

    ${isSiteYachting(theme) &&
    css`
      ${theme.text.heading4(theme)}
      color: ${theme.colors.yachting.silver30};
    `}
    ${isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      font-family: ${theme.fonts.Georgia.style.fontFamily}, sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.2;
      color: ${theme.colors.yachting.silver30};

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        font-size: 20px;
      }
    `}
  `,
)
const LogoSmall = styled(Logo.Simple)(
  ({ theme }) => css`
    width: 135px;
    margin-bottom: ${theme.spacing.x8}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-bottom: ${theme.spacing.x3}px;
    }

    ${isSiteYachting(theme) &&
    css`
      margin-bottom: ${theme.spacing.x3}px;
    `}
  `,
)
const Navigation = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    gap: ${theme.spacing.x4}px;
    line-height: 1.75;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: grid;
      grid-auto-columns: minmax(0, 1fr);
      grid-auto-flow: column;
    }

    ${isSiteYachting(theme) &&
    css`
      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        width: 66%;
      }

      @media screen and (max-width: ${theme.breakpoints.mobileMax}px) {
        gap: ${theme.spacing.x5}px;
      }
    `}
  `,
)
const NavigationColumn = styled.menu(
  ({ theme }) => css`
    list-style: none;
    margin: 0;
    padding: 0;

    ${isSiteYachting(theme) &&
    css`
      font-size: 14px;

      > li:not(:last-of-type) {
        margin-bottom: ${theme.spacing.x2}px;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        font-size: 20px;
      }
    `}
  `,
)
const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`
const SocialLinks = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-top: ${theme.spacing.x15}px;
    }

    ${isSiteYachting(theme) &&
    css`
      margin-top: ${theme.spacing.x11}px;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        margin-top: ${theme.spacing.x11}px;
      }
    `}
  `,
)
const StyledSocialIcon = styled(SocialIcon)(
  ({ theme }) => css`
    height: ${theme.spacing.x4}px;
    margin-right: ${theme.spacing.x1}px;
    color: ${theme.colors.amels.shell};
    transition: all 150ms;

    :hover {
      color: ${theme.colors.amels.beach};
    }

    ${isSiteYachting(theme) &&
    css`
      height: 20px;
      margin-right: ${theme.spacing.x3}px;
      color: ${theme.colors.yachting.white};

      :hover {
        color: ${theme.colors.yachting.orange};
      }
    `}
  `,
)
const Legal = styled(Row)(
  ({ theme }) => css`
    font-size: 12px;
    letter-spacing: 3.43px;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: ${theme.spacing.x4}px;
    flex-flow: column-reverse;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex-flow: row;
      margin-top: ${theme.spacing.x7}px;
    }

    ${isSiteYachting(theme) &&
    css`
      font-size: 12px;
      font-weight: 400;
      text-transform: none;
      color: ${theme.colors.yachting.silver};
      margin-top: ${theme.spacing.x8}px;
      row-gap: ${theme.spacing.x8}px;
      flex-flow: column;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-top: ${theme.spacing.x11}px;
        row-gap: ${theme.spacing.x8}px;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        row-gap: ${theme.spacing.x2}px;
        align-items: center;
      }

      ${SocialLinks} {
        margin-top: 0;
      }
    `}
  `,
)
const LegalLinks = styled.menu(
  ({ theme }) => css`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: ${theme.spacing.x4}px ${theme.spacing.x2}px;
    flex-flow: column;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex-flow: row;

      > li:not(:first-of-type)::before {
        content: '·';
        margin-right: ${theme.spacing.x2}px;

        ${isSiteYachting(theme) &&
        css`
          content: '';
          margin-right: 0;
        `}
      }
    }

    ${isSiteYachting(theme) &&
    css`
      flex-flow: row wrap;
      gap: ${theme.spacing.x1}px ${theme.spacing.x3}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        width: 66%;
      }
    `}
  `,
)
const LogoFootnote = styled.div(
  ({ theme }) => css`
    display: block;
    max-width: 135px;
    color: ${theme.colors.yachting.beach};
    text-align: center;
    font-size: 14px;
  `,
)

const YachtingNewsletter = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      width: 33%;
    }
  `,
)
const Copyright = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      width: 33%;
    }
  `,
)

const Footer = ({
  newsletterForm,
  columns,
  socials,
  copyright,
  legalLinks,
  logoFootnote,
  ...others
}: FooterProps) => {
  const renderNavigation = ({
    columns,
    forceTitleRow,
  }: {
    columns: FooterProps['columns']
    forceTitleRow?: boolean
  }) => (
    <Navigation {...others}>
      {columns?.map(({ key, title, links }) => (
        <NavigationColumn key={key}>
          {(title || forceTitleRow) && (
            <li>
              <Title>{title || <>&nbsp;</>}</Title>
            </li>
          )}
          {links.map(({ key, title, href, target }) => (
            <li key={key}>
              <StyledLink href={href} target={target || undefined}>
                {title}
              </StyledLink>
            </li>
          ))}
        </NavigationColumn>
      ))}
    </Navigation>
  )

  const socialLinks = (
    <SocialLinks>
      {socials?.map(({ key, icon, href, target }) => (
        <Link key={key} href={href} target={target || undefined}>
          <StyledSocialIcon icon={icon} aria-label={icon} />
        </Link>
      ))}
    </SocialLinks>
  )

  return (
    <Container>
      <Row>
        <LeftColumn>
          {currentSite === 'yachting' ? (
            <>
              <LogoSmall data-testid="footer-logo" />
              {logoFootnote && <LogoFootnote>{logoFootnote}</LogoFootnote>}
            </>
          ) : (
            newsletterForm
          )}
        </LeftColumn>
        <RightColumn>
          {currentSite === 'yachting' ? (
            <>
              {renderNavigation({
                columns,
                forceTitleRow: true,
              })}
              <YachtingNewsletter>{newsletterForm}</YachtingNewsletter>
            </>
          ) : (
            <>
              {renderNavigation({ columns, forceTitleRow: true })}
              {socialLinks}
            </>
          )}
        </RightColumn>
      </Row>
      <Legal>
        <LeftColumn>
          {currentSite === 'yachting' ? socialLinks : copyright}
        </LeftColumn>
        <RightColumn>
          <LegalLinks>
            {legalLinks?.map(({ key, title, href, target }) => (
              <li key={key}>
                {href ? (
                  <StyledLink href={href} target={target || undefined}>
                    {title}
                  </StyledLink>
                ) : (
                  title
                )}
              </li>
            ))}
          </LegalLinks>
          {currentSite === 'yachting' && <Copyright>{copyright}</Copyright>}
        </RightColumn>
      </Legal>
    </Container>
  )
}

export default Footer
