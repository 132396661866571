/* eslint-disable no-param-reassign */
import { MouseEvent as ReactMouseEvent, MutableRefObject } from 'react'

const useVideoSeekBar = (
  videoRef: MutableRefObject<HTMLVideoElement | null>,
  seekBarRef: MutableRefObject<HTMLButtonElement | null>,
) => {
  const handleMouseMove = (e: MouseEvent) => {
    const elem = seekBarRef.current
    if (!elem) {
      return
    }
    const targetRect = elem.getBoundingClientRect()
    const percentage = (e.clientX - targetRect.left) / targetRect.width
    if (videoRef.current) {
      videoRef.current.currentTime = percentage * videoRef.current.duration
    }
  }
  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove)
    document.removeEventListener('mouseup', handleMouseUp)
  }
  const handleSeekBarMouseDown = (e: ReactMouseEvent) => {
    const elem = seekBarRef.current
    if (!elem) {
      return
    }
    const targetRect = elem.getBoundingClientRect()
    const percentage = (e.clientX - targetRect.left) / targetRect.width
    if (videoRef.current) {
      videoRef.current.currentTime = percentage * videoRef.current.duration
    }
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
  }

  return handleSeekBarMouseDown
}

export default useVideoSeekBar
