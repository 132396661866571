import styled from '@emotion/styled'
import Image from '../../components/Image'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'
import { BynderImageStoryblok } from '../storyblok.generated'

interface Props {
  content: BynderImageStoryblok
}

const ImageWrapper = styled.span`
  // Image position is absolute and could not be adjusted without adding !important
  img {
    position: relative !important;
  }
`

const RichTextBynderImageBlok = ({ content, ...others }: Props) => {
  const image = mapImage(content) ?? getRequiredImageFallback()

  return (
    <ImageWrapper>
      <Image src={image.src} alt={image.alt} fill {...others} />
    </ImageWrapper>
  )
}

export default RichTextBynderImageBlok
