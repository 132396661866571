// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapCloudinaryAssetName = (asset: Record<string, any>): string => {
  if (asset?.alt && asset.alt.length > 0) {
    return asset.alt
  }
  if (asset?.title && asset.title.length > 0) {
    return asset.title
  }
  if (asset?.name && asset.name.length > 0) {
    return asset.name
  }

  return /[^/]*$/.exec(asset.filename)?.[0] ?? ''
}

export default mapCloudinaryAssetName
