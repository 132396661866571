/* eslint-disable security/detect-object-injection */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps } from 'react'
import { isSiteAmels, isSiteYachting } from '../themes'
import Table from './Table'

const StyledTable = styled(Table)(
  ({ theme }) => css`
    display: none;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: table;
    }
  `,
)
const MobileTable = styled.table(
  ({ theme }) => css`
    width: 100%;

    :not(:last-of-type) {
      margin-bottom: ${theme.spacing.x5}px;
    }

    td,
    th {
      text-align: left;
      font-weight: 400;
      padding: ${theme.spacing.x2}px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: none;
    }
  `,
)
const MobileTableTitle = styled.th(({ theme }) => [
  css`
    color: ${theme.colors.yachting.darkBlue};
    border-bottom: 1px solid ${theme.colors.yachting.darkBlue};
  `,
  isSiteAmels(theme) &&
    css`
      border-bottom-color: ${theme.colors.amels.bayGold};
    `,
])
const MobileTableLabel = styled.th(({ theme }) => [
  css`
    && {
      font-weight: 300;
    }
  `,
  isSiteYachting(theme) &&
    css`
      color: ${theme.colors.yachting.darkBlue};
    `,
])

type Props = ComponentProps<typeof Table>

const ResponsiveTable = ({ head, rows, ...others }: Props) => (
  <>
    <StyledTable head={head} rows={rows} {...others} />

    {rows.map((row) => (
      <MobileTable key={row.key}>
        {head[0] && rows[0] && (
          <thead>
            <tr key={head[0].key}>
              <MobileTableTitle colSpan={2}>
                {rows[0].cells[0]?.content}
              </MobileTableTitle>
            </tr>
          </thead>
        )}
        <tbody>
          {head.map((cell, index) => {
            if (index === 0) {
              return null
            }

            return (
              <tr key={cell.key}>
                <MobileTableLabel>{cell.content}</MobileTableLabel>
                <td>{row.cells[index]?.content}</td>
              </tr>
            )
          })}
        </tbody>
      </MobileTable>
    ))}
  </>
)

export default ResponsiveTable
