import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, forwardRef } from 'react'

import FormFieldCheckbox from './FormFieldCheckbox'
import { isSiteAmels, isSiteXplorer, isSiteYachtSupport } from '../themes'

const StyledFormFieldCheckbox = styled(FormFieldCheckbox)(({ theme }) => [
  css`
    a {
      color: inherit;
      text-decoration: underline;
    }
  `,
  isSiteAmels(theme) &&
    css`
      color: ${theme.colors.amels.beach};
      text-transform: uppercase;
      letter-spacing: 3.43px;
      font-size: 12px;
    `,
  isSiteXplorer(theme) &&
    css`
      color: ${theme.colors.xplorer.secondaryDamenYachtingOrange};
      font-size: 16px;
    `,
  isSiteYachtSupport(theme) &&
    css`
      color: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
      font-size: 16px;
    `,
])

type Props = ComponentProps<typeof FormFieldCheckbox>

// eslint-disable-next-line react/display-name
const FormFieldConditionsCheckbox = forwardRef<HTMLInputElement, Props>(
  (props: Props, ref) => <StyledFormFieldCheckbox {...props} ref={ref} />,
)

export default FormFieldConditionsCheckbox
