const makeBynderImageUrl = ({
  src,
  transformations = [],
}: {
  src: string
  transformations?: string[]
}) => {
  const combinedTransformations = [transformations.join(',')].filter((x) => x)
  const quality = process.env.NEXT_PUBLIC_IMAGE_QUALITY ?? 80

  return `${src}?io=${combinedTransformations}&quality=${quality}`
}

export default makeBynderImageUrl
