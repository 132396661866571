import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { FooterProps } from './index'
import Link from '../../components/Link'
import SocialIcon from '../../components/SocialIcon'
import Logo from '../../components/Logo'
import { isLinkReferringToCurrentSite } from '../../utils/isLinkReferringToCurrentSite'

const Wrapper = styled.footer(
  ({ theme }) => css`
    padding: ${theme.spacing.x8}px ${theme.spacing.x3}px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${theme.spacing.x8}px;
    background: ${theme.colors.yachtSupport.primaryYSCarbonBlack};
    color: ${theme.colors.yachtSupport.white};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      padding: ${theme.spacing.x15}px ${theme.spacing.x10}px;
    }
  `,
)

const Container = styled.div(
  ({ theme }) =>
    css`
      display: grid;
      gap: ${theme.spacing.x8}px;
      width: 100%;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        gap: ${theme.spacing.x7}px;
      }

      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        grid-template-columns: 1fr 392px;
        gap: ${theme.spacing.x8}px;
        margin: 0 auto;
        max-width: 1280px;
      }

      @media screen and (min-width: ${theme.breakpoints
          .desktop}px) and (max-width: ${theme.breakpoints.desktopLarge}px) {
        .newsletter-form {
          max-width: 556px;
        }
      }
    `,
)

const BottomContainer = styled(Container)(
  ({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      grid-template-columns: 1fr 1fr;
      margin-top: ${theme.spacing.x7}px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      margin-top: ${theme.spacing.x15}px;
    }
  `,
)

const Navigation = styled.div(
  ({ theme }) =>
    css`
      display: grid;
      grid-row-gap: ${theme.spacing.x8}px;
      align-items: start;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: ${theme.spacing.x7}px;
      }

      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        grid-template-columns: 1fr 1fr 1fr 48px;
      }
    `,
)

const StyledSocialIcon = styled(SocialIcon)(
  ({ theme }) => css`
    height: ${theme.spacing.x3}px;
    color: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
    transition: all 150ms;

    :hover {
      color: ${theme.colors.yachtSupport.white};
    }
  `,
)

const StyledLogo = styled(Logo.Simple)(
  ({ theme }) => css`
    width: 292px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      width: 360px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      width: 400px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      margin-bottom: ${theme.spacing.x8}px;
    }
  `,
)

const Menu = styled.menu(
  ({ theme }) => css`
    list-style: none;
    display: grid;
    align-items: start;
    gap: ${theme.spacing.x3}px;
    margin: 0;
    padding: 0;
  `,
)

const MenuItem = styled.li(
  css`
    line-height: 1;
  `,
)

const MenuItemLink = styled.a(
  ({ theme }) =>
    css`
      font-family: ${theme.fonts.Rubik.style.fontFamily};
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5; /* 142.857% */
      letter-spacing: 0.7px;
      text-transform: uppercase;
      color: ${theme.colors.yachtSupport.white};
    `,
)

const SitesMenuItemLink = styled(MenuItemLink)<{ isActive?: boolean }>(
  ({ theme, isActive }) => [
    css`
      color: ${theme.colors.yachtSupport.white};
    `,
    isActive &&
      css`
        color: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
        pointer-events: none;
      `,
  ],
)

const SocialLinks = styled.menu(
  ({ theme }) => css`
    list-style: none;
    display: flex;
    gap: ${theme.spacing.x3}px;
    margin: 0;
    padding: 0;

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      text-align: center;
      justify-content: center;
      flex-direction: column;
    }
  `,
)

const LegalLinks = styled.menu(
  ({ theme }) => css`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      justify-content: end;
    }
  `,
)

const LegalLinksItem = styled.li(
  css`
    line-height: 1;
    display: flex;
  `,
)

const StyledLink = styled(Link)(
  ({ theme }) =>
    css`
      color: inherit;
      text-decoration: none;
      font-family: ${theme.fonts.Rubik.style.fontFamily};
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.6px;
      text-transform: uppercase;

      &:hover {
        text-decoration: underline;
      }
    `,
)

const StyledLinkDelimeter = styled.div(
  ({ theme }) => css`
    width: 1px;
    background: ${theme.colors.yachtSupport.white};
    margin: 0 ${theme.spacing.x1}px;
  `,
)

const Copyright = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.yachtSupport.white};
    font-family: ${theme.fonts.Rubik.style.fontFamily};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
  `,
)

const FooterYachtSupport = (props: FooterProps) => {
  const { copyright, columns, socials, legalLinks, newsletterForm, ...others } =
    props
  return (
    <Wrapper {...others}>
      <Container>
        <StyledLogo />
      </Container>
      <Container>
        <Navigation>
          {columns?.map(({ key, codeId, links }) => {
            const isSiteLinks = codeId === 'sites'
            return (
              <Menu key={key}>
                {links.map(({ key, title, href, target }) =>
                  isSiteLinks ? (
                    <MenuItem key={key}>
                      <SitesMenuItemLink
                        href={href}
                        target={target || undefined}
                        isActive={isLinkReferringToCurrentSite(href)}
                      >
                        {title}
                        {' >'}
                      </SitesMenuItemLink>
                    </MenuItem>
                  ) : (
                    <MenuItem key={key}>
                      <MenuItemLink href={href} target={target || undefined}>
                        {title}
                      </MenuItemLink>
                    </MenuItem>
                  ),
                )}
              </Menu>
            )
          })}
          <SocialLinks>
            {socials?.map(({ key, icon, href, target }) => (
              <Link key={key} href={href} target={target || undefined}>
                <StyledSocialIcon icon={icon} aria-label={icon} />
              </Link>
            ))}
          </SocialLinks>
        </Navigation>
        <div className="newsletter-form">{newsletterForm}</div>
      </Container>
      <BottomContainer>
        <Copyright>{copyright}</Copyright>
        <LegalLinks>
          {legalLinks?.map(({ key, title, href, target }, index) => (
            <LegalLinksItem key={key}>
              {href ? (
                <StyledLink href={href} target={target || undefined}>
                  {title}
                </StyledLink>
              ) : (
                title
              )}
              {legalLinks.length > index + 1 && <StyledLinkDelimeter />}
            </LegalLinksItem>
          ))}
        </LegalLinks>
      </BottomContainer>
    </Wrapper>
  )
}

export default FooterYachtSupport
