import { ReactNode } from 'react'
import { currentSite, Site } from '../../sites'
import FooterCommon from './Footer'
import FooterXplorer from './Footer.xplorer'
import FooterYachtSupport from './Footer.yachtsupport'

export interface FooterProps {
  newsletterForm: ReactNode
  columns?: Array<{
    key: string
    title: ReactNode
    codeId?: string
    links: Array<{
      key: string
      href: string
      title: string
      target?: string
    }>
  }>
  socials?: Array<{
    key: string
    icon:
      | 'vimeo'
      | 'linkedin'
      | 'instagram'
      | 'facebook'
      | 'twitter'
      | 'youtube'
    href: string
    target?: string
  }>
  copyright: ReactNode
  legalLinks?: Array<{
    key: string
    title: ReactNode
    href?: string
    target?: string
  }>
  logoFootnote?: string
}

const Footer = (props: FooterProps) => {
  switch (currentSite) {
    case Site.Yachting:
      return <FooterCommon {...props} />
    case Site.Xplorer:
      return <FooterXplorer {...props} />
    case Site.YachtSupport:
      return <FooterYachtSupport {...props} />
    default:
      return <FooterCommon {...props} />
  }
}

export default Footer
